import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';

const OffersActivity = (props) => {
    const {
        enableFooter = false,
        marginTop = '26px',
        backgroundImageURL,
        badgeClass,
        Footer,
        dropdownOptions,
        offerId,
        handleOfferDropdownChange,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const formatDate = (dateString, dateType = null) => {
        const date = new Date(dateString);
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        let daySuffix;

        // Determine the appropriate suffix for the day
        if (day === 1 || day === 21 || day === 31) {
            daySuffix = 'st';
        } else if (day === 2 || day === 22) {
            daySuffix = 'nd';
        } else if (day === 3 || day === 23) {
            daySuffix = 'rd';
        } else {
            daySuffix = 'th';
        }

        if (dateType && dateType === 'date,year') {
            const year = date.getFullYear();
            return `${month} ${day}${daySuffix}, ${year}`;
        } else {
            return `${month} ${day}${daySuffix}`;
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    return (
        <Fragment>
            <Grid
                container
                sx={{
                    borderRadius: '8px',
                    background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), radial-gradient(50% 50% at 50% 50%, rgba(10, 0, 23, 0) 0%, rgba(10, 0, 23, 0.4) 100%);',
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: '8px',
                        backgroundImage: enableFooter
                            ? `url(${backgroundImageURL}), linear-gradient(#C4C4C4, #C4C4C4)`
                            : `url(${backgroundImageURL})`,
                        height: props.cusHeight || 'auto',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    },
                    '::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: '8px',
                        backgroundColor: isMenuOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                        pointerEvents: isMenuOpen ? 'auto' : 'none',
                        transition: 'background-color 0.3s',
                    },
                }}
            >
                <Grid container sx={{ zIndex: 1 }}>
                    <Grid
                        item
                        xs={6}
                        textAlign="start"
                        display={'flex'}
                        justifyContent="flex-end"
                    >
                        <Box className={`${badgeClass}  offer-date`}>
                            {props.topTitle ? props.topTitle : formatDate(props.date, props?.dateType)}
                        </Box>
                    </Grid>
                    <Grid container mt={marginTop} sx={{ p: '0px 20px 10px 10px' }}>
                        <Grid item xs={12}>
                            <Typography className="offer-title">{props.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="offer-subTitle">{props.subTitle}</Typography>
                        </Grid>
                    </Grid>
                    {enableFooter && (
                        <>
                            <Divider width="100%" />
                            <Grid item xs={12} sx={{ p: '10px 5px 3px 10px' }}>
                                {Footer}
                            </Grid>
                        </>
                    )}
                    {dropdownOptions && dropdownOptions.length > 0 && (
                        <Grid container justifyContent="flex-end" sx={{ position: 'absolute', top: 10, right: 10 }}>
                            <IconButton
                                onClick={handleMenuOpen}
                                sx={{
                                    backgroundColor: '#667085',
                                    padding: '0px',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                    },
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    sx: {
                                        background: '#FFFFFF',
                                        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '10px',
                                    },
                                }}
                            >
                                <Grid container direction="column" spacing={1} sx={{ padding: '8px 10px' }}>
                                    {dropdownOptions?.map((option, index) => (
                                        <Grid item key={index}>
                                            <Grid
                                                gap={5}
                                                className="user-dropdown-option"
                                                onClick={() => {
                                                    handleOfferDropdownChange(option.route, offerId, option.title);
                                                }}
                                            >
                                                <Box item>{option.icon}</Box>
                                                <Box item>{option.title}</Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Menu>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
};
export default OffersActivity;

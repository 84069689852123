import { Grid, Avatar, Typography, Badge, Chip, Divider, Drawer, useMediaQuery, IconButton } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState, useEffect } from 'react';
import SearchBar from '../../components/General/SearchBar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ArrowBack } from '@mui/icons-material';
import UserImage from '../../assets/images/UserImg.png';
import ChatUI from './ChatUI';
import { APP_CONSTANTS } from '../../config/config';

const { APP_BASE_URL } = APP_CONSTANTS;
const CustomBadge = styled(Badge)`
    .MuiBadge-badge {
        background-color: #2ca58d;
        color: #2ca58d;
    }
`;

export const InboxUsers = (props) => {
    const { threads, handleChatSelected, lastMessage } = props;
    const [selectedChat, setSelectedChat] = useState(null);
    const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    const isMobileOrTabletView = useMediaQuery('(max-width: 960px)'); // Adjust the breakpoint as needed

    const [originalThreads, setOriginalThreads] = useState(threads);

    const [threadsList, setThreadsList] = useState(originalThreads);

    const handleChangeSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        if (searchTerm === '') {
            setThreadsList(originalThreads); // Reset to the original threads
        } else {
            const filteredThreads = originalThreads.filter((thread) => thread.name.toLowerCase().includes(searchTerm));
            setThreadsList(filteredThreads);
        }
    };

    useEffect(() => {
        setOriginalThreads(threads);
        setThreadsList(threads);
    }, [threads]);
    const handleChatItemClick = (threadId) => {
        setSelectedChat(threadId);
        setMobileDrawerOpen(true);
        handleChatSelected(threadId);
    };

    const closeMobileDrawer = () => {
        setMobileDrawerOpen(false);
    };

    return (
        <Box className="container" sx={{ m: { xs: '15px', sm: '15px', md: 0 } }}>
            <Grid container p={2}>
                <Grid item xs={12} mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" className="container-title">
                        All Conversations
                    </Typography>
                    {/* <ArrowDropDownIcon sx={{ color: '#98A2B3' }} /> */}
                </Grid>
                <Grid item xs={12} mb={1}>
                    <SearchBar
                        placeholder="Search"
                        type="Search"
                        isIconRequired={false}
                        handleChangeSearch={handleChangeSearch}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    height: '79%',

                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
            >
                {threadsList.map((item, index) => (
                    <>
                        <Grid
                            key={`message-${item.thread_id}`}
                            item
                            xs={12}
                            p={2}
                            onClick={() => {
                                handleChatItemClick(item.thread_id);
                            }}
                            className={`chat-item ${selectedChat === item.thread_id ? 'selected' : ''}`}
                        >
                            <Grid container>
                                <Grid item alignItems="flex-start" xs={2}>
                                    <CustomBadge
                                        variant="dot"
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        badgeContent={null}
                                        showZero
                                        // invisible={!item.active}
                                    >
                                        <Avatar alt="User" src={APP_BASE_URL + item.image_url} />
                                    </CustomBadge>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            justifyContent={'space-between'}
                                            alignItems="center"
                                        >
                                            <Typography variant="body1" className="user-name">
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className="event-title">
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className="event-description">
                                                {item.last_message_text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    display="flex"
                                    flexDirection={'column'}
                                    justifyContent={'space-between'}
                                    alignItems="center"
                                >
                                    <Typography variant="body1" className="view-status">
                                        Now
                                    </Typography>

                                    <Chip label={item.unread_count} className="chip-count" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider width="100%" />
                    </>
                ))}
            </Grid>
            {isMobileOrTabletView && (
                <Drawer
                    open={isMobileDrawerOpen}
                    onClose={closeMobileDrawer}
                    ModalProps={{
                        style: {
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        },
                    }}
                    PaperProps={{
                        style: {
                            width: '100%',
                            maxWidth: '100%',
                        },
                    }}
                >
                    <Box>
                        <Box>
                            <ChatUI
                                height="100vh"
                                backIcon={
                                    <IconButton onClick={closeMobileDrawer} sx={{ padding: '0px' }}>
                                        <ArrowBack />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

import React from 'react';
import { ThemeProvider, Box, createTheme, useMediaQuery, Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import routes from './routes';
import { AppBarComponent } from './components';
import SecondaryBottomAppBar from './components/AppBarComponent/SecondaryBottomAppBar';
import CustomizedSnackbars from './views/Snackbar/CustomSnackbar';
import SecondaryTopAppBar from './views/Home/SecondaryAppBar';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {STRIPE_CONSTANTS} from "./config/config";

const AppContent = () => {
    const location = useLocation();
    const matches = useMediaQuery('(max-width:1023px)');

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: matches ? 'column' : 'row',
            }}
        >
            {location.pathname !== '/login' &&
            location.pathname !== '/register' &&
            location.pathname !== '/forgot-password' &&
            location.pathname !== '/set-password' &&
            location.pathname.indexOf('/security/confirm-email') !== 0 &&
            location.pathname.indexOf('/ul/') !== 0 &&
            location.pathname !== '/' ? (
                <Box sx={{ p: matches ? '0px' : '20px' }}>
                    <AppBarComponent />
                </Box>
            ) : null}
            <Box
                sx={{
                    width:
                        location.pathname === '/login' ||
                        location.pathname === '/set-password' ||
                        location.pathname.indexOf('/security/confirm-email') === 0 ||
                        location.pathname.indexOf('/ul/') === 0 ||
                        location.pathname === '/register' ||
                        location.pathname === '/forgot-password'
                            ? '100%'
                            : matches
                            ? '100%'
                            : 'calc(100% - 344px)',
                    ml:
                        location.pathname === '/login' ||
                        location.pathname === '/set-password' ||
                        location.pathname.indexOf('/security/confirm-email') === 0 ||
                        location.pathname.indexOf('/ul/') === 0 ||
                        location.pathname === '/register' ||
                        location.pathname === '/forgot-password'
                            ? '0px'
                            : matches
                            ? '0'
                            : '280px',
                    background: '#F2F4F7',
                    p: matches ? '0px' : '30px 0px 30px 0px',
                    mb: { xs: '65px', sm: 0 },
                }}
            >
                {location.pathname !== '/login' &&
                location.pathname !== '/register' &&
                location.pathname !== '/forgot-password' &&
                location.pathname !== '/set-password' &&
                location.pathname.indexOf('/security/confirm-email') !== 0 &&
                location.pathname.indexOf('/ul/') !== 0 &&
                location.pathname !== '/' ? (
                    <>
                        {matches ? null : (
                            <Box mb={2} pr={2}>
                                <SecondaryTopAppBar />
                            </Box>
                        )}
                    </>
                ) : null}

                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={<route.component />} />
                    ))}
                </Routes>
            </Box>
            {location.pathname !== '/login' &&
            location.pathname !== '/set-password' &&
            location.pathname !== undefined &&
            location.pathname !== '/' ? (
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            pt: '2rem',
                            display: { xs: 'block', sm: 'none' },
                            zIndex: 9,
                        }}
                    >
                        <SecondaryBottomAppBar />
                    </Box>
                </>
            ) : null}
        </Box>
    );
};

const stripePromise = loadStripe(STRIPE_CONSTANTS.PUBLIC_KEY);

const App = () => {
    const theme = createTheme();
    const { showLoading } = useSelector((state) => state.global);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Router>
                    <Box maxWidth="100%">
                        <Elements stripe={stripePromise}>
                            <AppContent />
                        </Elements>
                        <CustomizedSnackbars />
                    </Box>
                </Router>
                <Backdrop open={showLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

import { call, put } from 'redux-saga/effects';
import { getThreadsRequest, getThreadMessagesRequest, sendMessageRequest } from '../requests/chat.request';
import { setThreads, setThreadMessages, setIsDone } from '../../slices/chat.slice';
import { setShowLoading } from '../../slices/global.slice';
import { showSnackBar } from '../../slices/snackbar.slice';

export function* handleGetThreads(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getThreadsRequest, { accessToken });

        const { data } = response;

        const threads = data.threads;
        if (threads) {
            yield put(setThreads(threads));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetThreadMessages(action) {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getThreadMessagesRequest, { accessToken, threadId: action.payload, type: 'venue' });

        const { data } = response;

        const threadMessages = data.messages;
        yield put(setThreadMessages(threadMessages));
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleSendMessage(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(sendMessageRequest, { accessToken: accessToken, message: action.payload });
        console.log('🚀 ~ file: chat.handler.js ~ line 48 ~ handleSendMessage ~ response', response);

        const { data } = response;

        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function getUpComingPromotionsRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/getUpcomingList`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function getPromotionTypesRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/getTypes`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function createPromotionRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/create`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getPromotionDetailRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/getDetails`,
        headers: {
            Authorization: data.accessToken,
        },
        data: { promotionId: data.id },
    };

    return axios(config);
}

export function updatePromotionRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/update`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function endPromotionRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/end`,
        headers: {
            Authorization: data.accessToken,
        },
        data: { promotionId: data.id },
    };

    return axios(config);
}

export function getPromotionsByRangeRequest(data) {
    const { accessToken, options } = data;
    var config = {
        method: 'post',
        url: `${API_URL}v1/promotion/getRangeList`,
        headers: {
            Authorization: data.accessToken,
        },
        data: {},
    };

    if (options.fromTimeStamp) {
        config.data.fromTimeStamp = options.fromTimeStamp;
    }
    if (options.toTimeStamp) {
        config.data.toTimeStamp = options.toTimeStamp;
    }

    return axios(config);
}

import { createSlice } from '@reduxjs/toolkit';

const PromotionsSlice = createSlice({
    name: 'promotions',
    initialState: {
        upComingPromotions: [],
        rangeUpComingPromotions: [],
        rangeActivePromotions: [],
        rangePastPromotions: [],
        promotionTypes: [],
        isApiDone: false,
        promotionToEdit: null,
    },
    reducers: {
        getUpComingPromotions(state, action) {},
        setUpComingPromotions(state, action) {
            state.upComingPromotions = action.payload;
        },
        getPromotionTypes(state, action) {},
        setPromotionTypes(state, action) {
            state.promotionTypes = action.payload;
        },
        createNewPromotion(state, action) {},
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
        getPromotionDetail(state, action) {},
        setPromotionToEdit(state, action) {
            state.promotionToEdit = action.payload;
        },
        updatePromotion(state, action) {},
        endPromotion(state, action) {},
        getPromotionsByRange(state, action) {},
        setRangeActivePromotions(state, action) {
            state.rangeActivePromotions = action.payload;
        },
        setRangePastPromotions(state, action) {
            state.rangePastPromotions = action.payload;
        },
        setRangeUpComingPromotions(state, action) {
            state.rangeUpComingPromotions = action.payload;
        },
    },
});

export const {
    getUpComingPromotions,
    setUpComingPromotions,
    getPromotionTypes,
    setPromotionTypes,
    createNewPromotion,
    setIsDone,
    getPromotionDetail,
    setPromotionToEdit,
    updatePromotion,
    endPromotion,
    getPromotionsByRange,
    setRangeUpComingPromotions,
    setRangeActivePromotions,
    setRangePastPromotions,
} = PromotionsSlice.actions;

export default PromotionsSlice.reducer;

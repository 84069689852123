import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function getPaymentMethods(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/paymentMethod/getList`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function addPaymentMethod(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/paymentMethod/add`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function updateDefaultPaymentMethod(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/paymentMethod/setDefault`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getDefaultPaymentMethod(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/paymentMethod/getDefault`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function sendDrink(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/drink/sendMultiDrink`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}


export function getHomeData(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/venue/getNearbyUsers`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function redeemDrink(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/drink/redeem`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getNotifications(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/venue/getNotifications`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getCreditsAndCoupons(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/drink/getVenueDrinks`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getTransactions(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/drink/getVenueTransactions`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import { signinUser } from '../../store/slices/user.slice';
import { Grid, Typography, Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import SPILOGO from '../../assets/images/SpiLogo.svg';
import { isEmpty, isUndefined } from 'lodash';

const defaultFormInput = {
    email: '',
    password: '',
};

const LoginPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formInput, setFormInput] = useState(defaultFormInput);

    const { isApiDone, id } = useSelector((state) => state.user);

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const accessToken = localStorage.getItem('accessToken');
    const userData = localStorage.getItem('user');

    const dispatchSnackBar = useCallback((type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    }, []);

    useEffect(() => {
        if (isApiDone === true) {
            dispatchSnackBar('success', 'Login Successfully', true);
            navigate('/home');
        } else {
            // dispatchSnackBar('error', 'Login Failed', true);
            navigate('/login');
        }
    }, [dispatchSnackBar, id, isApiDone, navigate]);

    const handleInputChange = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    const handleLogin = (e) => {
        e.stopPropagation();
        const signinDataObject = {
            email: formInput.email,
            password: formInput.password,
        };

        const emailFormat = '/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/';
        if (signinDataObject.email === '' || signinDataObject.password === '') {
            dispatchSnackBar('error', 'Please fill in all the fields', true);
        } else if (signinDataObject.email === emailFormat) {
            dispatchSnackBar('error', 'Kindly Enter Valid Email Address', true);
        } else {
            dispatch(signinUser(signinDataObject));
        }
    };

    useEffect(() => {
        if (!isEmpty(accessToken) && !isUndefined(accessToken)) {
            navigate('/home');
        }
    }, [accessToken]);

    return (
        <Fragment>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.email}
                                fullWidth
                                name="email"
                                id="email"
                                placeholder="Email Address"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.password}
                                fullWidth
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Password"
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} pt={2}>
                            <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={(e) => handleLogin(e)}
                                disabled={!formInput.email || !formInput.password}
                            >
                                Login
                            </Button>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} alignItems="center">
                            <Typography>Forgot your password?</Typography>
                            <Button type="button" className="button-transparent">
                                <Link
                                    style={{ textDecoration: 'underline', color: 'blue', textTransform: 'none' }}
                                    to="/forgot-password"
                                >
                                    Reset
                                </Link>
                            </Button>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} alignItems="center">
                            <Typography>New to SipSender?</Typography>
                            <Button type="button" className="button-transparent">
                                <Link
                                    style={{ textDecoration: 'underline', color: 'blue', textTransform: 'none' }}
                                    to="/register"
                                >
                                    Sign Up Now
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default LoginPage;

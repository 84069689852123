import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isApiDone: false,
        username: '',
        id: 0,
        isLogOut: false,
        user: {},
        isEmailVerified: false,
        isEmailConfirmed: false,
        emailSent: false,
        isTokenVerified: false,
        resetPassword: false,
    },
    reducers: {
        setUser(state, action) {
            const userData = action.payload;
            state.isApiDone = true;
            state.isOtpVerified = false;

            if (userData) {
                state.id = userData.id;
                state.mobile = userData.mobile;
                state.user = userData;
            }
        },
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
        signinUser(state, action) {},
        registerUser(state, action) {},
        resetState(state, action) {
            state.isApiDone = false;
            state.username = '';
            state.id = 0;
            state.mobile = '';
            state.isLogOut = false;
            state.user = {};
            state.emailSent = false;
            state.isEmailVerified = false;
            state.resetPassword = false;
        },
        isEmailSent(state, action) {},
        setIsEmailSent(state, action) {
            const emailSendRes = action.payload;
            state.emailSent = true;
            state.isEmailVerified = emailSendRes.isEmailVerified;
        },
        passwordReset(state, action) {},
        setPasswordReset(state, action) {
            const resetPswRes = action.payload;
            state.resetPassword = true;
            state.isTokenVerified = resetPswRes.isTokenVerified;
        },
        confirmEmail(state, action) {},
        setConfirmEmail(state, action) {
            const confirmEmailRes = action.payload;
            state.isEmailConfirmed = confirmEmailRes.isEmailConfirmed;
        },
        resetUserState(state, action) {
            state.user = {};
        },
        getProfile(state, action) {},
        updateProfile(state, action) {},
    },
});

export const {
    getUser,
    setUser,
    signinUser,
    registerUser,
    setIsDone,
    isLogOut,
    setIsLogOut,
    resetState,
    resetUserState,
    isEmailSent,
    setIsEmailSent,
    passwordReset,
    setPasswordReset,
    confirmEmail,
    setConfirmEmail,
    getProfile,
    updateProfile,
} = userSlice.actions;

export default userSlice.reducer;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Box, Container, Grid, Tab, Tabs } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            sx={{ background: 'orange' }}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const GenericTabs = (props) => {
    const { children, value, active, index, ...other } = props;

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return (
        <Fragment>
            <Grid container item xs={12} className="tab-background">
                <Grid sx={{ width: '100%' }} ml={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Tabs value={active} onChange={props.handleChange} aria-label="basic tabs">
                            {props.firstTab && (
                                <Tab sx={{ textTransform: 'none' }} {...a11yProps(0)} label={props.firstTab} />
                            )}
                            {props.secondTab && (
                                <Tab sx={{ textTransform: 'none' }} {...a11yProps(1)} label={props.secondTab} />
                            )}

                            {props.thirdTab && (
                                <Tab sx={{ textTransform: 'none' }} {...a11yProps(2)} label={props.thirdTab} />
                            )}
                            {props.fourthTab && (
                                <Tab sx={{ textTransform: 'none' }} {...a11yProps(3)} label={props.fourthTab} />
                            )}
                        </Tabs>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default GenericTabs;

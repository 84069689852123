import { createSlice } from '@reduxjs/toolkit';

const AnalyzeSlice = createSlice({
    name: 'analyze',
    initialState: {
        stats: [],
        engagement: [],
        attendees: [],
        isApiDone: false,
        statDetails: undefined
    },
    reducers: {
        getStats(state, action) {},
        setStats(state, action) {
            state.stats = action.payload;
        },
        setEngagement(state, action) {
            state.engagement = action.payload;
        },
        setAttendees(state, action) {
            state.attendees = action.payload;
        },
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
        getStatsClaimed(state, action) {},
        getStatsDrinks(state, action) {},
        getStatsActive(state, action) {},
        getStatsUpcoming(state, action) {},
        setStatDetails(state, action) {
            state.statDetails = action.payload
        }
    },
});

export const { 
    getStats, 
    setStats, 
    setEngagement, 
    setAttendees,
    getStatsClaimed,
    getStatsDrinks,
    getStatsActive,
    getStatsUpcoming,
    setStatDetails
 } = AnalyzeSlice.actions;

export default AnalyzeSlice.reducer;

/* Route declarations for the app */
import * as reviews from '../views';

const routData = [
    {
        id: 'route-001',
        path: '/login',
        component: reviews.Login,
        requiresAuth: true,
    },
    {
        id: 'route-002',
        path: '/register',
        component: reviews.Register,
        requiresAuth: true,
    },
    {
        id: 'route-003',
        path: '/forgot-password',
        component: reviews.ForgotPassword,
        requiresAuth: false,
    },
    {
        id: 'route-004',
        path: '/set-password',
        component: reviews.SetPassword,
        requiresAuth: false,
    },
    {
        id: 'route-022',
        path: '/security/confirm-email/:token',
        component: reviews.ConfirmEmail,
        requiresAuth: false,
    },
    {
        id: 'route-023',
        path: '/ul/*',
        component: reviews.UniversalLink,
        requiresAuth: false,
    },

    {
        id: 'route-005',
        path: '/home',
        component: reviews.Home,
        requiresAuth: false,
    },

    {
        id: 'route-006',
        path: '/create',
        component: reviews.CreatePage,
        requiresAuth: false,
    },
    {
        id: 'route-007',
        path: '/create-promotion',
        component: reviews.CreatePromotion,
        requiresAuth: false,
    },
    {
        id: 'route-008',
        path: '/',
        component: reviews.Login,
        requiresAuth: false,
    },
    {
        id: 'route-009',
        path: '/create-package',
        component: reviews.CreatePackage,
        requiresAuth: true,
    },
    {
        id: 'route-010',
        path: '/edit-promotion/:id',
        component: reviews.CreatePromotion,
        requiresAuth: false,
    },
    {
        id: 'route-000',
        path: '*',
        component: reviews.NoPageFound,
        requiresAuth: false,
    },
    {
        id: 'route-011',
        path: '/manage-account',
        component: reviews.ManageAccount,
        requiresAuth: false,
    },
    {
        id: 'route-019',
        path: '/analyze',
        component: reviews.Analyze,
        requiresAuth: false,
    },
    {
        id: 'route-012',
        path: '/manage',
        component: reviews.Manage,
        requiresAuth: false,
    },
    {
        id: 'route-013',
        path: '/edit-package/:id',
        component: reviews.CreatePackage,
        requiresAuth: false,
    },
    {
        id: 'route-013',
        path: '/inbox/:id',
        component: reviews.ChatContainer,
        requiresAuth: false,
    },
    {
        id: 'route-020',
        path: '/drink-scanner',
        component: reviews.DrinkScanner,
        requiresAuth: false,
    },
    {
        id: 'route-021',
        path: '/payments',
        component: reviews.Payments,
        requiresAuth: false,
    },
];

export default routData;

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getNotifications } from '../../store/slices/wallet.slice';
import { useDispatch, useSelector } from 'react-redux';
import { APP_CONSTANTS } from '../../config/config';
import WarningIcon from '@mui/icons-material/Warning';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';

export default function Notification() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allNotifications, setAllNotifications] = useState([])
    const wallet = useSelector((state) => state.wallet);
    const {notifications} = wallet

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getNotifications({}))
        }, 10000);
        dispatch(getNotifications({}))
    
        return () => {
          clearInterval(interval);
        };
      }, []);

    useEffect(() => {
        if (notifications == undefined) {
            return
        }
        setAllNotifications(notifications.map(notif => ({
            ...notif,
            imageUrl: imageForNotification(notif)
        })))
    }, [notifications])

    const { APP_BASE_URL } = APP_CONSTANTS;

    const textForNotification = (notif) => {
        if (notif.type == 'message') {
            return (
                <span style={{fontSize: '13px'}}>New message for <span style={{fontWeight: 'bold'}}>{notif.title}</span> from <span style={{fontWeight: 'bold'}}>{notif.name}</span>.
                </span>
            )
        } else if (notif.type == 'expiring') {
            return (
                <span style={{fontSize: '13px'}}><span style={{fontWeight: 'bold'}}>{notif.title}</span> is expiring soon.
                </span>
            )
        } else if (notif.type == 'claimed') {
            return (
                <span style={{fontSize: '13px'}}>All offers claimed for <span style={{fontWeight: 'bold'}}>{notif.title}</span>.
                </span>
            )
        } else {
            return (
                <span style={{fontSize: '13px'}}><span style={{fontWeight: 'bold'}}>{notif.title}</span> has expired.
                </span>
            )
        }
    }

    const iconForNotification = (notif) => {
        if (notif.type == 'message') {
            return (<EmailIcon style={{color: '#5B2A9E'}}/>)
        } else if (notif.type == 'expiring') {
            return (<WarningIcon style={{color: '#FFBF00'}}/>)
        } else if (notif.type == 'claimed') {
            return (<WarningIcon style={{color: '#FFBF00'}}/>)
        } else {
            return (<ErrorIcon style={{color: '#8B0000'}}/>)
        }
    }

    const imageForNotification = (notif) => {
        let image
        if (notif.type == 'message') {
            image = notif.image_url
        } else {
            image = notif.cover_image_url
        }
        return image
    }

    const handleOnItemClick = (notif) => {
        if (notif.type == 'message') {
            navigate(`/inbox/${notif.thread_id}`)
        } else {
            if (notif.subType == 'package') {
                navigate(`/edit-package/${notif.id}`)
            } else {
                navigate(`/edit-promotion/${notif.id}`)
            }
        }
    }

    const ItemView = (item, index, isLast) => {
        return (
            <div key={index}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px'}} onClick={() => handleOnItemClick(item)}>
                        {iconForNotification(item)}
                        <img src={APP_BASE_URL + item.imageUrl} alt="" style={{width: '34px', height: '34px', borderRadius: '4px', marginLeft: '8px', marginRight: '8px'}}/>
                        {textForNotification(item)}
                </div>
                {!isLast && <Divider />}
            </div>
        )
    }

    return (
        <Paper sx={{ backgroundColor: '#FFFFFF', borderRadius: '20px', p: 2 }} elevation={0}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '15px' }}>Notifications </Typography>
                <Chip
                    label={allNotifications.length}
                    sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                        color: '#ffffff',
                        width: '20px',
                        height: '20px',
                        background: '#7F56D9',
                        borderRadius: '100px',
                        textAlign: 'center',
                        padding: '10px',
                        overflow: 'inherit',
                    }}
                />
            </Box>
            <div style={{maxHeight: '220px', overflow: 'auto'}}>
                {allNotifications.map((item, index) => ItemView(item, index, index == allNotifications.length - 1))}
            </div>
        </Paper>
    );
}

import { call, put } from 'redux-saga/effects';
import {
    getPaymentMethods,
    addPaymentMethod,
    updateDefaultPaymentMethod,
    getDefaultPaymentMethod,
    sendDrink,
    getHomeData,
    redeemDrink,
    getNotifications,
    getCreditsAndCoupons,
    getTransactions
} from '../requests/wallet.request';
import {
    setPaymentMethods,
    setDefaultPaymentId,
    addPaymentMethodDone,
    setIsDone,
    setHomeData,
    setNotifications,
    setCreditsAndCoupons,
    setTransactions
} from '../../slices/wallet.slice';
import { setShowLoading } from '../../slices/global.slice';
import { showSnackBar } from '../../slices/snackbar.slice';
import { createNewDataset, engagmentTotal, attendeesTotal } from './analyze.handler';

export function* handleGetPaymentMethods(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPaymentMethods, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 22 ~ handleGetPaymentMethods ~ response', response);

        const { data } = response;
        const paymentMethods = data.paymentMethods
        if (paymentMethods && paymentMethods.length > 0) {
            yield put(setPaymentMethods(paymentMethods));
        }
        const defaultPaymentId = data.defaultPaymentId
        if (defaultPaymentId && defaultPaymentId.length > 0) {
            yield put(setDefaultPaymentId(defaultPaymentId));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleAddPaymentMethod(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(addPaymentMethod, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 41 ~ handleAddPaymentMethod ~ response', response);

        const { data } = response;
        const paymentMethod = data.paymentMethod
        if (data.status === 1 && paymentMethod) {
            yield put(addPaymentMethodDone(paymentMethod));

            const snackbarObject = {
                type: 'success',
                message: 'New card added successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleUpdateDefaultPaymentMethod(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(updateDefaultPaymentMethod, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 63 ~ handleUpdateDefaultPaymentMethod ~ response', response);

        const { data } = response;
        const defaultPaymentId = action.payload.sourceId
        if (data.status === 1 && defaultPaymentId) {
            yield put(setDefaultPaymentId(defaultPaymentId))

            const snackbarObject = {
                type: 'success',
                message: 'Card set as preferred payment method successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleSendDrink(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');

        const form = {...action.payload}
        const response = yield call(sendDrink, { accessToken: accessToken, form});
        console.log('🚀 ~ file: wallet.handler.js ~ line 125 ~ handleSendDrink ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            const snackbarObject = {
                type: 'success',
                message: 'Drink sent successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
            yield put(setIsDone({ isApiDone: true }));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetHomeData(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getHomeData, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 173 ~ handleGetHomeData ~ response', response);

        const { data } = response;
        const users = data.users
        const stats = data.stats

        if (data.status === 1) {
            const updatedCounts = {
                ...stats.count,
                engagement: engagmentTotal(stats.engagment),
                attendees: attendeesTotal(stats.attendees)
            }
            const newDataset = createNewDataset(updatedCounts, 'All');
            yield put(setHomeData({users, stats: newDataset}));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleRedeemDrink(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');

        var response = yield call(redeemDrink, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 204 ~ handleRedeemDrink ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            const snackbarObject = {
                type: 'success',
                message: 'Drink redeemed successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
    yield put(setIsDone({ isApiDone: true }));
}

export function* handleGetNotifications(action) {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getNotifications, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 222 ~ handleGetNotifications ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            const notifications = data.notifications
            yield put(setNotifications(notifications));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetCreditsAndCoupons(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getCreditsAndCoupons, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 241 ~ handleGetCreditsAndCoupons ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            yield put(setCreditsAndCoupons(data));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetTransactions(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getTransactions, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: wallet.handler.js ~ line 269 ~ handleGetTransactions ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            yield put(setTransactions(data));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}
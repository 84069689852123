import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
dayjs.extend(utcPlugin);
export const frequencyOptions = [
    {
        id: 2,
        value: 'D0',
        title: 'Repeat: Monday',
    },
    {
        id: 3,
        value: 'D1',
        title: 'Repeat: Tuesday',
    },
    {
        id: 4,
        value: 'D2',
        title: 'Repeat: Wednesday',
    },
    {
        id: 5,
        value: 'D3',
        title: 'Repeat: Thursday',
    },
    {
        id: 6,
        value: 'D4',
        title: 'Repeat: Friday',
    },
    {
        id: 7,
        value: 'D5',
        title: 'Repeat: Saturday',
    },
    {
        id: 8,
        value: 'D6',
        title: 'Repeat: Sunday',
    },
    {
        id: 9,
        value: 'DD',
        title: 'Repeat: Everyday',
    },
    {
        id: 10,
        value: 'DW',
        title: 'Repeat: Working Day',
    },
];

export const datesOptions = [
    {
        id: '1',
        title: 'Today: ' + dayjs().startOf('day').format('MM/DD/YYYY'),
    },
    {
        id: '2',
        title: 'This week: ' + dayjs().startOf('week').format('MM/DD/YYYY') + ' - ' + dayjs().format('MM/DD/YYYY'),
    },
    {
        id: '3',
        title: 'This month: ' + dayjs().startOf('month').format('MM/DD/YYYY') + ' - ' + dayjs().format('MM/DD/YYYY'),
    },
    {
        id: '4',
        title: 'Custom',
    },
    {
        id: '5',
        title: 'All time',
    },
];

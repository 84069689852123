import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, useMediaQuery } from '@mui/material';
import Promotion from '../../components/Promotion/Promotion';
import PROMOTION_ONE from '../../assets/images/Promotion1.png';
import PROMOTION_TWO from '../../assets/images/promotion2.png';
import ADD_ICON from '../../assets/images/AddPromotionIcon.svg';

const CreatePage = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const matches = useMediaQuery('(max-width:1023px)');

    const promotionData = [
        {
            id: 1,
            img: PROMOTION_ONE,
            name: 'Create Promotion',
            detail: 'Use this feature to offer things like Happy Hours, Drink Specials to get the word out and drive traffic',

            action: (
                <Button
                    variant="outlined"
                    className="button-outlined"
                    startIcon={<img src={ADD_ICON} alt="add" />}
                    component={Link}
                    to="/create-promotion"
                >
                    Create promotion
                </Button>
            ),
        },
        {
            id: 2,
            img: PROMOTION_TWO,
            name: 'Add Event Package',
            detail: 'Use this feature to offer event packages and highlight your venue accommodations for larger parties',
            action: (
                <Button
                    variant="outlined"
                    className="button-outlined"
                    startIcon={<img src={ADD_ICON} alt="add" />}
                    component={Link}
                    to="/create-package"
                >
                    Add Event Package
                </Button>
            ),
        },
    ];
    return (
        <Grid container p={3} justifyContent="center">
            <Grid container spacing={3.75} sx={{ px: { xs: 2, sm: 2, md: 0 } }} mt={isSmallScreen ? 5 : 0}>
                {promotionData.map((item) => (
                    <Grid key={item.id} item xs={12} md={12} lg={6}>
                        <Promotion
                            promotionImg={item.img}
                            promotionName={item.name}
                            promotionDetail={item.detail}
                            promotionAction={item.action}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default CreatePage;

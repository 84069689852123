import { call, put } from 'redux-saga/effects';
import {
    getUpComingPromotionsRequest,
    getPromotionTypesRequest,
    createPromotionRequest,
    getPromotionDetailRequest,
    updatePromotionRequest,
    endPromotionRequest,
    getPromotionsByRangeRequest,
} from '../requests/promotion.request';
import {
    setUpComingPromotions,
    setPromotionTypes,
    setIsDone,
    setPromotionToEdit,
    setRangeActivePromotions,
    setRangePastPromotions,
    setRangeUpComingPromotions,
} from '../../slices/promotion.slice';
import { setShowLoading } from '../../slices/global.slice';
import { showSnackBar } from '../../slices/snackbar.slice';

export function* handleGetUpComingPromotions(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getUpComingPromotionsRequest, { accessToken });

        const { data } = response;

        const promotionsData = data.list;
        if (promotionsData) {
            yield put(setUpComingPromotions(promotionsData));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetPromotionTypes(action) {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPromotionTypesRequest, { accessToken });

        const { data } = response;

        const promotionTypes = data.types;
        yield put(setPromotionTypes(data.types));
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleCreatePromotion(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(createPromotionRequest, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: promotion.handler.js ~ line 41 ~ handleCreatePromotion ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Promotion Created Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetPromotionDetail(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPromotionDetailRequest, { accessToken, id: action.payload });
        console.log('🚀 ~ file: promotion.handler.js ~ line 89 ~ handleGetPromotionDetail ~ response', response);

        const { data } = response;

        const promotion = data.promotion;
        if (promotion && promotion.length > 0) {
            yield put(setPromotionToEdit(promotion[0]));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}
export function* handleUpdatePromotion(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(updatePromotionRequest, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: promotion.handler.js ~ line 108 ~ handleUpdatePromotion ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Promotion Updated Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleEndPromotion(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(endPromotionRequest, { accessToken, id: action.payload });
        console.log('🚀 ~ file: promotion.handler.js ~ line 143 ~ handleEndPromotionDetail ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Promotion Ended Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}
export function* handleGetPromotionsByRange(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPromotionsByRangeRequest, { accessToken, options: action.payload });

        const { data } = response;

        if (data?.upcoming || data?.active || data?.past) {
            yield put(setRangeUpComingPromotions(data?.upcoming));
            yield put(setRangeActivePromotions(data?.active));
            yield put(setRangePastPromotions(data?.past));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

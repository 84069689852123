import React, { Fragment, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, Menu, Divider, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Fade from '@mui/material/Fade';
import USER_IMAGE from '../../assets/images/UserImg.png';
import SPI_LOGO from '../../assets/images/SpiLogo.svg';
import NOTIFICATION_ICON from '../../assets/images/NotificationIcon.svg';
import { useDispatch } from 'react-redux';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import ManageIcon from '../../assets/images/ManageAccountIcon.png';
import { resetState } from '../../store/slices/user.slice.js';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PaymentsIcon from '@mui/icons-material/Payments';
import { APP_CONSTANTS } from '../../config/config';

const SecondaryTopAppBar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state) => state.user.user);
    const matches = useMediaQuery('(max-width:1023px)');

    const formatRouteName = (path) => {
        const trimmedPath = path.replace(/^\//, '').replace('-', ' ').split('/')[0];
        const formattedName = trimmedPath.charAt(0).toUpperCase() + trimmedPath.slice(1);
        if (formattedName == 'Payments') {
            return 'Payments & Billing'
        }
        return formattedName;
    };

    const pageName = formatRouteName(location.pathname);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch(resetState());
        dispatchSnackBar('success', 'Logout Successfully', true);
        navigate('/login');
    };

    const dispatchSnackBar = (type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;
        dispatch(showSnackBar(snackbarObject));
    };
    const userName = localStorage.getItem('userName');
    const userImageUrl = localStorage.getItem('userImageUrl');

    const handleManageAccount = () => {
        navigate('/manage-account');
    };

    const handleScanner = () => {
        navigate('/drink-scanner');
    };

    const handlePayments = () => {
        navigate('/payments')
    }

    const { APP_BASE_URL } = APP_CONSTANTS;

    return (
        <Fragment>
            <Grid
                container
                direction="row"
                alignItems="center"
                sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    p: matches ? '10px 16px' : '0px',
                    mb: matches ? '0px' : '4px',
                    background: matches ? 'white' : 'transparent',
                }}
            >
                {matches ? (
                    <Grid item>
                        <img src={SPI_LOGO} alt="SIP_SENDER" />
                    </Grid>
                ) : null}

                <Grid item xs={!matches && 12}>
                    <Grid container alignItems="center">
                        {!matches && (
                            <Grid item flexGrow={1}>
                                <Typography sx={{ fontSize: '22px', fontWeight: 600 }}>
                                    {' '}
                                    {pageName === 'Home' ? '' : pageName}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item>
                            <Grid container spacing={2.5} alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <img src={NOTIFICATION_ICON} alt="notification" />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Grid item>
                                            {userImageUrl ? (
                                                <img src={APP_BASE_URL + userImageUrl} style={{borderRadius: '50%'}} alt="" width="40px" height="40px" />
                                            ) : (
                                                <img src={USER_IMAGE} alt="user" width="40px" height="40px" />
                                            )}
                                        </Grid>
                                        {!matches && (
                                            <Grid
                                                item
                                                onClick={handleClick}
                                                display="flex"
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '17px',
                                                                color: '#1D2939',
                                                                lineHeight: '150%',
                                                            }}
                                                        >
                                                            {userName == 'null' ? 'Set Profile' : userName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <ArrowDropDownIcon />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <Grid container direction="column" gap="10px" p="12px 20px 12px 16px">
                        <Grid item>
                            <Grid
                                className="user-dropdown-option"
                                onClick={() => {
                                    handleClose();
                                    handleManageAccount();
                                }}
                            >
                                <Box item>
                                    <ManageAccountsRoundedIcon sx={{ color: '#98A2B3' }} />
                                </Box>
                                <Box item>Manage Account</Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid className="user-dropdown-option" onClick={() => {
                                handleClose()
                                handleScanner()
                            }}>
                                <Box item>
                                    <QrCodeScannerIcon sx={{ color: '#98A2B3' }} />
                                </Box>
                                <Box item>Redeem Drink</Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid className="user-dropdown-option" onClick={() => {
                                handleClose()
                                handlePayments()
                            }}>
                                <Box item>
                                    <PaymentsIcon sx={{ color: '#98A2B3' }} />
                                </Box>
                                <Box item>Payments & Billing</Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid
                                className="user-dropdown-option"
                                onClick={() => {
                                    handleClose();
                                    handleLogout();
                                }}
                            >
                                <Box item>
                                    <LogoutRoundedIcon sx={{ color: '#98A2B3' }} />
                                </Box>
                                <Box item>Logout</Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Menu>
            </Grid>
        </Fragment>
    );
};

export default SecondaryTopAppBar;

import React from 'react';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import UserImage from '../../assets/images/UserImg.png';
import { APP_CONSTANTS } from '../../config/config';

const { APP_BASE_URL } = APP_CONSTANTS;

const ChatHeader = (props) => {
    return (
        <Grid
            container
            gap="16px"
            sx={{
                p: '16px 20px',
                background: 'white',
                borderBottom: '1px solid #EAECF0',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                alignItems: 'center',
            }}
        >
            {props.backIcon && <Grid item>{props.backIcon}</Grid>}
            <Grid item>
                <Box sx={{ width: '40px', height: '40px' }}>
                    <Avatar alt="User" src={APP_BASE_URL + props.image_url} />
                </Box>
            </Grid>
            <Grid item flexGrow={1}>
                <Typography sx={{ fontSize: '17px', color: '#344054', fontWeight: 600 }}>{props.name}</Typography>
                {/* <Typography sx={{ fontSize: '12px', color: '#12B76A' }}>Online now</Typography> */}
            </Grid>
        </Grid>
    );
};

export default ChatHeader;

import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function getUpComingPackagessRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/getUpcomingList`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function getPackageTypesRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/getTypes`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function createPackageRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/create`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getPackageDetailRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/getDetails`,
        headers: {
            Authorization: data.accessToken,
        },
        data: { packageId: data.id },
    };

    return axios(config);
}

export function updatePackageRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/update`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function endPackageRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/end`,
        headers: {
            Authorization: data.accessToken,
        },
        data: { packageId: data.id },
    };

    return axios(config);
}

export function getPackagesByRangeRequest(data) {
    const { accessToken, options } = data;
    var config = {
        method: 'post',
        url: `${API_URL}v1/package/getRangeList`,
        headers: {
            Authorization: data.accessToken,
        },
        data: {},
    };

    if (options.fromTimeStamp) {
        config.data.fromTimeStamp = options.fromTimeStamp;
    }
    if (options.toTimeStamp) {
        config.data.toTimeStamp = options.toTimeStamp;
    }

    return axios(config);
}

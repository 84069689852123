import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import {useNavigate, useParams} from 'react-router-dom';
import SPILOGO from '../../assets/images/SpiLogo.svg';
import { useDispatch, useSelector } from "react-redux";
import { confirmEmail } from "../../store/slices/user.slice";
import { useMediaQuery } from "@mui/material";

const ConfirmEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();

    const { isEmailConfirmed } = useSelector((state) => state.user);
    const isMobileOrTabletView = useMediaQuery('(max-width: 960px)'); // Adjust the breakpoint as needed

    useEffect(() => {
        dispatch(confirmEmail({ confirmToken: token }));
    }, [])

    if (!isEmailConfirmed) {
        return (<div />);
    }

    return (
        <>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="h3" variant="h5" align="center">
                                Your email address has been successfully confirmed!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} pt={2}>
                            {isMobileOrTabletView ? (
                              <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={() => {
                                    window.location.replace('sipsender://Login');

                                    setTimeout(() => {
                                        navigate('/login')
                                    }, 3000);
                                }}
                              >
                                  Open SipSender App
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={() => navigate('/login')}
                              >
                                  Back to the login page
                              </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ConfirmEmail;

import React, { useEffect, useState } from 'react';
import { Box, Grid, Select, MenuItem } from '@mui/material';
import Engagement from '../../components/Analyze/Engagement';
import VisitorInsights from '../../components/Analyze/VisitorInsights';
import StatCard from '../../components/Home/StatCard';
import giftGrey from '../../assets/images/gift-grey.svg';
import notificationGreen from '../../assets/images/notification-status-green.svg';
import peopleIcon from '../../assets/images/people.svg';
import notificationGrey from '../../assets/images/notification-status-grey.svg';
import { SearchFilter } from '../../components/Manage/SearchFilter';
import { ResponsiveContainer } from 'recharts';
import { getStats, getStatsActive, getStatsClaimed, getStatsDrinks, getStatsUpcoming, setStatDetails } from '../../store/slices/analyze.slice';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { isUndefined } from 'lodash';

const isUndefinedOrNull = (item) => {
    return isUndefined(item) || item == null || item == ''
}

const Analyze = () => {
    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState('All');

    const [selectedDateFilter, setSelectedDateFilter] = useState('');
    const [dateRange, setDateRange] = useState({ fromTimeStamp: null, toTimeStamp: null });
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    
    useEffect(() => {
        setDateRange({
            fromTimeStamp: startDateTime,
            toTimeStamp: endDateTime,
        });
    }, [startDateTime, endDateTime]);

    useEffect(() => {
        dispatch(setStatDetails(undefined))

        let fromTimeStamp, toTimeStamp
        if (!isUndefined(dateRange) && !isUndefinedOrNull(dateRange.fromTimeStamp) && !isUndefinedOrNull(dateRange.toTimeStamp)) {
            fromTimeStamp = dayjs(dateRange.fromTimeStamp).format('MM-DD-YYYY HH:mm:ss')
            toTimeStamp = dayjs(dateRange.toTimeStamp).format('MM-DD-YYYY HH:mm:ss')
        }
        console.log('date', dateRange)
        const params = {
            filter: selectedFilter,
            fromTimeStamp,
            toTimeStamp
        }
        
        dispatch(getStats(params));
    }, [dispatch, selectedFilter, dateRange]);

    const { stats, engagement, attendees, statDetails } = useSelector((state) => state.analyze);

    const onShowStatsDetails = (type) => {
        let fromTimeStamp, toTimeStamp
        if (!isUndefined(dateRange) && !isUndefinedOrNull(dateRange.fromTimeStamp) && !isUndefinedOrNull(dateRange.toTimeStamp)) {
            fromTimeStamp = dayjs(dateRange.fromTimeStamp).format('MM-DD-YYYY HH:mm:ss')
            toTimeStamp = dayjs(dateRange.toTimeStamp).format('MM-DD-YYYY HH:mm:ss')
        }
        console.log('date', dateRange)
        const params = {
            filter: selectedFilter,
            fromTimeStamp,
            toTimeStamp
        }

        if (type == 'claimed') {
            return dispatch(getStatsClaimed(params))
        } else if (type == 'active') {
            return dispatch(getStatsActive(params))
        } else if (type == 'upcoming') {
            return dispatch(getStatsUpcoming(params))
        } else {
            return dispatch(getStatsDrinks(params))
        }
    }

    const onHideStatsDetails = () => {
        dispatch(setStatDetails(undefined))
    }

    const handleChangeDate = (e, key) => {
        key === 'startDate' ? setStartDateTime(e.target.value) : setEndDateTime(e.target.value);
    };

    const handleRangeSelection = (e) => {
        const id = e.target.value;
        setSelectedDateFilter(id == 4 ? 'custom' : '');
        let startDate = null;
        let endDate = null;
        if (id == 1) {
            startDate = dayjs().startOf('day');
            endDate = dayjs().endOf('day');
        } else if (id == 2) {
            startDate = dayjs().startOf('week');
            endDate = dayjs().endOf('week');
        } else if (id == 3) {
            startDate = dayjs().startOf('month');
            endDate = dayjs().endOf('month');
        } else if (id == 4) {
            startDate = startDateTime;
            endDate = endDateTime;
        }
        setDateRange({
            fromTimeStamp: startDate,
            toTimeStamp: endDate,
        });
    };

    return (
        <Grid container gap={'24px'} sx={{ px: { xs: 1, md: 0 } }}>
            <Grid item xs={12} sx={{ mt: { xs: 2, md: 0 } }}>
                <Grid container spacing={'20px'}>
                    <Grid item xs={12} md={6}>
                        <SearchFilter 
                        handleChangeDate={handleChangeDate}
                        handleRangeSelection={handleRangeSelection}
                        selectedDate={selectedDateFilter}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Box className="flex-fill search-main-div">
                            <Select
                                id="demo-select-small"
                                className="select-dropdown"
                                defaultValue={'All'}
                                value={selectedFilter}
                                onChange={(event) => setSelectedFilter(event.target.value)}
                            >
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'All Events'}>All Events</MenuItem>
                                <MenuItem value={'All Offers'}>All Offers</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <StatCard 
                statsData={stats} 
                statCardHeading={false} 
                mediumScreenRation={2} 
                onShowStatsDetails={onShowStatsDetails}
                onHideStatsDetails={onHideStatsDetails}
                statDetails={statDetails}
                canShowDetails={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing="30px">
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <ResponsiveContainer width="100%" height={300}>
                            <VisitorInsights data={attendees}/>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <ResponsiveContainer width="100%" height={300}>
                            <Engagement data={engagement}/>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Analyze;

import React from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({ buttonTitle, buttonOnClick, buttonDisabled, icon, cancelButtonTitle, navigationRoute }) => {
    const navigate = useNavigate();
    return (
        <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent="end" gap={3}>
                <Button sx={{ color: '#344054', textTransform: 'none' }} onClick={() => navigate(navigationRoute)}>
                    {cancelButtonTitle}
                </Button>
                <Button
                    sx={{
                        textTransform: 'none',
                        color: '#ffffff',
                        backgroundColor: buttonDisabled ? '#98A2B3' : '#7F56D9',
                        borderRadius: '8px',
                        px: 2,
                        '&:hover': {
                            backgroundColor: buttonDisabled ? '#98A2B3' : '#42307d',
                            color: '#ffffff !important',
                        },
                        '&:disabled': {
                            color: '#ffffff',
                            cursor: 'not-allowed',
                            pointerEvents: 'all !important',
                        },
                    }}
                    startIcon={icon}
                    disabled={buttonDisabled}
                    onClick={buttonOnClick}
                >
                    {buttonTitle}
                </Button>
            </Grid>
        </Grid>
    );
};

export default CustomButton;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Paper,
    TextField,
    MenuItem,
    Select,
    Typography,
    FormControl,
    IconButton,
    Box
} from '@mui/material';
import SEND_DRINK_IMAGE from '../../assets/images/send_drink.png';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import { sendDrink } from '../../store/slices/wallet.slice';
import { useParams, useNavigate } from 'react-router-dom';

const defaultTipOptions = [{
    percent: 5,
    title: '5%'
}, {
    percent: 10,
    title: '10%'
}, {
    percent: 15,
    title: '15%'
}, {
    percent: 20,
    title: '20%'
}]

const amountOptions = [{title: '$5', amount: 5}, {title: '$9', amount: 9}, {title: '$15', amount: 15}, {title: '$22', amount: 22}]

const OptionsView = ({options, selectedIdx, onChange}) => {
    return (
        <Grid container xs={12} style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
            {options.map((option, idx) => {
                const isSelected = idx == selectedIdx
                const marginRight = idx == options.length - 1 ? 0 : 8
                return (
                    <Grid item style={{padding: 4, backgroundColor: isSelected ? '#7F56D9' : '#F2F4F7', borderRadius: 8, justifyContent: 'center', alignItems: 'center', marginRight}}>
                        <Button onClick={() => onChange(idx)}>
                            <Grid container style={{display: 'flex', flexDirection: 'column'}}>
                                <span style={{color: isSelected ? '#fff' : '#667085', fontSize: 17}}>{option.title}</span>
                            
                                {option.description && 
                                <span style={{color: isSelected ? '#fff' : '#667085', fontSize: 13}}>{option.description}</span>
                                }
                            </Grid>
                        </Button>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const QuantityView = ({quantity, onDecrementPress, onIncrementPress}) => {
    return (
        <Grid item style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 16, borderColor: '#D0D5DD', borderWidth: 1, borderStyle: 'solid', borderRadius: 8, paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8, alignSelf: 'center'}}>
            <IconButton style={{padding: 8, backgroundColor: '#667085', borderRadius: 4}} onClick={onDecrementPress}>
                <RemoveIcon style={{color: '#fff'}}/>
            </IconButton>
            <Typography variant='h4' style={{marginLeft: 16, marginRight: 16}}>
                {quantity}
            </Typography>
            <IconButton style={{padding: 8, backgroundColor: '#7F56D9', borderRadius: 4}} onClick={onIncrementPress}>
                <AddIcon style={{color: '#fff'}}/>
            </IconButton>
        </Grid>
    )
}

const SeparatorView = () => {
    return (
        <Grid item xs={12}>
            <Box style={{width: '100%', height: 1, backgroundColor: '#F2F4F7'}}/>
        </Grid>
    )
}

const TotalView = ({total}) => {
    if (total == 0) {
        return null
    }
    return (
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
            <Typography pb={1} variant="body1">
                Total
            </Typography>

            <Typography pb={1} variant="caption">
                &nbsp; (incl. tip & fees)
            </Typography>

            <Box style={{flex: 1}}/>

            <Typography pb={1} variant="h4" color={'#7F56D9'}>
                ${total.toFixed(2)}
            </Typography>
        </Grid>
    )
}

const SendButton = ({isDisabled, onClick}) => {
    return (
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button
                sx={{
                    textTransform: 'none',
                    color: '#ffffff',
                    backgroundColor: isDisabled ? '#98A2B3' : '#7F56D9',
                    borderRadius: '8px',
                    px: 2,
                    '&:hover': {
                        backgroundColor: isDisabled ? '#98A2B3' : '#42307d',
                        color: '#ffffff !important',
                    },
                    '&:disabled': {
                        color: '#ffffff',
                        cursor: 'not-allowed',
                        pointerEvents: 'all !important',
                    },
                }}
                startIcon={<SendIcon/>}
                disabled={isDisabled}
                onClick={onClick}>
                Send
            </Button>   
        </Grid>
    )
}

const VisibilityView = ({value, onChange}) => {
    const visibilityOptions = ['Public', 'Private']
    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <Select
                    size="small"
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    name="visibility"
                >
                    {visibilityOptions?.map((option) => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Grid>
    )
}

const SendDrink = () => {
    // Props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, name } = useParams();
    const [visibility, setVisibility] = useState('Public')
    const [quantity, setQuantity] = useState(1)
    const [message, setMessage] = useState('')
    const [amount, setAmount] = useState(0)
    const [selectedAmountIdx, setSelectedAmountIdx] = useState()
    const [tipOptions, setTipOptions] = useState(defaultTipOptions)
    const [tipAmount, setTipAmount] = useState(null)
    const [selectedTipIdx, setSelectedTipIdx] = useState(null)
    const [totalAmount, setTotalAmount] = useState(0)

    const wallet = useSelector((state) => state.wallet);
    const {isApiDone} = wallet

    useEffect(() => {
        if (amount == 0) {
            return
        }
        const drinkAmount = amount * quantity
        const newOptions = tipOptions.map(option => {
            const optionAmount = option.percent / 100 * drinkAmount
            return {
                percent: option.percent,
                amount: optionAmount,
                title: `${option.percent}%`,
                description: `$${optionAmount.toFixed(2)}`
            }
        })

        setTipOptions(newOptions)
        setSelectedTipIdx(null)
        setTipAmount(null)

    }, [amount, quantity])

    useEffect(() => {
        if (amount == 0) {
            return
        }
        const drinkAmount = amount * quantity
        const fee = 0.99
        var totalAmount = drinkAmount + fee
        if (tipAmount != null && tipAmount != undefined) {
            totalAmount = totalAmount + tipAmount
        }
        setTotalAmount(totalAmount)
    }, [tipAmount, tipOptions])

    useEffect(() => {
        if (isApiDone) {
            navigate('/home');
        }
    }, [isApiDone]);

    // Actions
    const handleAmountChange = (idx) => {
        setAmount(amountOptions[idx].amount)
        setSelectedAmountIdx(idx)
    }

    const handleTipChange = (idx) => {
        setTipAmount(tipOptions[idx].amount)
        setSelectedTipIdx(idx)
    }

    const handleIncrementQuantity = () => {
        setQuantity(quantity + 1)
    }
    
    const handleDecrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    const handSendDrink = () => {
        const drinkAmt = parseInt((amount * quantity) * 100)
        const tipAmt = parseInt(tipAmount * 100)
        const totalAmt = parseInt(totalAmount * 100)
        
        const params = {
            totalAmount: totalAmt, 
            receiverId: id, 
            receiverName: name, 
            drinkAmount: drinkAmt, 
            tipAmount: tipAmt, 
            type: 'venue'
        }
        dispatch(sendDrink(params))
    }

    return (
        <Grid container p={3} justifyContent="center">
            <Grid item xs={12} md={9} lg={9} xl={7} mt={2}>
                <Paper sx={{ padding: '20px' }}>
                    <Grid container spacing={3} justifyContent={'center'}>

                        <VisibilityView value={visibility} onChange={setVisibility}/>

                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 8}}>
                            <img src={SEND_DRINK_IMAGE} style={{width: 220, height: 200, objectFit: 'contain'}} alt=''/>
                        </Grid>

                        <OptionsView options={amountOptions} selectedIdx={selectedAmountIdx} onChange={handleAmountChange}/>

                        <QuantityView quantity={quantity} onDecrementPress={handleDecrementQuantity} onIncrementPress={handleIncrementQuantity}/>

                        <Grid item xs={12}>
                            <TextField
                                name="message"
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Write a message"
                                fullWidth
                            />
                        </Grid>

                        <SeparatorView/>

                        <Grid item xs={12}>
                            <Typography pb={1} variant="body1">
                                Add a tip
                            </Typography>
                        </Grid>

                        <OptionsView options={tipOptions} selectedIdx={selectedTipIdx} onChange={handleTipChange}/>

                        <Grid item xs={12} style={{display: 'flex', flexDirection:'row', }}>
                            <InfoIcon/>
                            <Typography pb={1} variant="caption" style={{marginLeft: 8}}>
                                SipSender charges a $0.99 convenience fee for sending drinks.
                            </Typography>
                        </Grid>

                        <SeparatorView/>

                        <TotalView total={totalAmount}/>

                        <SendButton isDisabled={totalAmount == 0} onClick={handSendDrink} />

                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default SendDrink;
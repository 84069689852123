import { call, put } from 'redux-saga/effects';
import { getAllQuestions } from '../requests/user.request';
import { setQuestion } from '../../slices/questions.slice';

export function* questionsHandler(action) {
    try {
        const accessTokenObj = JSON.parse(localStorage.getItem('accessToken'));
        const response = yield call(getAllQuestions, accessTokenObj);

        const { data } = response;

        const questionsData = data.questions;
        yield put(setQuestion({ questionsData }));
    } catch (error) {
        console.log(error);
    }
}

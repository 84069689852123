import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Grid, Typography, Chip, ListItem } from '@mui/material';
import { APP_CONSTANTS } from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { getUpComingPromotions } from '../../store/slices/promotion.slice';
import SPI_LOGO from '../../assets/images/SpiLogo.svg';
import HOME_ICON from '../../assets/images/HomeIcon.svg';
import CREATE_ICON from '../../assets/images/addIcon.svg';
import MANAGE_ICON from '../../assets/images/settingIcon.svg';
import ANALYZE_ICON from '../../assets/images/analyzeIcon.svg';
import MESSAGE_ICON from '../../assets/images/messageIcon.svg';
import OffersActivity from '../Offers/OffersActivity';
import SecondaryTopAppBar from '../../views/Home/SecondaryAppBar';
import { isEmpty } from 'lodash';
const { APP_BASE_URL } = APP_CONSTANTS;

const { APP_TITLE } = APP_CONSTANTS;

export default function PrimarySearchAppBar({ children, to, ...props }) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    const [selectedItem, setSelectedItem] = useState(null);
    const matches = useMediaQuery('(max-width:1023px)');

    const user = useSelector((state) => state.user);

    const promotions = useSelector((state) => state.promotions);
    const { upComingPromotions, isApiDone: isPromotionApiDone } = promotions;
    const accessToken = localStorage.getItem('accessToken');

    const handleItemClick = (item) => {
        setSelectedItem(item);
        navigate(item.path);
    };

    const handleLogoutCallBack = useCallback(() => {
        if (accessToken) {
            dispatch(getUpComingPromotions());
        }
    }, [isPromotionApiDone]);

    useEffect(() => {
        handleLogoutCallBack();
    }, [handleLogoutCallBack]);

    const Routes = [
        {
            id: 1,
            name: 'Home',
            path: '/home',
            icon: HOME_ICON,
        },
        {
            id: 2,
            name: 'Create',
            path: '/create',
            icon: CREATE_ICON,
        },
        {
            id: 3,
            name: 'Manage',
            path: '/manage',
            icon: MANAGE_ICON,
        },
        {
            id: 4,
            name: 'Analyze',
            path: '/analyze',
            icon: ANALYZE_ICON,
        },
        {
            id: 5,
            name: 'Messages',
            path: '/inbox/0',
            icon: MESSAGE_ICON,
        },
    ];

    const activeColor = '#7F56D9';
    const nonActiveColor = '#667085';

    return (
        <Fragment>
            {matches ? (
                <SecondaryTopAppBar />
            ) : (
                <AppBar position="static" className="main-navbar">
                    <Toolbar className="nvabar" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <NavLink to="/">
                            <img
                                src={SPI_LOGO}
                                alt={APP_TITLE}
                                style={{ width: '196px', height: '30px' }}
                                className="AppLogo"
                            />
                        </NavLink>

                        <Grid container gap="10px" width="100%" mt="15px" height="38vh">
                            {Routes.length &&
                                Routes.map((item) => (
                                    <ListItem
                                        key={item.id}
                                        to={item.path}
                                        component={NavLink}
                                        className={`list-item ${location.pathname === item.path ? 'selected' : ''}`}
                                        onClick={() => handleItemClick(item)}
                                    >
                                        <Grid container gap="10px" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={item.icon}
                                                    alt="Home"
                                                    style={{
                                                        filter:
                                                            location.pathname === item.path
                                                                ? `invert(36%) sepia(93%) saturate(4258%) hue-rotate(252deg) brightness(87%) contrast(88%)`
                                                                : `invert(70%) sepia(0%) saturate(5%) hue-rotate(0deg) brightness(103%) contrast(90%)`,
                                                        fill:
                                                            location.pathname === item.path
                                                                ? activeColor
                                                                : nonActiveColor,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography className="item-text">{item.name}</Typography>
                                            </Grid>
                                            {item.badge ? (
                                                <Grid item flexGrow={1} textAlign="right">
                                                    <Chip
                                                        label={item.badge}
                                                        sx={{
                                                            background: activeColor,
                                                            color: 'white',
                                                            width: '24px',
                                                            height: '24px',
                                                        }}
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </ListItem>
                                ))}
                        </Grid>

                        <Grid container gap="10px" width="100%" mt="20px">
                            <Typography
                                sx={{
                                    fontSize: '17px',
                                    pb: '17px',
                                    lineHeight: '150%',
                                    color: '#1D2939',
                                    fontWeight: '600',
                                }}
                            >
                                Upcoming Offers
                            </Typography>

                            <Grid
                                container
                                gap="10px"
                                sx={{
                                    height: '32vh',
                                    minHeight: '32vh',
                                    maxHeight: '32vh',
                                    overflow: 'hidden',
                                    overflowY: 'scroll',
                                }}
                            >
                                {!isEmpty(user) &&
                                    upComingPromotions.map((promotion) => (
                                        <div key={promotion.id} style={{minWidth: '200px', height: '50px' }}>
                                        <OffersActivity
                                            badgeClass="bg-purple"
                                            key={promotion.id}
                                            title={promotion.title}
                                            date={promotion.start_timestamp}
                                            subTitle={promotion.description}
                                            backgroundImageURL={APP_BASE_URL + promotion.cover_image_url}
                                            cusHeight="80px"
                                        />
                                        </div>
                                    ))}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}
        </Fragment>
    );
}

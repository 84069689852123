import { isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { QrReader } from 'react-qr-reader';
import { redeemDrink } from '../../store/slices/wallet.slice';

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);

const DrinkScanner = () => {
    const dispatch = useDispatch();
    const [showReader, setShowReader] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isProcessing, setIsProcessing] = useState(false)
    const wallet = useSelector((state) => state.wallet);
    const {isApiDone} = wallet
    
    const handleScan = (data) => {
        if (isUndefinedOrEmpty(data) || isProcessing) {
            return
        }
        const params = {
            uuid: data
        }
        console.log(params)
        setIsProcessing(true)
        dispatch(redeemDrink(params))
    }
    
    const requestPermsissions = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(function (stream) {
                setShowReader(true)
            })
            .catch(function (error) {
                setShowReader(false)
                setErrorMessage(error.message)
            });
        } else {
            setShowReader(false)
            setErrorMessage('Browser doesn\'t support camera.')
        }
    }

    useEffect(() => {
        requestPermsissions()
    }, []);

    useEffect(() => {
        setIsProcessing(false)
    }, [isApiDone]);

    useEffect(() => {
        return () => {
          setShowReader(false)
        };
      }, []);

    const ReaderView = () => {
        if (!showReader) {
            return null
        }
        return (
            <Grid item xs={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Typography pb={1} variant="body1" style={{width: '100%', textAlign: 'center'}}>
                    Scan QR-Code from SipSender mobile app to redeem drink
                </Typography>
                <QrReader
                    videoContainerStyle={{width: '100%', paddingTop: '75%'}}
                    videoStyle={{borderRadius: '20px'}}
                    onResult={(result, error) => {
                        if (!!result) {
                            handleScan(result?.text)
                        }
                    }}
                />
            </Grid>
        )
    }

    const ErrorView = () => {
        if (isUndefinedOrEmpty(errorMessage)) {
            return null
        }
        return (
            <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography pb={1} variant="body1" color={'#f00'}>{errorMessage}</Typography>
            </Grid>
        )
    }

    return (
        <Grid container p={3} justifyContent="center">
            <Grid item xs={12} md={9} lg={9} xl={7} mt={2}>
                <Paper sx={{ padding: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography pb={1} variant="h4">Redeem Drink</Typography>
                        </Grid>
                        {ReaderView()}
                        {ErrorView()}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DrinkScanner;
import dayjs from 'dayjs';
import { isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Divider,
    IconButton,
    InputAdornment,
} from '@mui/material';

import utcPlugin from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
import CustomButton from '../../components/PromotionPackageButton/CustomButton';
import { APP_CONSTANTS } from '../../config/config';
import { uploadImage } from '../../store/sagas/requests/image.request.js';
import { createNewPackage, getPackageDetail, getPackageTypes, updatePackage } from '../../store/slices/package.slice';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import PromoPreview from '../CreatePromotion/PromoPreview';
import USER_AVATAR_1 from '../../assets/images/user_avatar_1.png';
import USER_AVATAR_2 from '../../assets/images/user_avatar_2.png';

const defaultFormInput = {
    packageTypeId: '',
    title: '',
    description: '',
    coverImageUrl: '',
    imageUrl2: '',
    imageUrl3: '',
    startTimeStamp: '',
    endTimeStamp: '',
    duration: '',
    ageRestriction: '',
    promotionsIds: '',
    claims: '',
    about: '',
    rules: '',
};

const defaultSchedule = [
    {
        code: 'DD',
        title: 'Every day',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'DW',
        title: 'Working days',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'DS',
        title: 'Weekends',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D0',
        title: 'MON',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D1',
        title: 'TUE',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D2',
        title: 'WED',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D3',
        title: 'THU',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D4',
        title: 'FRI',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
];

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);
const parseScheduleDays = (schedule, useFullDays) => {
    if (isUndefinedOrEmpty(schedule)) {
      return undefined
    }
    const codes = schedule.split(',').map(sc => sc.split('=')[0])
    if (codes.length == 0) {
      return undefined
    }
    if (codes[0] == 'DD') return 'Every day'
    if (codes[0] == 'DW') return 'Weekdays'
    if (codes[0] == 'DS') return 'Weekends'
    const days = useFullDays ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] : ['Mo', 'Tu', 'We', 'Th', 'Fr']
    return codes.map(code => days[parseInt(code.charAt(1))]).join(', ')
}

const parseScheduleTime = (schedule) => {
    if (isUndefinedOrEmpty(schedule)) {
      return 'Varies'
    }
    var scheduleTime = undefined
    const days = schedule.split(',')
    for (const day of days) {
      const tokens = day.split('=')
      if (tokens.length > 2) {
        const time = tokens[1] + ' - ' + tokens[2]
        if (scheduleTime == undefined) {
          scheduleTime = time
        } else if (scheduleTime != time) {
          scheduleTime = 'Varies'
          break
        }
      }
    }
    return scheduleTime || 'Varies'
}

const CreatePackage = () => {
    dayjs.extend(utcPlugin);
    const { id } = useParams();
    console.log('package id', id);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isFrequencySelected, setFrequencySelected] = useState(false);
    const [isReviewing, setIsReviewing] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [formInput, setFormInput] = useState(defaultFormInput);
    const [isEditing, setIsEditing] = useState(false);
    const [existingImagesCount, setExistingImagesCount] = useState(0);
    const packages = useSelector((state) => state.packages);
    const matches = useMediaQuery('(max-width:1023px)');
    const [schedule, setSchedule] = useState(defaultSchedule);
    const { APP_BASE_URL } = APP_CONSTANTS;

    const { packageTypes, isApiDone, packageToEdit } = packages;

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const dispatchSnackBar = useCallback((type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    }, []);

    useEffect(() => {
        if (id) {
            console.log('getPackageDetail');
            dispatch(getPackageDetail(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (packageToEdit) {
            setIsEditing(true);
            setFormInput({
                title: packageToEdit.package_title,
                description: packageToEdit.description,
                coverImageUrl: packageToEdit.cover_image_url,
                imageUrl2: packageToEdit.image_url_2,
                imageUrl3: packageToEdit.image_url_3,
                ageRestriction: packageToEdit?.age_restriction?.toString(),
                duration: packageToEdit?.duration?.toString(),
                packageTypeId: packageToEdit.package_type_id,
                promotionsIds: packageToEdit?.promotions?.join(','),
                claims: packageToEdit?.claims?.toString(),
                about: packageToEdit.about,
                rules: packageToEdit.rules,
            });
            setStartDate(dayjs.utc(packageToEdit.start_timestamp).format('YYYY-MM-DD'));
            if (packageToEdit.end_timestamp) {
                setEndDate(dayjs.utc(packageToEdit.end_timestamp).format('YYYY-MM-DD'));
            }
            if (packageToEdit.cover_image_url) {
                setExistingImagesCount((prevCount) => prevCount + 1);
            }

            if (packageToEdit.image_url_2) {
                setExistingImagesCount((prevCount) => prevCount + 1);
            }

            if (packageToEdit.image_url_3) {
                setExistingImagesCount((prevCount) => prevCount + 1);
            }
            setSchedule(decodeSchedule(packageToEdit.frequency));
            setFrequencySelected(!isUndefinedOrEmpty(packageToEdit.frequency))
        }
    }, [packageToEdit]);

    const handleInputChange = (name, value) => {
        if (name === 'startTimeStamp') {
            value = value + ':00.000';
        }
        if (name === 'claims') {
            const newValue = parseInt(value);
            if (isNaN(newValue) || newValue < 1) {
                value = '';
            }
        }
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    // const uploadPackageImages = async () => {
    //     try {
    //         const keys = ['coverImageUrl', 'imageUrl2', 'imageUrl3'];
    //         const accessToken = localStorage.getItem('accessToken');

    //         if (selectedImages.length > 0 && accessToken) {
    //             for (let index = 0; index < selectedImages.length; index++) {
    //                 const file = selectedImages[index];
    //                 const formData = new FormData();
    //                 formData.append('image', file);

    //                 const response = await uploadImage({ accessToken: accessToken, form: formData });
    //                 const { data } = response;

    //                 if (data.status) {
    //                     if (isEditing) {
    //                         setFormInput((prevFormInput) => {
    //                             if (isEmpty(prevFormInput.coverImageUrl)) {
    //                                 return { ...prevFormInput, coverImageUrl: data.imageUrl };
    //                             } else if (isEmpty(prevFormInput.imageUrl2)) {
    //                                 return { ...prevFormInput, imageUrl2: data.imageUrl };
    //                             } else if (isEmpty(prevFormInput.imageUrl3)) {
    //                                 return { ...prevFormInput, imageUrl3: data.imageUrl };
    //                             }
    //                             return prevFormInput;
    //                         });
    //                     } else {
    //                         setFormInput({ ...formInput, [keys[index]]: data.imageUrl });
    //                     }
    //                 } else {
    //                     console.log('Error uploading image:', response);
    //                     dispatchSnackBar('error', 'Error uploading image, please try again', true);
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         dispatchSnackBar('error', 'Error uploading image, please try again', true);
    //         console.log('Exception occurred during image upload:', error);
    //     }
    // };
    const uploadPackageImages = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const uploadedImageURLs = [];

            if (selectedImages.length > 0 && accessToken) {
                for (let index = 0; index < selectedImages.length; index++) {
                    const file = selectedImages[index];
                    const formData = new FormData();
                    formData.append('image', file);

                    const response = await uploadImage({ accessToken: accessToken, form: formData });
                    const { data } = response;

                    if (data.status) {
                        uploadedImageURLs.push(data.imageUrl);
                    } else {
                        console.log('Error uploading image:', response);
                        dispatchSnackBar('error', 'Error uploading image, please try again', true);
                    }
                }
            }

            return uploadedImageURLs;
        } catch (error) {
            dispatchSnackBar('error', 'Error uploading image, please try again', true);
            console.log('Exception occurred during image upload:', error);
            return [];
        }
    };

    const handleFileInputChange = (event) => {
        const files = Array.from(event.target.files);
        if (files && files.length > 0) {
            addSelectedImage(files);
        }
    };

    const addSelectedImage = (files) => {
        const updatedImages = [...selectedImages];

        if (selectedImages.length === 0) {
            updatedImages.push(files[0]); // Add the first selected image
        } else {
            updatedImages.splice(1, 0, files[0]); // Add the subsequent selected images after the first image
        }

        setSelectedImages(updatedImages.slice(0, 3)); // Store only up to 3 selected images
    };

    const handleDrop = (event) => {
        event.preventDefault();

        const files = Array.from(event.dataTransfer.files);
        if (files && files.length > 0) {
            addSelectedImage(files);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
    };

    const handleReview = (value) => {
        setIsReviewing(value);
    };

    useEffect(() => {
        dispatch(getPackageTypes());
    }, []);

    useEffect(() => {
        if (isApiDone) {
            navigate('/manage');
        }
    }, [isApiDone]);

    const handleCreatePackage = async (e) => {
        e.stopPropagation();
        // handle cover photos upload and set them accordingly
        const keys = ['coverImageUrl', 'imageUrl2', 'imageUrl3'];
        const uploadedImageURLs = await uploadPackageImages();
        let form = { ...formInput };

        // Update the image URLs in the form input
        if (uploadedImageURLs.length > 0) {
            uploadedImageURLs.forEach((imageUrl, index) => {
                const key = keys[index];
                if (isEmpty(form[key])) {
                    form[key] = imageUrl;
                }
            });
        }

        form.startTimeStamp = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
        if (endDate) {
            form.endTimeStamp = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
        }

        ['ageRestriction', 'promotionsIds', 'endTimeStamp', 'imageUrl2', 'imageUrl3', 'duration', 'claims'].map(
            (key) => {
                if (isEmpty(form[key]) || isUndefined(form[key])) {
                    delete form[key];
                }
            },
        );
        if (isFrequencySelected) {
            form['frequency'] = encodedSchedule();
        }

        console.log('package form to submit', form);

        dispatch(createNewPackage(form));
    };

    const handleUpdatePackage = async (e) => {
        e.stopPropagation();
        const keys = ['coverImageUrl', 'imageUrl2', 'imageUrl3'];
        const uploadedImageURLs = await uploadPackageImages();
        let form = { ...formInput };

        // Update the image URLs in the form input
        if (uploadedImageURLs.length > 0) {
            uploadedImageURLs.forEach((imageUrl, index) => {
                if (isEmpty(form.coverImageUrl)) {
                    form.coverImageUrl = imageUrl;
                } else if (isEmpty(form.imageUrl2)) {
                    form.imageUrl2 = imageUrl;
                } else if (isEmpty(form.imageUrl3)) {
                    form.imageUrl3 = imageUrl;
                }
            });
        }

        form.startTimeStamp = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
        if (endDate) {
            form.endTimeStamp = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
        }

        ['ageRestriction', 'promotionsIds', 'endTimeStamp', 'imageUrl2', 'imageUrl3', 'duration', 'claims'].map(
            (key) => {
                if (isEmpty(form[key]) || isUndefined(form[key])) {
                    delete form[key];
                }
            },
        );
        if (isFrequencySelected) {
            form['frequency'] = encodedSchedule();
        }

        console.log('package form to update', form);
        form = { ...form, packageId: id };
        dispatch(updatePackage(form));
    };

    const setScheduleItemSelected = (item, isSelected) => {
        if (isSelected) {
            const isGroup = (item) => item.code == 'DD' || item.code == 'DW' || item.code == 'DS';
            if (isGroup(item)) {
                schedule.forEach((sc) => (sc.isSelected = false));
            } else {
                schedule.forEach((sc) => {
                    if (isGroup(sc)) sc.isSelected = false;
                });
            }
        }
        item.isSelected = isSelected;

        const idx = schedule.findIndex((sc) => sc.code == item.code);
        if (idx != -1) {
            schedule[idx] = item;
        }
        setSchedule([...schedule]);
    };

    const setScheduleItemStart = (item, startTime) => {
        item.startTime = startTime;
        updateScheduleItem(item);
    };

    const setScheduleItemEnd = (item, endTime) => {
        item.endTime = endTime;
        updateScheduleItem(item);
    };

    const updateScheduleItem = (item) => {
        const idx = schedule.findIndex((sc) => sc.code == item.code);
        if (idx != -1) {
            schedule[idx] = item;
        }
        setSchedule([...schedule]);
    };

    const encodedSchedule = () => {
        const formatted = (time) => dayjs(time, 'HH:mm').format('hh:mm a');
        return schedule
            .filter((sc) => sc.isSelected && sc.startTime != undefined && sc.endTime != undefined)
            .map((sc) => `${sc.code}=${formatted(sc.startTime)}=${formatted(sc.endTime)}`)
            .join(',');
    };

    const decodeSchedule = (encoded) => {
        var schedule = [...defaultSchedule];
        if (isUndefinedOrEmpty(encoded)) {
            return schedule;
        }
        encoded.split(',').forEach((str) => {
            const tokens = str.split('=');
            const idx = schedule.findIndex((sc) => sc.code == tokens[0]);
            if (idx != -1 && tokens.length > 2) {
                var item = schedule[idx];
                item.isSelected = true;
                item.startTime = dayjs(tokens[1], 'hh:mm a').format('HH:mm');
                item.endTime = dayjs(tokens[2], 'hh:mm a').format('HH:mm');
                schedule[idx] = item;
            }
        });
        return schedule;
    };

    const isReviewAllowed = () => {
        const selectedSchedule = schedule.findIndex(
            (sc) => sc.isSelected && !isUndefinedOrEmpty(sc.startTime) && !isUndefinedOrEmpty(sc.endTime),
        );
        return (
            formInput.title &&
            formInput.description &&
            (selectedImages.length + existingImagesCount != 0) &&
            startDate &&
            formInput.packageTypeId &&
            (!isFrequencySelected || selectedSchedule != -1) &&
            formInput.about
        );                                        
    };

    const ScheduleItemView = (item) => {
        return (
            <Grid
                key={item.title}
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    marginTop: '5px',
                }}
            >
                <Grid item xs={12} md={5} display="flex" flexDirection={'column'}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={item.isSelected}
                                    onChange={(e) => setScheduleItemSelected(item, e.target.checked)}
                                    disabled={isReviewing}
                                    name={`${item.title}Selected`}
                                    sx={{
                                        marginLeft: '10px',
                                        marginRight: '5px',
                                        borderRadius: '4px',
                                        '&.Mui-checked': {
                                            color: '#7F56D9',
                                        },
                                        '&.Mui-disabled': {
                                            '&.Mui-checked': {
                                                color: '#D0D5DD',
                                            },
                                        },
                                    }}
                                />
                            }
                            label={` ${item.title}`}
                            disabled={isReviewing}
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={7} display="flex" flexDirection={'row'} gap={2}>
                    <TextField
                        name={`${item.title}Start`}
                        type="time"
                        value={item.startTime}
                        onChange={(e) => setScheduleItemStart(item, e.target.value)}
                        placeholder="00:00"
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                    <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {' '}
                        -{' '}
                    </Typography>
                    <TextField
                        name={`${item.title}End`}
                        type="time"
                        value={item.endTime}
                        onChange={(e) => setScheduleItemEnd(item, e.target.value)}
                        placeholder="00:00"
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Divider sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} />
            </Grid>
        );
    };

    const ScheduleView = () => {
        return (
            <Grid item xs={12} display="flex" flexDirection={'column'}>
                <Grid
                    container
                    sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}
                >
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Schedule
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Time
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                {schedule.map((item) => ScheduleItemView(item))}
            </Grid>
        );
    };

    const userName = localStorage.getItem('userName');
    const userImageUrl = localStorage.getItem('userImageUrl');
    const userAddress = localStorage.getItem('userAddress');

    const PreviewView = () => {
        if (!isReviewing) {
            return null
        }
        const encoded = encodedSchedule()
        let imageUrl
        if (isEditing && formInput.coverImageUrl) {
            imageUrl = APP_BASE_URL + formInput.coverImageUrl
        } else if (selectedImages.length > 0) {
            imageUrl = URL.createObjectURL(selectedImages[0])
        }

        const event = {
            ...formInput,
            imageUrl,
            venue: {
                imageUrl: APP_BASE_URL + userImageUrl,
                name: userName,
                address: userAddress
            },
            friends: [{
                name: 'John',
                imageUrl: USER_AVATAR_1
            }, {
                name: 'Jane',
                imageUrl: USER_AVATAR_2
            }],
            day: parseScheduleDays(encoded),
            time: parseScheduleTime(encoded),
            totalClaims: formInput.claims,
            claimsCount: 0,
        }
        return (
            <PromoPreview event={event} isPromo={true}/>
        )
    }

    const HeaderView = () => {
        var title = id ? 'Edit Event Package' : 'Add Event Package'
        if (isReviewing) {
            title = 'User Preview'
        }
        return (
            <Grid item xs={12}>
                <Typography pb={1} variant="h4">{title}</Typography>
            </Grid>
        )
    }

    const FooterView = () => {
        return (
            <Grid item xs={12}>
                {isReviewing ? (
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Button
                                startIcon={<ArrowBackIcon />}
                                onClick={() => handleReview(false)}
                                sx={{
                                    color: '#667085',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    width: '300px'
                                }}
                            >
                                Back to Edit
                            </Button>

                            <CustomButton
                                buttonTitle={isEditing ? 'Update' : 'Publish'}
                                buttonOnClick={isEditing ? handleUpdatePackage : handleCreatePackage}
                                buttonDisabled={false}
                                icon={<UploadFileRoundedIcon />}
                                cancelButtonTitle={'Cancel'}
                                navigationRoute={'/manage'}
                            />
                    </div>
                ) : (
                    <CustomButton
                        buttonTitle={'Review'}
                        buttonOnClick={() => handleReview(true)}
                        buttonDisabled={!isReviewAllowed()}
                        icon={<VisibilityIcon />}
                        cancelButtonTitle={'Cancel'}
                        navigationRoute={'/manage'}
                    />
                )}
            </Grid>
        )
    }

    const FormView = () => {
        if (isReviewing) {
            return null
        }
        return (
            <>
                <Grid item xs={12}>
                    <Typography pb={1} variant="body1">
                        Category
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            size="small"
                            fullWidth
                            value={formInput.packageTypeId}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            name="packageTypeId"
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        >
                            <MenuItem value="" disabled>
                                Search
                            </MenuItem>
                            {packageTypes?.map((packageType) => {
                                return (
                                    <MenuItem key={packageType.id} value={packageType.id}>
                                        {packageType.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Package Name / Title
                    </Typography>
                    <TextField
                        id="title"
                        name="title"
                        type="text"
                        value={formInput.title}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Type Package Name / Title"
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Package Highlights
                    </Typography>
                    <TextField
                        id="description"
                        name="description"
                        type="text"
                        value={formInput.description}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Add short description"
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Full Offer Details
                    </Typography>
                    <TextField
                        name="about"
                        value={formInput.about}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Type something about your event package"
                        multiline
                        rows={3}
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Policies & Rules
                    </Typography>
                    <TextField
                        name="rules"
                        value={formInput.rules}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder={'1.\n2.\n3.'}
                        multiline
                        rows={3}
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                    <Typography pb={1} variant="body1">
                        Photo Gallery (up to 3 photos)
                    </Typography>
                    <Box
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        sx={{
                            border: '2px dashed #98A2B3',
                            minHeight: '100px',
                            borderRadius: '4px',
                            padding: '20px',
                            textAlign: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            backgroundColor: '#F9F5FF',
                            gap: '10px',
                        }}
                    >
                        {isEditing && formInput.coverImageUrl && (
                            <div
                                style={{
                                    width: isSmallScreen ? '100%' : '25%',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={APP_BASE_URL + formInput.coverImageUrl}
                                    alt="Cover 1"
                                    style={{ width: '100%' }}
                                />
                                <DeleteIcon
                                    sx={{
                                        position: 'absolute',
                                        background: '#667085',
                                        borderRadius: '4px',
                                        width: '25px',
                                        height: '25px',
                                        right: '10px',
                                        top: '9px',
                                        padding: '10px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setFormInput({ ...formInput, coverImageUrl: '' });
                                        setExistingImagesCount((prevCount) => prevCount - 1);
                                    }}
                                />
                            </div>
                        )}

                        {isEditing && formInput.imageUrl2 && (
                            <div
                                style={{
                                    width: isSmallScreen ? '100%' : '25%',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={APP_BASE_URL + formInput.imageUrl2}
                                    alt="Cover 2"
                                    style={{ width: '100%' }}
                                />
                                <DeleteIcon
                                    sx={{
                                        position: 'absolute',
                                        background: '#667085',
                                        borderRadius: '4px',
                                        width: '25px',
                                        height: '25px',
                                        right: '10px',
                                        top: '9px',
                                        padding: '10px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setFormInput({ ...formInput, imageUrl2: '' });
                                        setExistingImagesCount((prevCount) => prevCount - 1);
                                    }}
                                />
                            </div>
                        )}

                        {isEditing && formInput.imageUrl3 && (
                            <div
                                style={{
                                    width: isSmallScreen ? '100%' : '25%',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={APP_BASE_URL + formInput.imageUrl3}
                                    alt="Cover 3"
                                    style={{ width: '100%' }}
                                />
                                <DeleteIcon
                                    sx={{
                                        position: 'absolute',
                                        background: '#667085',
                                        borderRadius: '4px',
                                        width: '25px',
                                        height: '25px',
                                        right: '10px',
                                        top: '9px',
                                        padding: '10px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setFormInput({ ...formInput, imageUrl3: '' });
                                        setExistingImagesCount((prevCount) => prevCount - 1);
                                    }}
                                />
                            </div>
                        )}
                        {selectedImages.map((image, index) => (
                            <div
                                key={index}
                                style={{ width: isSmallScreen ? '100%' : '25%', position: 'relative' }}
                            >
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Cover ${index + 1}`}
                                    style={{ width: '100%' }}
                                />
                                <DeleteIcon
                                    sx={{
                                        position: 'absolute',
                                        background: '#667085',
                                        borderRadius: '4px',
                                        width: '25px',
                                        height: '25px',
                                        right: '10px',
                                        top: '9px',
                                        padding: '10px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        const updatedImages = [...selectedImages];
                                        updatedImages.splice(index, 1);
                                        setSelectedImages(updatedImages);
                                        isReviewing && handleReview(false);
                                    }}
                                />
                            </div>
                        ))}
                        {(isEditing
                            ? selectedImages.length + existingImagesCount < 3
                            : selectedImages.length < 3) &&
                            !isReviewing && (
                                <div
                                    style={
                                        (isEditing
                                            ? selectedImages.length + existingImagesCount
                                            : selectedImages.length) == 0
                                            ? { width: '100%' }
                                            : {
                                                    width: isSmallScreen ? '100%' : '25%',
                                                    border: '2px dashed #98A2B3',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: isSmallScreen ? 'auto' : '100%',
                                                }
                                    }
                                >
                                    {'Drop an image or '}
                                    <label
                                        htmlFor="cover-photo-input"
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Upload a file
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileInputChange}
                                        style={{ display: 'none' }}
                                        id="cover-photo-input"
                                        multiple
                                        max={isEditing ? 3 - existingImagesCount : 3}
                                    />
                                </div>
                            )}
                    </Box>
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} gap={3} xs={12}>
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Start date
                        </Typography>
                        <TextField
                            name="startTimeStamp"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="MM/DD/YYYY"
                            fullWidth
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        flexDirection={'column'}
                        sx={{ marginTop: { xs: 2, md: 0 } }}
                    >
                        <Typography pb={1} variant="body1">
                            End date
                        </Typography>
                        <TextField
                            name="endTimeStamp"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="MM/DD/YYYY"
                            type="date"
                            fullWidth
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        />
                    </Grid>
                </Grid>

                <Grid item sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} gap={3} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isFrequencySelected}
                                    onChange={(e) => setFrequencySelected(e.target.checked)}
                                    disabled={isReviewing}
                                    name={`Frequency`}
                                    sx={{
                                        marginLeft: '10px',
                                        marginRight: '5px',
                                        borderRadius: '4px',
                                        '&.Mui-checked': {
                                            color: '#7F56D9',
                                        },
                                        '&.Mui-disabled': {
                                            '&.Mui-checked': {
                                                color: '#D0D5DD',
                                            },
                                        },
                                    }}
                                />
                            }
                            label={`Frequency`}
                            disabled={isReviewing}
                        />
                    </FormGroup>
                </Grid>

                {isFrequencySelected && ScheduleView()}

                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Event Duration
                    </Typography>
                    <TextField
                        id="duration"
                        name="duration"
                        type="number"
                        value={formInput.duration}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="00:00:00"
                        fullWidth
                        inputProps={{ min: '0' }}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>

                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Number of available "tickets" to Claim
                    </Typography>
                    <TextField
                        id="claims"
                        name="claims"
                        type="number"
                        value={formInput.claims}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Unlimited"
                        inputProps={{
                            min: '0',
                            style: {
                                textAlign: formInput.claims && formInput.claims > 0 ? 'center' : 'left',
                            },
                        }}
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                        InputProps={{
                            startAdornment: !isReviewing && (
                                <InputAdornment position="start">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        style={{
                                            backgroundColor: '#7F56D9',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            marginLeft: '10px',
                                        }}
                                        disabled={isReviewing}
                                        onClick={() => {
                                            if (formInput.claims && formInput.claims > 1) {
                                                handleInputChange(
                                                    'claims',
                                                    parseInt(formInput.claims || 0) - 1,
                                                );
                                            } else {
                                                handleInputChange('claims', '');
                                            }
                                        }}
                                    >
                                        <RemoveIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: !isReviewing && (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        style={{
                                            backgroundColor: '#7F56D9',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                        }}
                                        disabled={isReviewing}
                                        onClick={() =>
                                            handleInputChange('claims', parseInt(formInput.claims || 0) + 1)
                                        }
                                    >
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </>
        )
    }

    return (
        <Grid container p={3} justifyContent="center">
            

            <Grid item xs={12} md={9} lg={9} xl={7} mt={2}>
                <Paper sx={{ padding: '20px' }}>
                    <Grid container spacing={3}>
                        {HeaderView()}
                        {FormView()}
                        {PreviewView()}
                        {FooterView()}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CreatePackage;

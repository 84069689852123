import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

const useDebouncedSearch = (value, delay) => {
    // const [search, setSearch] = useState('');
    // const [filteredData, setFilteredData] = useState(initialData);

    // useEffect(() => {
    //     const debouncedSearch = debounce((value) => {
    //         const filteredActive = initialData?.active?.filter((item) =>
    //             item.title.toLowerCase().includes(value.toLowerCase()),
    //         );
    //         const filteredUpcoming = initialData?.upComing?.filter((item) =>
    //             item.title.toLowerCase().includes(value.toLowerCase()),
    //         );
    //         const filteredPast = initialData?.past?.filter((item) =>
    //             item.title.toLowerCase().includes(value.toLowerCase()),
    //         );
    //         setFilteredData({
    //             active: filteredActive,
    //             upComing: filteredUpcoming,
    //             past: filteredPast,
    //         });
    //     }, delay);

    //     debouncedSearch(search);

    //     return () => {
    //         debouncedSearch.cancel();
    //     };
    // }, [search, initialData, delay]);

    // return {
    //     search,
    //     setSearch,
    //     filteredData,
    // };
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebouncedSearch;

import { Box, Button, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ADD_ICON from '../../assets/images/AddPromotionIcon.svg';
import giftGreen from '../../assets/images/gift-green.svg';
import giftGrey from '../../assets/images/gift-grey.svg';
import notificationGreen from '../../assets/images/notification-status-green.svg';
import notificationGrey from '../../assets/images/notification-status-grey.svg';
import peopleIcon from '../../assets/images/people.svg';
import PROMOTION_ONE from '../../assets/images/Promotion1.png';
import PROMOTION_TWO from '../../assets/images/promotion2.png';
import userTick from '../../assets/images/user-tick.svg';
import Notification from '../../components/Home/Notification';
import SipSender from '../../components/Home/SipSender';
import StatCard from '../../components/Home/StatCard';
import OffersActivity from '../../components/Offers/OffersActivity';
import Promotion from '../../components/Promotion/Promotion';
import { APP_CONSTANTS } from '../../config/config';
import { getHomeData } from '../../store/slices/wallet.slice';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
    const dispatch = useDispatch();
    const wallet = useSelector((state) => state.wallet);
    const {homeData} = wallet

    const [users, setUsers] = useState([])
    const [stats, setStats] = useState([])

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const matches = useMediaQuery('(max-width:1023px)');
    const upComingPromotions = useSelector((state) => state.promotions.upComingPromotions);
    const { APP_BASE_URL } = APP_CONSTANTS;

    useEffect(() => {
        dispatch(getHomeData({}))
    }, [dispatch])

    useEffect(() => {
        if (homeData == undefined || homeData.users == undefined) {
            return
        }
        setUsers(homeData.users)
        setStats(homeData.stats)
    }, [homeData])

    const promotionData = [
        {
            id: 1,
            img: PROMOTION_ONE,
            name: 'Create Promotion',
            detail: 'Use this feature to offer things like Happy Hours, Drink Specials to get the word out and drive traffic',
            action: (
                <Button
                    variant="outlined"
                    className="button-outlined"
                    startIcon={<img src={ADD_ICON} alt="add" />}
                    component={Link}
                    to="/create-promotion"
                >
                    Create promotion
                </Button>
            ),
        },
        {
            id: 2,
            img: PROMOTION_TWO,
            name: 'Add Event Package',
            detail: 'Use this feature to offer event packages and highlight your venue accommodations for larger parties',
            action: (
                <Button
                    variant="outlined"
                    className="button-outlined"
                    startIcon={<img src={ADD_ICON} alt="add" />}
                    component={Link}
                    to="/create-package"
                >
                    Add Event Package
                </Button>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <StatCard statsData={stats} statCardHeading={true} />
                </Grid>
                {!isSmallScreen && (
                    <Grid item xs={12} lg={4}>
                        <Notification />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={3.75} sx={{ px: { xs: 2, sm: 2, md: 0 } }}>
                {promotionData.map((item) => (
                    <Grid key={item.id} item xs={12} md={12} lg={6}>
                        <Promotion
                            key={item.id}
                            promotionImg={item.img}
                            promotionName={item.name}
                            promotionDetail={item.detail}
                            promotionAction={item.action}
                        />
                    </Grid>
                ))}
                {users.length > 0 &&
                <Grid item xs={12}>
                    <SipSender avatarList={users} />
                </Grid>
                }
                {upComingPromotions != null && upComingPromotions.length > 0 &&
                <Grid item xs={12}>
                    <Paper sx={{ backgroundColor: '#FFFFFF', borderRadius: '20px', p: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '17px', pb: 2 }}>
                            Current & Future Offers
                        </Typography>
                        <div style={{ overflowX: 'auto' }}>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                {upComingPromotions?.map((promotion) => (
                                    <div key={promotion.id} style={{ minWidth: '230px', height: '242px' }}>
                                        <OffersActivity
                                            badgeClass="bg-green"
                                            key={promotion.id}
                                            title={promotion.title}
                                            date={promotion.start_timestamp}
                                            subTitle={promotion.description}
                                            backgroundImageURL={APP_BASE_URL + promotion.cover_image_url}
                                            enableFooter={true}
                                            Footer={
                                                <Grid container>
                                                    <Grid item sx={{ padding: '0px 10px 5px 0px' }}>
                                                        <Typography className="offer-footer">
                                                            {`Every Monday: 5:00 - 7:00 pm`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                            marginTop="150px"
                                            cusHeight="239px"
                                        />
                                    </div>
                                ))}
                            </Box>
                        </div>
                    </Paper>
                </Grid>
                }
                {isSmallScreen && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '20px',
                                p: 2,
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '17px', pb: 2 }}>
                                Upcoming Offers
                            </Typography>
                            <Box
                                sx={{
                                    maxHeight: '450px',
                                    overflowY: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '5px',
                                }}
                            >
                                {upComingPromotions?.map((promotion) => (
                                    <div key={promotion.id} style={{ minWidth: '230px' }}>
                                        <OffersActivity
                                            badgeClass="bg-purple"
                                            key={promotion.id}
                                            title={promotion.title}
                                            date={promotion.start_timestamp}
                                            subTitle={promotion.description}
                                            backgroundImageURL={APP_BASE_URL + promotion.cover_image_url}
                                            enableFooter={false}
                                        />
                                    </div>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default Home;

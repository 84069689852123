import React, { Fragment } from 'react';
import {
    Grid,
    Box,
    Tab, 
    Tabs
} from '@mui/material';

function a11yProps(index) {
    return {
        id: `payment-tab-${index}`,
        'aria-controls': `payment-tabpanel-${index}`,
    };
}

const TabBar = (props) => {
    const { active, handleChange, } = props;

    var styles = {
        default_tab:{
          color: '#667085',
          backgroundColor: 'transparent',
          fontWeight: 400,
          fontSize: '18px',
          textTransform: 'none',
        },
        active_tab:{
          color: '#7F56D9',
        }
      }
    
      styles.tab = []
      styles.tab[0] = styles.default_tab;
      styles.tab[1] = styles.default_tab;
      styles.tab[active] = Object.assign({},   styles.tab[active], styles.active_tab);

    return (
        <Fragment>
            <Grid container item xs={12} ml={3} className="tab-background" sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Tabs value={active} onChange={handleChange} aria-label="payment tabs" className='payment-tab-root' TabIndicatorProps={{style: {backgroundColor: '#7F56D9'}}}>
                        {props.firstTab && (
                            <Tab style={styles.tab[0]} {...a11yProps(0)} label={props.firstTab} />
                        )}
                        {props.secondTab && (
                            <Tab style={styles.tab[1]} {...a11yProps(1)} label={props.secondTab} />
                        )}
                    </Tabs>
                </Box>
            </Grid>
        </Fragment>
    );
}

export default TabBar;
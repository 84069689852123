import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function uploadImage(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/uploadImage`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

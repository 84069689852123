import React, {useEffect, useState} from 'react';
import {
    Grid,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    MenuItem, 
    Select,
    Button,
    TextField,
    TablePagination
} from '@mui/material';
import { datesOptions } from '../../config/constants';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import dayjs from 'dayjs';

const styles = {
    tableHeader: {
        backgroundColor: '#F9FAFB',
    },
    rowCell: {
        fontSize: '15px', 
        color: '#344054',
        fontWeight: 400,
    },
    headerCell: {
        fontSize: '15px',
        color: '#1D2939',
        fontWeight: 600,
    },
    titleText: {
        fontSize: '15px',
        color: '#1D2939',
        fontWeight: 600,
    },
    balanceText: {
        fontSize: '20px',
        color: '#1D2939',
        fontWeight: 400,
    },
    filtersContainer: {
        backgroundColor: '#F9FAFB',
        borderRadius: '16px',
        padding: '16px',
        marginLeft: '18px',
        margingRight: '8px',
        marginTop: '8px'
    },
    filtersInnerContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    filtersCustomDateContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
        paddingLeft: '8px'
    },
    spacer: {
        flex: 1
    },
    downloadInvoices: {
        border: '1px solid #7F56D9',
        borderRadius: '10px',
        padding: '8px 16px',
        color: '#7F56D9',
        fontWeight: 600,
    },
    downloadCSV: {
        textDecoration: 'underline',
        textDecorationColor: '#7F56D9',
        color: '#7F56D9',
        padding: '8px 16px',
        fontWeight: 600,
    }
}

const FiltersView = (props) => {
    const {selectedDate, handleChangeDate, endDate, startDate, handleRangeSelection, handleDownloadInvoices, handleDownloadCSV} = props

    return (
        <Grid item xs={12} style={styles.filtersContainer}>
            <Grid container style={styles.filtersInnerContainer}>
                <Select
                    className="select-dropdown-payment"
                    onChange={handleRangeSelection}
                    defaultValue={datesOptions[4]?.id}
                >
                    {datesOptions?.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.title}
                            </MenuItem>
                        );
                    })}
                </Select>

                {selectedDate == 'custom' ? (
                    <Grid container style={styles.filtersCustomDateContainer} spacing={1}>
                        <Grid item>
                            <TextField
                                name="startTimeStamp"
                                value={startDate}
                                onChange={(e) => handleChangeDate(e, 'startDate')}
                                placeholder="MM/DD/YYYY"
                                type="date"
                                fullWidth
                                label="From"
                                style={{backgroundColor: '#fff'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField         
                                name="endTimeStamp"
                                value={endDate}                        
                                onChange={(e) => handleChangeDate(e, 'endDate')}
                                placeholder="MM/DD/YYYY"
                                type="date"
                                label="To"
                                style={{backgroundColor: '#fff'}}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                ): (
                    <Box style={{flex: 1}}/>
                )}
                
                {/* <Button startIcon={<SimCardDownloadIcon/>} style={styles.downloadInvoices} onClick={handleDownloadInvoices}>
                    Download Invoices
                </Button> */}

                <Button style={styles.downloadCSV} onClick={handleDownloadCSV}>
                    Download CSV
                </Button>
            </Grid>
        </Grid>
    )
}

const TypesView = (props) => {
    const {selectedType, handleTypeChange} = props
    const types = [{
        id: '0',
        title: 'All Transactions'
    }, {
        id: '1',
        title: 'Drink Redeemed'
    }, {
        id: '2',
        title: 'Withdraw'
    }]

    return (
        <Grid item xs={12} style={styles.filtersInnerContainer}>
            <Select
                className="select-dropdown-payment"
                onChange={handleTypeChange}
                value={selectedType}
            >
                {types?.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.id}>
                            {item.title}
                        </MenuItem>
                    );
                })}
            </Select>

            <Box style={{flex: 1}}/>
        </Grid>
    )
}

const HeaderView = (props) => {
    const {balance} = props
    return (
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
            <span style={styles.titleText}>Transaction History</span>
            <Box style={{flex: 1}}/>
            <span style={styles.titleText}>Balance: <span style={styles.balanceText}>{balance}</span></span>
        </Grid>
    )
}

const TableView = (props) => {
    const {list, total, page, handleChangePage} = props

    return (
        <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="transactions table" size='small'>
                <TableHead>
                    <TableRow style={styles.tableHeader}>
                        <TableCell style={styles.headerCell}>Date</TableCell>
                        <TableCell style={styles.headerCell}>Type</TableCell>
                        <TableCell style={styles.headerCell}>Description</TableCell>
                        <TableCell style={styles.headerCell}>Amount/<br/>Balance</TableCell>
                        <TableCell style={styles.headerCell}>Ref ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.length == 0 ? (
                        <TableRow>
                            <TableCell colSpan={12} style={{...styles.rowCell, textAlign: 'center'}}>No records found</TableCell>
                        </TableRow>
                    ) :
                    list.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell style={styles.rowCell}>{item.date}</TableCell>
                            <TableCell style={styles.rowCell}>{item.type}</TableCell>
                            <TableCell style={styles.rowCell}>{item.description}</TableCell>
                            <TableCell style={styles.rowCell}>{item.amount}<br/>{item.balance}</TableCell>
                            <TableCell style={styles.rowCell}>{item.refId}</TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
                <TablePagination
                colSpan={12}
                rowsPerPageOptions={[]}
                count={total}
                rowsPerPage={50}
                page={page}
                SelectProps={{
                    inputProps: {
                    'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                style={{borderWidth: 0}}
                onPageChange={handleChangePage}
                />
            </Table>
        </Grid>
    )
}

const Transactions = (props) => {
    const {list, balance, total, page, handleChangePage, handleFilterChange, handleDownloadInvoices, handleDownloadCSV} = props

    const [selectedType, setSelectedType] = useState('0')
    const [selectedDate, setSelectedDate] = useState('')
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [dateRange, setDateRange] = useState({ fromTimeStamp: '', toTimeStamp: '' });

    useEffect(() => {
        setDateRange({
            fromTimeStamp: startDateTime,
            toTimeStamp: endDateTime,
        });
    }, [startDateTime, endDateTime]);

    useEffect(() => {
        if ((dateRange.fromTimeStamp == '' && dateRange.toTimeStamp == '' && selectedDate == '') || dateRange.fromTimeStamp != '' && dateRange.toTimeStamp != '') {
            handleFilterChange(dateRange.fromTimeStamp, dateRange.toTimeStamp, selectedType)
        }
    }, [dateRange, selectedType]);

    const handleChangeDate = (e, key) => {
        key == 'startDate' ? setStartDateTime(e.target.value) : setEndDateTime(e.target.value);
    };

    const handleRangeSelection = (e) => {
        const id = e.target.value;
        setSelectedDate(id == 4 ? 'custom' : '');
        let startDate = null;
        let endDate = null;
        if (id == 1) {
            startDate = dayjs().startOf('day').format();
            endDate = dayjs().endOf('day').format();
        } else if (id == 2) {
            startDate = dayjs().startOf('week').format();
            endDate = dayjs().endOf('week').format();
        } else if (id == 3) {
            startDate = dayjs().startOf('month').format();
            endDate = dayjs().endOf('month').format();
        } else if (id == 4) {
            startDate = startDateTime;
            endDate = endDateTime;
        }
        setDateRange({
            fromTimeStamp: startDate,
            toTimeStamp: endDate,
        });
    };

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value)
    }

    return (
        <Grid container spacing={2}>
            <HeaderView balance={balance}/>

            <FiltersView 
            selectedDate={selectedDate} 
            handleRangeSelection={handleRangeSelection} 
            handleChangeDate={handleChangeDate}
            startDate={startDateTime}
            endDate={endDateTime}
            handleDownloadInvoices={handleDownloadInvoices}
            handleDownloadCSV={handleDownloadCSV}
            />

            <TypesView 
            selectedType={selectedType} 
            handleTypeChange={handleTypeChange}
            />

            <TableView 
            list={list}
            total={total}
            page={page}
            handleChangePage={handleChangePage}
            />
        </Grid>
    )
}
export default Transactions;
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Chip, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

const StatsItemView = (props) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const {key, mediumScreenRation, item, canShowDetails, isExpanded, onShowStatsDetails, onHideStatsDetails} = props
    const onTogglePress = () => {
        if (canShowDetails) {
            isExpanded ? onHideStatsDetails(item.type) : onShowStatsDetails(item.type)
        }
    }

    return (
        <Grid key={key} item xs={6} md={mediumScreenRation || 4}>
            <div onClick={onTogglePress}>
            <Grid container spacing="5px">
                <Grid item>
                    {!isSmallScreen && (
                        <Box className="image-icon">
                            <img src={item.image} alt="" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '2px',
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '30px',
                                    color: '#344054',
                                    lineHeight: '48px',
                                    textEdge: 'cap',
                                }}
                            >
                                {item.count}
                            </Typography>
                            <Chip
                                label={item.chipPercent}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '8px',
                                    color: '#ffffff',
                                    width: '43px',
                                    height: '18px',
                                    background: item.increment ? '#12B76A' : '#667085',
                                }}
                            />
                            {item.increment ? (
                                <ArrowUpwardIcon style={{ color: '#12B76A' }} />
                            ) : (
                                <ArrowDownwardIcon style={{ color: '#667085' }} />
                            )}
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '13px',
                                color: '#344054',
                                lineHeight: '19px',
                                padding: '3px',
                                maxWidth: '100px',
                                wordWrap: 'break-word',
                            }}
                        >
                            {item.bodyText}
                        </Typography>
                    </Box>
                </Grid>
                {canShowDetails && (
                    <Grid item style={{marginLeft: '8px', marginTop: '8px'}}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Grid>
                )}
            </Grid>
            </div>
        </Grid>
    )
}

const ColorLegend = ({options}) => {
    return (
        <Grid container direction="row" spacing={1}>
            {options.map((entry, index) => (
                <Grid item xs={6} key={`legend-${index}`}>
                    <Grid container alignItems="center" gap={'8px'}>
                        <Grid item>
                            <Box style={{width: 30, height: 2, backgroundColor: entry.color}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{entry.title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

const StatsDetailInsights = (props) => {
    const {list1, list2, title} = props
    const dayToTitle = (day) => {
        const tokens = day.split('T')[0].split('-')
        return `${tokens[1]}/${tokens[2]}`
    }

    var dataMap = new Map(list1.data.map(item => (
        [item.day, {
            title: dayToTitle(item.day),
            count1: Number(item.count),
            count2: 0
        }]
    )))
    var options = [{
        color: '#7F56D9',
        title: list1.title,
        key: 'count1',
    }]

    if (list2 != undefined) {
        list2.data.forEach((item) => {
            var obj = dataMap.get(item.day) || {
                title: dayToTitle(item.day),
                count1: 0,
                count2: 0
            }
            obj.count2 = Number(item.count)
            dataMap.set(item.day, obj)
        })

        options.push({
            color: '#2E90FA',
            title: list2.title,
            key: 'count2',
        })
    }
    const data = Array.from(dataMap.values())

    return (
        <Grid item xs={12}>
            <Grid container direction="column" gap="20px" sx={{ p: '20px', borderRadius: '20px' }}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item flexGrow={1}>
                            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>{title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ColorLegend options={options}/>
                </Grid>
                <Grid item>
                    <ResponsiveContainer width="100%" height={370}>
                        <LineChart data={data} key={`chart`}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="title" padding={{ left: 30, right: 30 }} />
                            <YAxis />
                            {options.map(option => (
                                <Line type="monotone" dataKey={option.key} stroke={option.color}/>
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

const StatCard = (props) => {
    const {statCardHeading, statsData, mediumScreenRation, onShowStatsDetails, onHideStatsDetails, canShowDetails, statDetails} = props
    
    return (
        <Grid container sx={{ backgroundColor: '#FFFFFF', borderRadius: '20px', p: 2 }}>
            {statCardHeading === true && (
                <Grid item xs={12} lg={12}>
                    <Typography variant="body1" sx={{ color: '#1D2939', fontWeight: 600, fontSize: '17px', pb: 2 }}>
                        This month
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {statsData?.map((item, index) => (
                        <StatsItemView 
                        key={index.toString()} 
                        mediumScreenRation={mediumScreenRation} 
                        item={item}
                        onShowStatsDetails={onShowStatsDetails}
                        onHideStatsDetails={onHideStatsDetails}
                        canShowDetails={canShowDetails && item.type != 'attendees' && item.type != 'engagement'}
                        isExpanded={statDetails != undefined && statDetails.type == item.type}
                        />
                    ))}
                </Grid>
            </Grid>

            {statDetails != undefined && (
                <StatsDetailInsights 
                list1={statDetails.list1} 
                list2={statDetails.list2} 
                title={statDetails.title}/>
            )}
        </Grid>
    );
};

export default StatCard;

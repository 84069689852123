import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function getStats(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/stats/get`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getStatsClaimed(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/stats/getClaimed`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getStatsDrinks(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/stats/getDrinks`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getStatsActive(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/stats/getActive`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

export function getStatsUpcoming(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/stats/getUpcoming`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}
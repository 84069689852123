import { createSlice } from '@reduxjs/toolkit';

const PackageSlice = createSlice({
    name: 'packages',
    initialState: {
        upComingPackages: [],
        rangeActivePackages: [],
        rangePastPackages: [],
        rangeUpComingPackages: [],
        pastPackages: [],
        packageTypes: [],
        isApiDone: false,
        packageToEdit: null,
    },
    reducers: {
        getUpComingPackages(state, action) {},
        setUpComingPackages(state, action) {
            state.upComingPackages = action.payload;
        },
        getPackageTypes(state, action) {},
        setPackageTypes(state, action) {
            state.packageTypes = action.payload;
        },
        createNewPackage(state, action) {},
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
        getPackageDetail(state, action) {},
        setPackageToEdit(state, action) {
            state.packageToEdit = action.payload;
        },
        updatePackage(state, action) {},
        endPackage(state, action) {},
        getPackagesByRange(state, action) {},
        setRangeActivePackages(state, action) {
            state.rangeActivePackages = action.payload;
        },
        setRangePastPackages(state, action) {
            state.rangePastPackages = action.payload;
        },
        setRangeUpComingPackages(state, action) {
            state.rangeUpComingPackages = action.payload;
        },
    },
});

export const {
    getUpComingPackages,
    setUpComingPackages,
    getPackageTypes,
    setPackageTypes,
    createNewPackage,
    setIsDone,
    getPackageDetail,
    setPackageToEdit,
    updatePackage,
    endPackage,
    getPackagesByRange,
    setRangeActivePackages,
    setRangePastPackages,
    setRangeUpComingPackages,
} = PackageSlice.actions;

export default PackageSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useMediaQuery, Grid, Typography, Checkbox } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import IconToggleButtonGroup from '../General/IconToggleButtonGroup';
import ACTIVE_ENGAGEMENT from '../../assets/images/ActiveEngagement.png';
import GRAPH_ICON from '../../assets/images/GraphIcon.png';

const defaultOptions = [{
    color: '#7F56D9',
    title: 'Total Attendees',
    key: 'total',
    isChecked: true
}, {
    color: '#12B76A',
    title: 'First-time Attendees',
    key: 'first_time_user',
    isChecked: true
}, {
    color: '#7F56D9',
    title: 'Referred by Friends',
    key: 'referred',
    isChecked: true
}, {
    color: '#F04438',
    title: 'Returning Attendees',
    key: 'returning_user',
    isChecked: true
}]

const ColorLegend = ({options, onChange}) => {
    return (
        <Grid container direction="row" spacing={1}>
            {options.map((entry, index) => (
                <Grid item xs={6} key={`legend-${index}`}>
                    <Grid container alignItems="center" gap={'8px'}>
                        <Grid item>
                            <Checkbox
                                checked={entry.isChecked}
                                onChange={(event) => onChange(entry, event.target.checked)}
                                sx={{
                                    '&.Mui-checked': {
                                        color: entry.color,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{entry.title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

const VisitorInsights = ({data}) => {
    const [alignment, setAlignment] = useState('left');

    const matchesOnSmallDevice = useMediaQuery('(max-width:600px)');
    const matchesOnMediumDevice = useMediaQuery('(min-width:1023px)');

    const chartSmallWidth = matchesOnSmallDevice ? 320 : 500;
    const chartWidth = matchesOnMediumDevice ? 600 : chartSmallWidth;

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const icons = [
        { value: 'left', label: 'Align Left', component: <img src={ACTIVE_ENGAGEMENT} alt="circle" /> },
        { value: 'right', label: 'Align Right', component: <img src={GRAPH_ICON} alt="circle" /> },
    ];
    
    const [options, setOptions] = useState(defaultOptions)
    const handleOptionChange = (option, newValue) => {
        const idx = options.findIndex(item => item.title == option.title)
        
        if (idx != -1) {
            const newOptions = [...options]
            newOptions[idx].isChecked = newValue
            setOptions(newOptions)
        }
    }

    const [chartKey, setChartKey] = useState(1)
    useEffect(() => {
        setChartKey(chartKey + 1)
    }, [options])

    return (
        <Grid container direction="column" gap="20px" sx={{ background: 'white', p: '20px', borderRadius: '20px' }}>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item flexGrow={1}>
                        <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Visitor Insights</Typography>
                    </Grid>
                    <Grid item>
                        <IconToggleButtonGroup icons={icons} value={alignment} onChange={handleAlignment} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ColorLegend options={options} onChange={handleOptionChange}/>
            </Grid>
            <Grid item>
                <ResponsiveContainer width="100%" height={370}>
                    <LineChart data={data} key={`${chartKey}_key`}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="title" padding={{ left: 30, right: 30 }} />
                        <YAxis />
                        {options.filter(option => option.isChecked).map(option => (
                            <Line type="monotone" dataKey={option.key} stroke={option.color}/>
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default VisitorInsights;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Box } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HOME_ICON from '../../assets/images/HomeIcon.svg';
import CREATE_ICON from '../../assets/images/addIcon.svg';
import MANAGE_ICON from '../../assets/images/settingIcon.svg';
import ANALYZE_ICON from '../../assets/images/analyzeIcon.svg';
import MESSAGE_ICON from '../../assets/images/messageIcon.svg';

export default function SecondaryBottomAppBar() {
    const [value, setValue] = useState(0);

    const handleNavigate = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%', overflowX: 'auto', position: 'fixed', bottom: 0, background: 'white' }}>
            <BottomNavigation showLabels value={value} onChange={handleNavigate}>
                <BottomNavigationAction
                    p={0}
                    component={NavLink}
                    to="/home"
                    label="Home"
                    icon={<img src={HOME_ICON} alt="home" />}
                />
                <BottomNavigationAction
                    p={0}
                    component={NavLink}
                    to="/create"
                    label="Create"
                    icon={<img src={CREATE_ICON} alt="Create" />}
                />
                <BottomNavigationAction
                    p={0}
                    component={NavLink}
                    to="/manage"
                    label="Manage"
                    icon={<img src={MANAGE_ICON} alt="Manage" />}
                />
                <BottomNavigationAction
                    p={0}
                    component={NavLink}
                    to="/analyze"
                    label="Analyze"
                    icon={<img src={ANALYZE_ICON} alt="Analyze" />}
                />
                <BottomNavigationAction
                    p={0}
                    component={NavLink}
                    to="/messages"
                    label="Messages"
                    icon={<img src={MESSAGE_ICON} alt="Messages" />}
                />
            </BottomNavigation>
        </Box>
    );
}

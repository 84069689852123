import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid, Button, Typography, TextField, Select, MenuItem, Divider, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from '../../components/PromotionPackageButton/CustomButton';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile, updateProfile } from '../../store/slices/user.slice';
import { isEmpty } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import { uploadImage } from '../../store/sagas/requests/image.request';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import { APP_CONSTANTS } from '../../config/config';

const defaultRepFormInput = {
    name: '',
    email: '',
    phone: '',
};

const defaultFormInput = {
    name: '',
    imageUrl: '',
    street: '',
    addressState: '',
    city: '',
    zipCode: '',
    phone: '',
    address: '',
    reps: [],
};

const formatPhoneNumber = (text, previousText) => {
    if (!text) return text
  
    const deleting = previousText && previousText.length > text.length
    if (deleting) {
      return text
    }
  
    let cleaned = text.replace(/\D/g, '') // remove non-digit characters
    let match = null
  
    if (cleaned.length > 0 && cleaned.length < 2) {
      return `(${cleaned}`
    } else if (cleaned.length == 3) {
      return `(${cleaned}) `
    } else if (cleaned.length > 3 && cleaned.length < 5) {
      match = cleaned.match(/(\d{3})(\d{1,3})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}`
      }
    } else if (cleaned.length == 6) {
      match = cleaned.match(/(\d{3})(\d{3})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}-`
      }
    } else if (cleaned.length > 6) {
      match = cleaned.match(/(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
      }
    }
  
    return text
}

const ManageAccount = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const [formInput, setFormInput] = useState(defaultFormInput);
    const [reps, setReps] = useState([defaultRepFormInput]);
    const [selectedImage, setSelectedImage] = useState(null)

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const dispatchSnackBar = useCallback((type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    }, []);

    const handleInputChange = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };
    const handleAddRep = (e) => {
        setReps([...reps, defaultRepFormInput]);
    };

    const handleRemoveRep = (index) => {
        const updatedReps = [...reps];
        updatedReps.splice(index, 1);
        setReps(updatedReps);
    };

    const handleRepInputChange = (name, value, index) => {
        const updatedReps = [...reps];
        updatedReps[index] = {
            ...updatedReps[index],
            [name]: value,
        };
        setReps(updatedReps);
    };

    useEffect(() => {
        if (!user || isEmpty(user)) {
            dispatch(getProfile());
        }
    }, [user]);

    const { APP_BASE_URL } = APP_CONSTANTS;

    useEffect(() => {
        if (user) {
            console.log(user)
            setFormInput({
                name: user.name,
                imageUrl: user.image_url,
                street: user.street,
                addressState: user.addressstate,
                city: user.city,
                zipCode: user.zipcode,
                phone: formatPhoneNumber(user.phone),
                reps: user.reps?.map(rep => ({...rep, phone: formatPhoneNumber(rep.phone)})) || [],
                address: ''
            });

            if (user.reps && user.reps.length > 0) {
                setReps(user.reps);
            }
        }
    }, [user]);

    const handleImageUpload = async (file) => {
        try {
            const formData = new FormData();
            formData.append('image', file);
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const response = await uploadImage({ accessToken: accessToken, form: formData });

                const { data } = response;
                if (data.status) {
                    // setFormInput({ ...formInput, coverImageUrl: data.imageUrl });
                    return data.imageUrl;
                } else {
                    console.log('🚀 ~ file: ManageAccount.js ~ handleImageUpload error', response);
                    dispatchSnackBar('error', 'Error uploading image, please try again', true);
                    return null;
                }
            }
        } catch (error) {
            dispatchSnackBar('error', 'Error uploading image, please try again', true);
            console.log('🚀 ~ file: ManageAccount.js ~ handleImageUpload exception', error);
        }
    };

    const handleAccountUpdate = async () => {

        let form = formInput;
        form.reps = reps;

        if (selectedImage) {
            const uploadedImageUrl = await handleImageUpload(selectedImage);
            if (!uploadedImageUrl) {
                return false;
            } else {
                form.imageUrl = uploadedImageUrl
            }
        }

        dispatch(updateProfile(form));
    };

    const handleImageSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedImage(file)
        }
    }

    return (
        <Container maxWidth="md">
            <Grid container sx={{ my: 1, background: 'white', borderRadius: '16px' }} p={'20px'} gap="20px">
                <Grid item xs={12}>
                    <Typography fontSize="17px" fontWeight="bold" sx={{ fontSize: '17px', fontWeight: 600 }}>
                        Business info
                    </Typography>
                </Grid>

                <Grid item xs={12} flexDirection={'column'} alignItems={'center'} display={'flex'}>
                    <label htmlFor='profile_image'>
                        <div style={{position: 'relative', textAlign: 'center'}}>
                            <img style={{width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover', backgroundColor: '#fcfcfc'}} 
                            src={selectedImage != null ? URL.createObjectURL(selectedImage) : APP_BASE_URL + formInput.imageUrl}
                            onClick={() => document.getElementById('imageInput').click()}
                            />
                            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height:'100%', backgroundColor: 'rgba(0,0,0, 0.2)', borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <EditIcon style={{color: '#5B2A9E', transform: 'scale(2.8)', margin: '20px'}}/>
                            </div>
                        </div>
                    </label>
                    <input type='file' id='profile_image' accept='image/*' style={{display: 'none'}} onChange={handleImageSelected}/>
                </Grid>

                <Grid item xs={12}>
                    <Typography pb={1} variant="body1">
                        Business Name
                    </Typography>
                    <TextField
                        sx={{ background: '#F9FAFB' }}
                        id="Business Name"
                        name="name"
                        type="text"
                        placeholder="Business Name"
                        fullWidth
                        value={formInput.name}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography pb={1} variant="body1">
                        Street Address
                    </Typography>
                    <TextField
                        sx={{ background: '#F9FAFB' }}
                        name="street"
                        type="text"
                        placeholder="Type your address"
                        fullWidth
                        value={formInput.street}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={'20px'}>
                        <Grid item xs={4}>
                            <Typography pb={1} variant="body1">
                                State
                            </Typography>
                            <TextField
                                sx={{ background: '#F9FAFB' }}
                                name="addressState"
                                type="text"
                                placeholder="Type your state"
                                fullWidth
                                value={formInput.addressState}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography pb={1} variant="body1">
                                City
                            </Typography>
                            <TextField
                                sx={{ background: '#F9FAFB' }}
                                name="city"
                                type="text"
                                placeholder="Type your city"
                                fullWidth
                                value={formInput.city}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography pb={1} variant="body1">
                                Zip Code
                            </Typography>
                            <TextField
                                sx={{ background: '#F9FAFB' }}
                                name="zipCode"
                                type="text"
                                placeholder="Type your address"
                                fullWidth
                                value={formInput.zipCode}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography pb={1} variant="body1">
                        Phone Number
                    </Typography>
                    <TextField
                        sx={{ background: '#F9FAFB' }}
                        name="phone"
                        type="text"
                        placeholder="(000) 000-0000"
                        fullWidth
                        value={formInput.phone}
                        onChange={(e) => handleInputChange(e.target.name, formatPhoneNumber(e.target.value, formInput.phone))}
                    />
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>

                {reps?.map((rep, index) => {
                    return (
                        <>
                            <Grid item xs={12} mt={2}>
                                <Typography pb={1} sx={{ fontSize: '17px', fontWeight: 600 }}>
                                    Representative #{index + 1}
                                    {index > 0 && (
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            sx={{ marginLeft: 'auto', color: '#7F56D9' }}
                                            onClick={() => handleRemoveRep(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography pb={1} variant="body1">
                                    Full Name
                                </Typography>
                                <TextField
                                    sx={{ background: '#F9FAFB' }}
                                    name="name"
                                    type="text"
                                    placeholder="Business Name"
                                    fullWidth
                                    value={rep.name}
                                    onChange={(e) => handleRepInputChange(e.target.name, e.target.value, index)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography pb={1} variant="body1">
                                    Phone Number
                                </Typography>
                                <TextField
                                    sx={{ background: '#F9FAFB' }}
                                    name="phone"
                                    type="text"
                                    placeholder="(000) 000-0000"
                                    fullWidth
                                    value={rep.phone}
                                    onChange={(e) => handleRepInputChange(e.target.name, formatPhoneNumber(e.target.value, rep.phone), index)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography pb={1} variant="body1">
                                    Email
                                </Typography>
                                <TextField
                                    sx={{ background: '#F9FAFB' }}
                                    name="email"
                                    placeholder="example@example.com"
                                    fullWidth
                                    value={rep.email}
                                    onChange={(e) => handleRepInputChange(e.target.name, e.target.value, index)}
                                />
                            </Grid>
                        </>
                    );
                })}

                <Grid item xs={12} textAlign="right">
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: '#7F56D9',
                            background: '#F9F5FF',
                            borderRadius: '8px',
                            p: '8px 16px',
                            '&:hover': {
                                background: '#98A2B3',
                                color: '#ffffff !important',
                            },
                        }}
                        onClick={handleAddRep}
                        startIcon={<AddCircleIcon sx={{ color: '#B692F6' }} />}
                    >
                        Add more representative
                    </Button>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        buttonTitle={'Save'}
                        buttonOnClick={handleAccountUpdate}
                        buttonDisabled={false}
                        icon={<CheckBoxRoundedIcon />}
                        cancelButtonTitle={'Cancel'}
                        navigationRoute={'/home'}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default ManageAccount;

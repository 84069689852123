import { call, put } from 'redux-saga/effects';
import {
    getUpComingPackagessRequest,
    getPackageTypesRequest,
    createPackageRequest,
    getPackageDetailRequest,
    updatePackageRequest,
    endPackageRequest,
    getPackagesByRangeRequest,
} from '../requests/package.request';
import {
    setUpComingPackages,
    setPackageTypes,
    setIsDone,
    setPackageToEdit,
    setRangeActivePackages,
    setRangePastPackages,
    setRangeUpComingPackages,
} from '../../slices/package.slice';
import { setShowLoading } from '../../slices/global.slice';
import { showSnackBar } from '../../slices/snackbar.slice';

export function* handleGetUpComingPackage(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getUpComingPackagessRequest, { accessToken });
        console.log('🚀 ~ file: package.handler.js ~ line 10 ~ handleGetUpComingPackage ~ response', response);
        const { data } = response;

        const packagesList = data.list;
        if (packagesList) {
            yield put(setUpComingPackages(packagesList));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetPackageTypes(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPackageTypesRequest, { accessToken });
        console.log('🚀 ~ file: package.handler.js ~ line 26 ~ handleGetPackageTypes ~ response', response);

        const { data } = response;
        const packageTypes = data.types;
        packageTypes.sort((a, b) => {
            const title1 = a.title.toUpperCase();
            const title2 = b.title.toUpperCase();
            if (title1 < title2) {
                return -1;
            }
            if (title1 > title2) {
                return 1;
            }
            return 0;
        })
        if (packageTypes) {
            yield put(setPackageTypes(packageTypes));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleCreatePackage(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(createPackageRequest, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: package.handler.js ~ line 42 ~ handleCreatePackage ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Package Created Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetPackageDetail(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPackageDetailRequest, { accessToken, id: action.payload });
        console.log('🚀 ~ file: package.handler.js ~ line 85 ~ handleGetPackageDetail ~ response', response);

        const { data } = response;

        const packageObj = data.package;
        if (packageObj) {
            yield put(setPackageToEdit(packageObj));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleUpdatePackage(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(updatePackageRequest, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: package.handler.js ~ line 105 ~ handleUpdatePackage ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Package Updated Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleEndPackage(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(endPackageRequest, { accessToken, id: action.payload });
        console.log('🚀 ~ file: package.handler.js ~ line 140 ~ handleEndPackage ~ response', response);

        const { data } = response;
        if (data.status === 1) {
            yield put(setIsDone({ isApiDone: true }));
            const snackbarObject = {
                type: 'success',
                message: 'Package Ended Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetPackagesByRange(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getPackagesByRangeRequest, { accessToken, options: action.payload });

        const { data } = response;
        console.log('package range data', data);

        if (data?.upcoming || data?.active || data?.past) {
            yield put(setRangeUpComingPackages(data?.upcoming));
            yield put(setRangeActivePackages(data?.active));
            yield put(setRangePastPackages(data?.past));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch } from 'react-redux';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import { passwordReset } from '../../store/slices/user.slice';
import { useNavigate } from 'react-router-dom';
import SPILOGO from '../../assets/images/SpiLogo.svg';

const classes = {
    TextField: {},
};

const defaultFormInput = {
    newPassword: '',
    confirmNewPassword: '',
};

const SetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();

    const userToken = searchParams.get('token');

    const [formInput, setFormInput] = useState(defaultFormInput);

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const handleInputChange = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    // SnackBar
    const dispatchSnackBar = (type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    };

    const handleResetPsw = (e) => {
        e.stopPropagation();
        const resetPswDataObject = {
            newPassword: formInput.newPassword,
            confirmNewPassword: formInput.confirmNewPassword,
        };

        if (resetPswDataObject.newPassword !== resetPswDataObject.confirmNewPassword) {
            dispatchSnackBar('error', 'Password does Not match', true);
        } else if (resetPswDataObject.newPassword.length <= 8 && resetPswDataObject.confirmNewPassword.length <= 8) {
            dispatchSnackBar('error', 'Password must be 8 character long', true);
        } else {
            dispatch(passwordReset({ password: resetPswDataObject.newPassword, userToken }));
            dispatchSnackBar('success', 'Password reset successfully', true);
            navigate('/login');
        }
    };

    return (
        <>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="h3" variant="h5" align="center">
                                Reset Your Password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.newPassword}
                                fullWidth
                                name="password"
                                type="password"
                                id="password"
                                placeholder="New Password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.confirmNewPassword}
                                fullWidth
                                name="confirmPassword"
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm New Password"
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} pt={2}>
                            <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={(e) => handleResetPsw(e)}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SetPassword;

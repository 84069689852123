import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import { registerUser } from '../../store/slices/user.slice';
import { useNavigate } from 'react-router-dom';
import SPILOGO from '../../assets/images/SpiLogo.svg';

const defaultFormInput = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    street: '',
    addressState: '',
    city: '',
    zipCode: '',
    imageUrl: '',
};

const RegisterPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isApiDone, id } = useSelector((state) => state.user);

    const [formInput, setFormInput] = useState(defaultFormInput);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const handleInputChange = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    // SnackBar
    const dispatchSnackBar = (type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    };

    useEffect(() => {
        if (isApiDone === true) {
            setIsEmailSent(true);
        }
    }, [dispatchSnackBar, id, isApiDone, navigate]);

    const handleRegister = (e) => {
        e.stopPropagation();
        const registerDataObject = {
            ...formInput
        };

        console.log('registerDataObject', registerDataObject);
        if (registerDataObject.password !== registerDataObject.confirmPassword) {
            dispatchSnackBar('error', 'Password does Not match', true);
        } else if (registerDataObject.password.length < 8 && registerDataObject.confirmPassword.length < 8) {
            dispatchSnackBar('error', 'Password must be 8 character long', true);
        } else {
            dispatch(registerUser(registerDataObject));
        }
    };

    const cantSave = () => {
        return !formInput.name || !formInput.street || !formInput.addressState || !formInput.city || !formInput.zipCode || !formInput.email || !formInput.password || !formInput.confirmPassword
    }

    if (isEmailSent) {
        return (
          <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
              <CssBaseline />
              <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                  <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        pb={3}
                      >
                          <img src={SPILOGO} alt="SPI" className="AppLogo" />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        mb={2}
                      >
                          <Typography component="h3" variant="h5" align="center">
                              We have sent you a confirmation email
                          </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        mb={2}
                      >
                          <Typography component="p" variant="body2" align="center">
                              Please check your email to verify your account.
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
        )
    }

    return (
        <>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="h3" variant="h5" align="center">
                                Create an account
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.name}
                                fullWidth
                                id="name"
                                placeholder="Name"
                                name="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.email}
                                fullWidth
                                id="email"
                                placeholder="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.newPsw}
                                fullWidth
                                name="password"
                                type="password"
                                id="password"
                                placeholder="Password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.confirmNewPsw}
                                fullWidth
                                name="confirmPassword"
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm Password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.street}
                                fullWidth
                                name="street"
                                type="text"
                                id="street"
                                placeholder="Street Address"
                            />
                        </Grid>
                        <Grid container xs={12} style={{flexDirection: 'row', display: 'flex'}} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{
                                        mb: 2,
                                    }}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    value={formInput.addressState}
                                    fullWidth
                                    name="addressState"
                                    type="text"
                                    id="addressState"
                                    placeholder="State"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    sx={{
                                        mb: 2,
                                    }}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    value={formInput.city}
                                    fullWidth
                                    name="city"
                                    type="text"
                                    id="city"
                                    placeholder="City"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    sx={{
                                        mb: 2,
                                    }}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    value={formInput.zipCode}
                                    fullWidth
                                    name="zipCode"
                                    type="text"
                                    id="zipCode"
                                    placeholder="Zip Code"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent={'center'} pt={2}>
                            <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={(e) => handleRegister(e)}
                                disabled={cantSave()}
                            >
                                Register
                            </Button>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} alignItems="center">
                            <Typography>Already have an account?</Typography>
                            <Button type="button" className="button-transparent">
                                <Link style={{ textDecoration: 'underline', color: 'blue' }} to="/login">
                                    Login
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RegisterPage;

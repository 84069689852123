import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Divider,
} from '@mui/material';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { uploadImage } from '../../store/sagas/requests/image.request.js';
import {
    createNewPromotion,
    getPromotionDetail,
    getPromotionTypes,
    updatePromotion,
} from '../../store/slices/promotion.slice';
import { showSnackBar } from '../../store/slices/snackbar.slice';

import { isEmpty, isUndefined } from 'lodash';
import Frequency from '../../components/Frequency/Frequency';
import CustomButton from '../../components/PromotionPackageButton/CustomButton';
import { APP_CONSTANTS } from '../../config/config';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import PromoPreview from './PromoPreview.jsx';
import USER_AVATAR_1 from '../../assets/images/user_avatar_1.png';
import USER_AVATAR_2 from '../../assets/images/user_avatar_2.png';

dayjs.extend(customParseFormat);

const defaultFormInput = {
    title: '',
    coverImageUrl: '',
    description: '',
    startTimeStamp: '',
    endTimeStamp: '',
    ageRestriction: '',
    claims: '',
    promotionTypeId: '',
    promotionCustomType: '',
    paidToBoost: '',
    frequency: '',
    about: '',
    rules: '',
};
const defaultSchedule = [
    {
        code: 'DD',
        title: 'Every day',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'DW',
        title: 'Working days',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'DS',
        title: 'Weekends',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D0',
        title: 'MON',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D1',
        title: 'TUE',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D2',
        title: 'WED',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D3',
        title: 'THU',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
    {
        code: 'D4',
        title: 'FRI',
        isSelected: false,
        startTime: '',
        endTime: '',
    },
];

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);
const parseScheduleDays = (schedule, useFullDays) => {
    if (isUndefinedOrEmpty(schedule)) {
      return undefined
    }
    const codes = schedule.split(',').map(sc => sc.split('=')[0])
    if (codes.length == 0) {
      return undefined
    }
    if (codes[0] == 'DD') return 'Every day'
    if (codes[0] == 'DW') return 'Weekdays'
    if (codes[0] == 'DS') return 'Weekends'
    const days = useFullDays ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] : ['Mo', 'Tu', 'We', 'Th', 'Fr']
    return codes.map(code => days[parseInt(code.charAt(1))]).join(', ')
}

const parseScheduleTime = (schedule) => {
    if (isUndefinedOrEmpty(schedule)) {
      return 'Varies'
    }
    var scheduleTime = undefined
    const days = schedule.split(',')
    for (const day of days) {
      const tokens = day.split('=')
      if (tokens.length > 2) {
        const time = tokens[1] + ' - ' + tokens[2]
        if (scheduleTime == undefined) {
          scheduleTime = time
        } else if (scheduleTime != time) {
          scheduleTime = 'Varies'
          break
        }
      }
    }
    return scheduleTime || 'Varies'
}

const CreatePromotion = () => {
    dayjs.extend(utcPlugin);
    const { id } = useParams();
    console.log('promotion id', id);
    // const isSmallScreen = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isReviewing, setIsReviewing] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [formInput, setFormInput] = useState(defaultFormInput);
    const [isEditing, setIsEditing] = useState(false);
    const promotions = useSelector((state) => state.promotions);
    const fileInputRef = useRef();
    const [schedule, setSchedule] = useState(defaultSchedule);

    const { APP_BASE_URL } = APP_CONSTANTS;

    const { promotionTypes, isApiDone, promotionToEdit } = promotions;
    var allPromotionTypes = [...promotionTypes, {id: 'custom', title: 'Custom Type'}]
    allPromotionTypes.sort((a, b) => {
        const title1 = a.title.toUpperCase();
        const title2 = b.title.toUpperCase();
        if (title1 < title2) {
            return -1;
        }
        if (title1 > title2) {
            return 1;
        }
        return 0;
    })

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const dispatchSnackBar = useCallback((type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getPromotionDetail(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (promotionToEdit) {
            setIsEditing(true);
            setFormInput({
                title: promotionToEdit.promotion_title,
                coverImageUrl: promotionToEdit.cover_image_url,
                description: promotionToEdit.description,
                ageRestriction: promotionToEdit?.age_restriction?.toString(),
                claims: promotionToEdit?.claims?.toString(),
                promotionTypeId: promotionToEdit.promotion_type_id,
                promotionCustomType: '',
                paidToBoost: promotionToEdit?.paid_to_boost?.toString(),
                frequency: promotionToEdit.frequency,
                about: promotionToEdit.about,
                rules: promotionToEdit.rules,
            });
            setStartDate(dayjs.utc(promotionToEdit.start_timestamp).format('YYYY-MM-DD'));
            if (promotionToEdit.end_timestamp) {
                setEndDate(dayjs.utc(promotionToEdit.end_timestamp).format('YYYY-MM-DD'));
            }
            if (promotionToEdit.frequency) {
            }
            setSchedule(decodeSchedule(promotionToEdit.schedule));
        }
    }, [promotionToEdit]);

    const handleInputChange = (name, value) => {
        if (name === 'startTimeStamp') {
            value = value + ':00.000';
        }
        if (name === 'claims') {
            const newValue = parseInt(value);
            if (isNaN(newValue) || newValue < 1) {
                value = '';
            }
        }
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    const handleImageUpload = async (file) => {
        try {
            const formData = new FormData();
            formData.append('image', file);
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const response = await uploadImage({ accessToken: accessToken, form: formData });

                const { data } = response;
                if (data.status) {
                    // setFormInput({ ...formInput, coverImageUrl: data.imageUrl });
                    return data.imageUrl;
                } else {
                    console.log('🚀 ~ file: createPromotion.js ~ handleImageUpload error', response);
                    dispatchSnackBar('error', 'Error uploading image, please try again', true);
                    return null;
                }
            }
        } catch (error) {
            dispatchSnackBar('error', 'Error uploading image, please try again', true);
            console.log('🚀 ~ file: createPromotion.js ~ handleImageUpload exception', error);
        }
    };

    const handleFileInputChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setCoverPhoto(e.target.files[0]);
        }
    };
    const handleDrop = (event) => {
        event.preventDefault();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            setCoverPhoto(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
    };

    const handleReview = (value) => {
        setIsReviewing(value);
    };

    useEffect(() => {
        dispatch(getPromotionTypes());
    }, []);

    useEffect(() => {
        if (isApiDone) {
            navigate('/manage');
        }
    }, [isApiDone]);

    const handleCreatePromotion = async (e) => {
        e.stopPropagation();

        const uploadedImageUrl = await handleImageUpload(coverPhoto);
        if (!uploadedImageUrl) {
            return false;
        }
        let form = { ...formInput };
        form.coverImageUrl = uploadedImageUrl;

        form.startTimeStamp = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
        if (endDate) {
            form.endTimeStamp = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
        }

        if (form.promotionTypeId === 'custom') {
            delete form.promotionTypeId;
        } else {
            delete form.promotionCustomType;
        }

        ['ageRestriction', 'paidToBoost', 'endTimeStamp', 'frequency', 'claims'].map((key) => {
            if (isEmpty(form[key]) || isUndefined(form[key])) {
                delete form[key];
            }
        });
        form['schedule'] = encodedSchedule();

        dispatch(createNewPromotion(form));
    };

    const handleUpdatePromotion = async (e) => {
        e.stopPropagation();
        const updatedFormInput = { ...formInput }; // Create a new object
        if (coverPhoto) {
            const uploadedImageUrl = await handleImageUpload(coverPhoto);
            if (!uploadedImageUrl) {
                return false;
            } else {
                updatedFormInput.coverImageUrl = uploadedImageUrl;
            }
        }

        updatedFormInput.startTimeStamp = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
        if (endDate) {
            updatedFormInput.endTimeStamp = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
        }

        if (!isEmpty(updatedFormInput.promotionTypeId) && updatedFormInput.promotionTypeId === 'custom') {
            delete updatedFormInput.promotionTypeId;
        } else {
            delete updatedFormInput.promotionCustomType;
        }

        ['ageRestriction', 'paidToBoost', 'endTimeStamp', 'frequency', 'claims'].forEach((key) => {
            if (isEmpty(updatedFormInput[key]) || isUndefined(updatedFormInput[key])) {
                delete updatedFormInput[key];
            }
        });

        updatedFormInput['schedule'] = encodedSchedule();
        const promotionToUpdate = { ...updatedFormInput, promotionId: id };

        dispatch(updatePromotion(promotionToUpdate));
    };

    const setScheduleItemSelected = (item, isSelected) => {
        if (isSelected) {
            const isGroup = (item) => item.code == 'DD' || item.code == 'DW' || item.code == 'DS';
            if (isGroup(item)) {
                schedule.forEach((sc) => (sc.isSelected = false));
            } else {
                schedule.forEach((sc) => {
                    if (isGroup(sc)) sc.isSelected = false;
                });
            }
        }
        item.isSelected = isSelected;

        const idx = schedule.findIndex((sc) => sc.code == item.code);
        if (idx != -1) {
            schedule[idx] = item;
        }
        setSchedule([...schedule]);
    };

    const setScheduleItemStart = (item, startTime) => {
        item.startTime = startTime;
        updateScheduleItem(item);
    };

    const setScheduleItemEnd = (item, endTime) => {
        item.endTime = endTime;
        updateScheduleItem(item);
    };

    const updateScheduleItem = (item) => {
        const idx = schedule.findIndex((sc) => sc.code == item.code);
        if (idx != -1) {
            schedule[idx] = item;
        }
        setSchedule([...schedule]);
    };

    const encodedSchedule = () => {
        const formatted = (time) => dayjs(time, 'HH:mm').format('hh:mm a');
        return schedule
            .filter((sc) => sc.isSelected && sc.startTime != undefined && sc.endTime != undefined)
            .map((sc) => `${sc.code}=${formatted(sc.startTime)}=${formatted(sc.endTime)}`)
            .join(',');
    };

    const decodeSchedule = (encoded) => {
        var schedule = [...defaultSchedule];
        if (isUndefinedOrEmpty(encoded)) {
            return schedule;
        }
        encoded.split(',').forEach((str) => {
            const tokens = str.split('=');
            const idx = schedule.findIndex((sc) => sc.code == tokens[0]);
            if (idx != -1 && tokens.length > 2) {
                var item = schedule[idx];
                item.isSelected = true;
                item.startTime = dayjs(tokens[1], 'hh:mm a').format('HH:mm');
                item.endTime = dayjs(tokens[2], 'hh:mm a').format('HH:mm');
                schedule[idx] = item;
            }
        });
        return schedule;
    };

    const isReviewAllowed = () => {
        const selectedSchedule = schedule.findIndex(
            (sc) => sc.isSelected && !isUndefinedOrEmpty(sc.startTime) && !isUndefinedOrEmpty(sc.endTime),
        );
        return (
            formInput.title &&
            formInput.description &&
            (coverPhoto || formInput.coverImageUrl) &&
            startDate &&
            formInput.promotionTypeId &&
            selectedSchedule != -1 &&
            formInput.about
        );
    };

    const ScheduleItemView = (item) => {
        return (
            <Grid
                key={item.title}
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    marginTop: '5px',
                }}
            >
                <Grid item xs={12} md={5} display="flex" flexDirection={'column'}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={item.isSelected}
                                    onChange={(e) => setScheduleItemSelected(item, e.target.checked)}
                                    disabled={isReviewing}
                                    name={`${item.title}Selected`}
                                    sx={{
                                        marginLeft: '10px',
                                        marginRight: '5px',
                                        borderRadius: '4px',
                                        '&.Mui-checked': {
                                            color: '#7F56D9',
                                        },
                                        '&.Mui-disabled': {
                                            '&.Mui-checked': {
                                                color: '#D0D5DD',
                                            },
                                        },
                                    }}
                                />
                            }
                            label={` ${item.title}`}
                            disabled={isReviewing}
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={7} display="flex" flexDirection={'row'} gap={2}>
                    <TextField
                        name={`${item.title}Start`}
                        type="time"
                        value={item.startTime}
                        onChange={(e) => setScheduleItemStart(item, e.target.value)}
                        placeholder="00:00"
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                    <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {' '}
                        -{' '}
                    </Typography>
                    <TextField
                        name={`${item.title}End`}
                        type="time"
                        value={item.endTime}
                        onChange={(e) => setScheduleItemEnd(item, e.target.value)}
                        placeholder="00:00"
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Divider sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} />
            </Grid>
        );
    };

    const ScheduleView = () => {
        return (
            <Grid item xs={12} display="flex" flexDirection={'column'}>
                <Grid
                    container
                    sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}
                >
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Schedule
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Time
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                {schedule.map((item) => ScheduleItemView(item))}
            </Grid>
        );
    };

    const userName = localStorage.getItem('userName');
    const userImageUrl = localStorage.getItem('userImageUrl');
    const userAddress = localStorage.getItem('userAddress');
    
    const PreviewView = () => {
        if (!isReviewing) {
            return null
        }
        const encoded = encodedSchedule()
        let imageUrl
        if (isEditing && formInput.coverImageUrl) {
            imageUrl = APP_BASE_URL + formInput.coverImageUrl
        } else{
            imageUrl = URL.createObjectURL(coverPhoto)
        }

        const event = {
            ...formInput,
            imageUrl,
            venue: {
                imageUrl: APP_BASE_URL + userImageUrl,
                name: userName,
                address: userAddress
            },
            friends: [{
                name: 'John',
                imageUrl: USER_AVATAR_1
            }, {
                name: 'Jane',
                imageUrl: USER_AVATAR_2
            }],
            day: parseScheduleDays(encoded),
            time: parseScheduleTime(encoded),
            totalClaims: formInput.claims,
            claimsCount: 0,
        }
        return (
            <PromoPreview event={event} isPromo={true}/>
        )
    }

    const FormView = () => {
        if (isReviewing) {
            return null
        }
        return (
            <>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Event Name / Title
                    </Typography>
                    <TextField
                        id="title"
                        name="title"
                        type="text"
                        value={formInput.title}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Type your event name"
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Offer Highlights
                    </Typography>
                    <TextField
                        name="description"
                        type="text"
                        value={formInput.description}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Add short description"
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Full Offer Details
                    </Typography>
                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        name="about"
                        value={formInput.about}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Type something about your event"
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Policies & Rules
                    </Typography>
                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        name="rules"
                        value={formInput.rules}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder={'1.\n2.\n3.'}
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                    <Typography pb={1} variant="body1">
                        Event Cover Photo
                    </Typography>
                    <Box
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        sx={{
                            border: '2px dashed #98A2B3',
                            minHeight: '100px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            display: 'flex',
                            backgroundColor: '#F9F5FF',

                            ...((coverPhoto || (isEditing && formInput.coverImageUrl)) && {
                                display: 'block',
                                backgroundColor: '#F3F4F6',
                            }),
                        }}
                    >
                        {' '}
                        {coverPhoto || (isEditing && formInput.coverImageUrl) ? (
                            <div>
                                <div style={{ position: 'relative' }}>
                                    {isEditing && formInput.coverImageUrl ? (
                                        <img
                                            src={APP_BASE_URL + formInput.coverImageUrl}
                                            alt="Cover"
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <img
                                            src={URL.createObjectURL(coverPhoto)}
                                            alt="Cover"
                                            style={{ width: '100%' }}
                                        />
                                    )}

                                    <DeleteIcon
                                        style={{
                                            position: 'absolute',
                                            background: '#667085',
                                            borderRadius: '4px',
                                            width: '25px',
                                            height: '25px',
                                            right: '10px',
                                            top: '9px',
                                            padding: '10px',
                                            color: '#fff',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setCoverPhoto(null);
                                            isEditing && setFormInput({ ...formInput, coverImageUrl: '' });
                                            isReviewing && handleReview(false);
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                }}
                            >
                                {'Drop an image or '}
                                <label
                                    htmlFor="cover-photo-input"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {' Upload a file'}
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileInputChange}
                                    style={{ display: 'none' }}
                                    id="cover-photo-input"
                                />
                            </div>
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileInputChange}
                            style={{ display: 'none' }}
                            id="cover-photo-input"
                            ref={fileInputRef}
                        />
                    </Box>
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} gap={1} xs={12}>
                    <Grid item xs={12} md={6} display="flex" flexDirection={'column'}>
                        <Typography pb={1} variant="body1">
                            Start date
                        </Typography>
                        <TextField
                            name="startTimeStamp"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="MM/DD/YYYY"
                            fullWidth
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        flexDirection={'column'}
                        sx={{ marginTop: { xs: 2, md: 0 } }}
                    >
                        <Typography pb={1} variant="body1">
                            End date
                        </Typography>
                        <TextField
                            name="endTimeStamp"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="MM/DD/YYYY"
                            type="date"
                            fullWidth
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        />
                    </Grid>
                </Grid>

                {ScheduleView()}

                <Grid item xs={12} display="flex" flexDirection={'column'}>
                    <Typography pb={1} variant="body1">
                        Number of available "tickets" to Claim
                    </Typography>
                    <TextField
                        id="claims"
                        name="claims"
                        type="number"
                        value={formInput.claims}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Unlimited"
                        inputProps={{
                            min: '0',
                            style: {
                                textAlign: formInput.claims && formInput.claims > 0 ? 'center' : 'left',
                            },
                        }}
                        fullWidth
                        disabled={isReviewing}
                        className={isReviewing ? 'reviewing-fields' : ''}
                        InputProps={{
                            startAdornment: !isReviewing && (
                                <InputAdornment position="start">
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        style={{
                                            backgroundColor: '#7F56D9',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            marginLeft: '10px',
                                        }}
                                        disabled={isReviewing}
                                        onClick={() => {
                                            if (formInput.claims && formInput.claims > 1) {
                                                handleInputChange(
                                                    'claims',
                                                    parseInt(formInput.claims || 0) - 1,
                                                );
                                            } else {
                                                handleInputChange('claims', '');
                                            }
                                        }}
                                    >
                                        <RemoveIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: !isReviewing && (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        style={{
                                            backgroundColor: '#7F56D9',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                        }}
                                        disabled={isReviewing}
                                        onClick={() =>
                                            handleInputChange('claims', parseInt(formInput.claims || 0) + 1)
                                        }
                                    >
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography pb={1} variant="body1">
                        Promotion Type
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            size="small"
                            fullWidth
                            value={formInput.promotionTypeId}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            name="promotionTypeId"
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        >
                            {allPromotionTypes?.map((promotionType) => {
                                return (
                                    <MenuItem key={promotionType.id} value={promotionType.id}>
                                        {promotionType.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {formInput.promotionTypeId === 'custom' && (
                    <Grid item xs={12} display="flex" flexDirection={'column'}>
                        <TextField
                            id="promotionCustomType"
                            name="promotionCustomType"
                            type="text"
                            value={formInput.promotionCustomType}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            placeholder="Enter you custom promotion type"
                            fullWidth
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        />
                    </Grid>
                )}
            </>
        )
    }

    const HeaderView = () => {
        var title = id ? 'Edit Promotion' : 'Create Promotion'
        if (isReviewing) {
            title = 'User Preview'
        }
        return (
            <Grid item xs={12}>
                <Typography pb={1} variant="h4">{title}</Typography>
            </Grid>
        )
    }

    const FooterView = () => {
        return (
            <Grid item xs={12}>
                {isReviewing ? (
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Button
                                startIcon={<ArrowBackIcon />}
                                onClick={() => handleReview(false)}
                                sx={{
                                    color: '#667085',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    width: '300px'
                                }}
                            >
                                Back to Edit
                            </Button>

                            <CustomButton
                                buttonTitle={isEditing ? 'Update' : 'Publish'}
                                buttonOnClick={isEditing ? handleUpdatePromotion : handleCreatePromotion}
                                buttonDisabled={false}
                                icon={<UploadFileRoundedIcon />}
                                cancelButtonTitle={'Cancel'}
                                navigationRoute={'/manage'}
                            />
                    </div>
                ) : (
                    <CustomButton
                        buttonTitle={'Review'}
                        buttonOnClick={() => handleReview(true)}
                        buttonDisabled={!isReviewAllowed()}
                        icon={<VisibilityIcon />}
                        cancelButtonTitle={'Cancel'}
                        navigationRoute={'/manage'}
                    />
                )}
            </Grid>
        )
    }

    return (
        <Grid container p={3} justifyContent="center">
            <Grid item xs={12} md={9} lg={9} xl={7} mt={2}>
                <Paper sx={{ padding: '20px' }}>
                    <Grid container spacing={3}>
                        {HeaderView()}
                        {FormView()}
                        {PreviewView()}
                        {FooterView()}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CreatePromotion;

import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography, Grid, Paper, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatHeader from './ChatHeader';
import { APP_CONSTANTS } from '../../config/config';

const { APP_BASE_URL } = APP_CONSTANTS;

const ChatUI = (props) => {
    const chatContainerRef = useRef(null);
    const [input, setInput] = React.useState('');
    const { threadMessages, selectedThread, handleMessageSend } = props;

    const handleSend = () => {
        if (input.trim() !== '') {
            console.log(input);
            handleMessageSend({ threadId: selectedThread.thread_id, text: input });
            setInput('');
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [threadMessages]);

    return (
        <Box
            sx={{
                height: props.height || 'calc(100vh - 120px)',
                display: 'flex',
                borderRadius: '16px',
                flexDirection: 'column',
                background: 'white',
            }}
        >
            {selectedThread ? (
                <>
                    <Box>
                        <ChatHeader
                            backIcon={props.backIcon}
                            name={selectedThread.name}
                            image_url={selectedThread.image_url}
                        />
                    </Box>
                    <Box
                        sx={{ flexGrow: 1, overflow: 'auto', p: { xs: '4px', md: '16px 20px' } }}
                        ref={chatContainerRef}
                    >
                        {threadMessages.map((message) => (
                            <Message
                                key={message.id}
                                message={message}
                                name={selectedThread.name}
                                image_url={selectedThread.image_url}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'background.default',
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                            background: 'white',
                            borderTop: '1px solid #EAECF0',
                        }}
                    >
                        <Grid container spacing={2} p={2} direction="column">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Type a message"
                                    value={input}
                                    variant="filled"
                                    inputProps={{
                                        style: {
                                            border: 'none',
                                            outline: 'none',
                                            background: 'white',
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            borderBottom: 'none',
                                        },
                                        disableUnderline: true,
                                    }}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item flexGrow={1}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                color="primary"
                                                variant="contained"
                                                startIcon={<SendIcon />}
                                                sx={{
                                                    width: '103px',
                                                    color: 'white',
                                                    background: '#7F56D9',
                                                    p: '8px 16px',
                                                    borderRadius: '8px',
                                                    textTransform: 'inherit',
                                                }}
                                                onClick={handleSend}
                                            >
                                                Send
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                <>
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <Typography variant="body1" color="textSecondary">
                            Select a thread to start chatting.
                        </Typography>
                    </Grid>
                </>
            )}
        </Box>
    );
};

const Message = ({ message, image_url, name }) => {
    const isCurrentUser = message.sender_type !== 1;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isCurrentUser ? 'row' : 'row-reverse',
                gap: '10px',
                mb: 0.8,
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
                alignItems: isCurrentUser ? 'flex-start' : 'flex-end',
            }}
        >
            {isCurrentUser && (
                <Box>
                    <Box sx={{ marginTop: '20px' }}>
                        <Avatar alt="User" src={APP_BASE_URL + image_url} />
                    </Box>
                </Box>
            )}

            <Box>
                {isCurrentUser ? <Typography sx={{ fontSize: '13px', color: '#344054' }}>{name}</Typography> : null}
                <Paper
                    sx={{
                        mt: '4px',
                        p: '10px 16px',
                        backgroundColor: isCurrentUser ? '#EAECF0' : '#7F56D9',
                        display: 'flex',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: '291px',
                    }}
                >
                    <Typography variant="body1" sx={{ color: isCurrentUser ? '#1D2939' : 'white', fontSIze: '15px' }}>
                        {message.text}
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default ChatUI;

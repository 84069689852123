import { filter, isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Box
} from '@mui/material';
import { QrReader } from 'react-qr-reader';
import { redeemDrink } from '../../store/slices/wallet.slice';
import TabBar from './TabBar';
import Transactions from './Transactions'
import Coupons from './Coupons'
import { getCreditsAndCoupons, getTransactions } from '../../store/slices/wallet.slice';
import dayjs from 'dayjs';
import axios from 'axios';
import { API_CONSTANTS } from '../../config/config'; 

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);

const Payments = () => {
    const dispatch = useDispatch();
    const wallet = useSelector((state) => state.wallet);
    const {creditsAndCoupons, transactionsData} = wallet

    const [activeTab, setActiveTab] = useState(0)

    const [coupons, setCoupons] = useState([])
    const [totalCoupons, setTotalCoupons] = useState(0)
    const [currentCouponPage, setCurrentCouponPage] = useState(0)
    const [couponsOrderBy, setCouponsOrderBy] = useState('date')

    const [transactions, setTransactions] = useState([])
    const [balance, setBalance] = useState('')
    const [totalTransactions, setTotalTransactions] = useState(0)
    const [currentTransactionPage, setCurrentTransactionPage] = useState(0)
    const [transactionFilterFrom, setTransactionFilterFrom] = useState()
    const [transactionFilterTo, setTransactionFilterTo] = useState()
    const [transactionFilterType, setTransactionFilterType] = useState()

    useEffect(() => {
        const params = {pageNumber: currentCouponPage, orderBy: couponsOrderBy}
        dispatch(getCreditsAndCoupons(params))    
    }, [currentCouponPage, couponsOrderBy]);

    useEffect(() => {
        if (creditsAndCoupons == undefined) { return }
        setCoupons(creditsAndCoupons.drinks.map(item => ({
            ...item,
            date: dayjs(item.sent_date).format('MM/DD/YYYY'),
            recipient: item.name,
            status: item.is_redeemed == true ? 'Redeemed' : item.is_expired == true ? 'Expired' : 'Pending'
        })))
        setTotalCoupons(Number(creditsAndCoupons.total))
    }, [creditsAndCoupons])

    useEffect(() => {
        const params = {
            pageNumber: currentTransactionPage, 
            fromTimeStamp: transactionFilterFrom,
            toTimeStamp: transactionFilterTo,
            type: transactionFilterType
        }
        
        dispatch(getTransactions(params))    
    }, [currentTransactionPage, transactionFilterFrom, transactionFilterTo, transactionFilterType]);

    useEffect(() => {
        if (transactionsData == undefined) { return }
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });          

        setTransactions(transactionsData.drinks.map(item => ({
            ...item,
            date: dayjs(item.redeem_date).format('MM/DD/YYYY'),
            description: item.type == 'Drink Redeemed' ? `${item.name} redeemed 1 drink` : `You requested a payout`,
            refId: item.ref_id,
            amount: currencyFormatter.format(item.amount / 100),
            balance: item.balance == null ? '' : currencyFormatter.format(item.balance / 100),
        })))
        setTotalTransactions(Number(transactionsData.total))
        setBalance(currencyFormatter.format(transactionsData.balance / 100))
    }, [transactionsData])

    // Actions
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleTransactionsFilterChange = (fromTimeStamp, toTimeStamp, type) => {
        if (isUndefinedOrEmpty(fromTimeStamp) || isUndefinedOrEmpty(toTimeStamp)) {
            setTransactionFilterFrom(undefined)
            setTransactionFilterTo(undefined)
        } else {
            setTransactionFilterFrom(dayjs(fromTimeStamp).startOf('day').format('MM-DD-YYYY HH:mm:ss'))
            setTransactionFilterTo(dayjs(toTimeStamp).endOf('day').format('MM-DD-YYYY HH:mm:ss'))
        }
        let filterType
        if (type == 1) {
            filterType = 'redeemed'
        } else if (type == 2) {
            filterType = 'withdraw'
        }
        setTransactionFilterType(filterType)
    }

    const handleDownloadInvoices = () => {
        console.log('download invoices')
    }

    const handleDownloadCSV = () => {
        const accessToken = localStorage.getItem('accessToken');
        const fileName = `transactions_${currentTransactionPage}.csv`
        var url = `${API_CONSTANTS.API_URL}v1/download/csv?pageNumber=${currentTransactionPage}`
        if (transactionFilterFrom != undefined && transactionFilterTo != undefined) {
          url = `${url}&fromTimeStamp=${encodeURIComponent(transactionFilterFrom)}&toTimeStamp=${encodeURIComponent(transactionFilterTo)}`
        }
        if (transactionFilterType != undefined) {
          url = `${url}&type=${transactionFilterType}`
        }
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: accessToken,
          },
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch(error => {
            console.error('Error downloading CSV:', error);
          });
    };

    const handleChangeTransactionPage = (event, newPage) => {
        setCurrentTransactionPage(newPage)
    }

    const handleChangeCouponSort = (newOrder) => {
        setCouponsOrderBy(newOrder)
    }

    const handleChangeCouponPage = (event, newPage) => {
        setCurrentCouponPage(newPage)
    } 
    
    return (
        <Fragment>
            <TabBar 
                active={activeTab}
                handleChange={handleTabChange}
                firstTab={<Box>Transaction History</Box>}
                secondTab={<Box>Credits & Coupons</Box>}
            />

            {activeTab === 0 && (
                <Box className="panel-body-payment">
                    <Transactions 
                    list={transactions} 
                    balance={balance} 
                    total={totalTransactions}
                    page={currentTransactionPage}
                    handleFilterChange={handleTransactionsFilterChange}
                    handleDownloadInvoices={handleDownloadInvoices}
                    handleDownloadCSV={handleDownloadCSV}
                    handleChangePage={handleChangeTransactionPage}
                    />
                </Box>
            )}

            {activeTab === 1 && (
                <Box className="panel-body-payment">
                    <Coupons 
                    list={coupons}
                    total={totalCoupons}
                    page={currentCouponPage}
                    handleChangeSort={handleChangeCouponSort}
                    handleChangePage={handleChangeCouponPage}
                    />
                </Box>
            )}

        </Fragment>
    )
}

export default Payments;
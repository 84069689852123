import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, Select, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { frequencyOptions } from '../../config/constants';

const useStyles = makeStyles({
    checkbox: {
        borderRadius: '4px',
        '&.Mui-checked': {
            color: '#7F56D9',
        },
        '&.Mui-disabled': {
            '&.Mui-checked': {
                color: '#D0D5DD',
            },
        },
    },
});

const Frequency = ({ isReviewing, frequency, handleInputChange }) => {
    const [frequencyChecked, setFrequencyChecked] = useState(false);

    const classes = useStyles();

    const handleFrequencyChecked = (event) => {
        setFrequencyChecked(event.target.checked);
    };
    useEffect(() => {
        // Set frequencyChecked to true if frequency is not empty
        setFrequencyChecked(!!frequency);
    }, [frequency]);
    return (
        <>
            <Grid item xs={12} ml={1}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkbox}
                                // defaultChecked={false}
                                checked={frequencyChecked}
                                onChange={handleFrequencyChecked}
                                disabled={isReviewing}
                                name="frequencyChecked"
                            />
                        }
                        label="Frequency"
                        disabled={isReviewing}
                    />
                </FormGroup>
            </Grid>
            {frequencyChecked && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Select
                            size="small"
                            fullWidth
                            value={frequency}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            name="frequency"
                            disabled={isReviewing}
                            className={isReviewing ? 'reviewing-fields' : ''}
                        >
                            {frequencyOptions?.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
        </>
    );
};

export default Frequency;

import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import { Box, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS } from '../../config/config';
import OffersActivity from '../Offers/OffersActivity';

export const ActiveEvent = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeEvent = [] } = props;
    const { APP_BASE_URL } = APP_CONSTANTS;
    const dropdownOptions = [
        { title: 'Edit', icon: <EditRoundedIcon sx={{ color: '#98A2B3' }} />, route: '/edit-package' },
        { title: 'Analyze', icon: <InsertChartRoundedIcon sx={{ color: '#98A2B3' }} />, route: '/analyze' },
    ];
    const handleOfferDropdownChange = (path, id, name) => {
        if (name === 'Edit') {
            navigate(`${path}/${id}`);
        } else {
            navigate(path);
        }
    };
    return (
        <Box>
            <Grid container spacing={1} alignItems="start">
                <Grid item>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '17px', pb: 2 }}>
                        Active Event
                    </Typography>
                </Grid>
                <Grid item>
                    <Chip
                        label={activeEvent?.length}
                        sx={{
                            fontWeight: 700,
                            fontSize: '12px',
                            color: '#ffffff',
                            width: '20px',
                            height: '20px',
                            background: '#12B76A',
                            borderRadius: '100px',
                            textAlign: 'center',
                            padding: '10px',
                            overflow: 'inherit',
                        }}
                    />
                </Grid>
            </Grid>

            <span></span>

            <div style={{ overflowX: 'auto' }}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {activeEvent?.map((promotion) => (
                        <div key={promotion.id} style={{ maxWidth: '230px' }}>
                            <OffersActivity
                                key={promotion.id}
                                offerId={promotion.id}
                                title={promotion.title}
                                date={promotion.start_timestamp}
                                subTitle={promotion.description}
                                backgroundImageURL={APP_BASE_URL + promotion.cover_image_url}
                                enableFooter={true}
                                marginTop="110px"
                                dropdownOptions={dropdownOptions}
                                handleOfferDropdownChange={handleOfferDropdownChange}
                                Footer={
                                    <>
                                        <Grid container>
                                            <Grid item>
                                                <Typography
                                                    className="offer-footer"
                                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                                >
                                                    <span>
                                                        <DateRangeRoundedIcon />
                                                    </span>
                                                    {`Every Monday: 5:00 - 7:00 pm`}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className="offer-footer"
                                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                                >
                                                    <span>
                                                        <FmdGoodOutlinedIcon />
                                                    </span>
                                                    Location
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                badgeClass="bg-green"
                            />
                        </div>
                    ))}
                </Box>
            </div>
        </Box>
    );
};

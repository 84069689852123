import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function requestSignin(data) {
    return axios.post(`${API_URL}v1/venue/loginEmailPassword`, data);
}

export function generateOtp(accessToken) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/auth/generate-otp`,
        headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json',
        },
        data: {},
    };

    return axios(config);
}

export function verifyOtp(data) {
    return axios.post(
        `${API_URL}v1/auth/verify-otp?token=${data.otpToken}`,
        {
            otp: data.otp,
        },
        {
            headers: {
                Authorization: `${data.accessToken}`,
            },
        },
    );
}

export function registerUser(data) {
    console.log('🚀 ~ file: user.request.js ~ line 38 ~ registerUser', data);
    var config = {
        method: 'post',
        url: `${API_URL}v1/venue/registerEmailPassword`,
        data: data,
    };
    return axios(config);
}

export function forgotPassword(data) {
    console.log('🚀 ~ file: user.request.js ~ line 38 ~ forgotPassword ~ forgotPassword', data);
    var config = {
        method: 'post',
        url: `${API_URL}v1/auth/forgotPassword`,
        data: {
            email: data,
        },
    };
    return axios(config);
}

export function resetPassword(data) {
    console.log('🚀 ~ file: user.request.js ~ line 50 ~ resetPassword ~ data', data);
    var config = {
        method: 'post',
        url: `${API_URL}v1/auth/reset-password?token=${data.userToken}`,
        data: {
            password: data.password,
        },
    };
    return axios(config);
}

export function confirmEmail(data) {
    console.log('🚀 ~ file: user.request.js ~ line 72 ~ confirmEmail ~ data', data);
    var config = {
        method: 'post',
        url: `${API_URL}v1/auth/confirmEmail/${data.confirmToken}`,
    };
    return axios(config);
}

export function requestLogout(data) {
    console.log('tokens ==>', data);
    var config = {
        method: 'post',
        url: `${API_URL}v1/auth/logout`,
        headers: {
            Authorization: `Bearer ${data.accessToken}`,
        },
        data: {
            refreshToken: data.refreshToken,
        },
    };
    return axios(config);
}

export function getAllQuestions(accessToken) {
    // console.log(accessToken);
    var config = {
        method: 'get',
        url: `${API_URL}v1/question/all`,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    return axios(config);
}

export function getProfileRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/venue/get`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function updateProfileRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/venue/update`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.form,
    };

    return axios(config);
}

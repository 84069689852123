import { takeLatest } from 'redux-saga/effects';
import {
    handleSignin,
    handleRegister,
    handleLogout,
    handleForgotEmail,
    handleResetEmail,
    handleConfirmEmail,
    handleGetProfile,
    handleUpdateProfile,
} from './handlers/user.handler';
import {
    signinUser,
    registerUser,
    isEmailSent,
    passwordReset,
    confirmEmail,
    getProfile,
    updateProfile
} from '../slices/user.slice';
import { questionsHandler } from './handlers/question.handler';
import { getQuestion } from '../slices/questions.slice';
import {
    handleGetUpComingPromotions,
    handleGetPromotionTypes,
    handleCreatePromotion,
    handleGetPromotionDetail,
    handleUpdatePromotion,
    handleEndPromotion,
    handleGetPromotionsByRange,
} from './handlers/promotion.handler';
import {
    getUpComingPromotions,
    getPromotionTypes,
    createNewPromotion,
    getPromotionDetail,
    updatePromotion,
    endPromotion,
    getPromotionsByRange,
} from '../slices/promotion.slice';
import {
    handleGetUpComingPackage,
    handleGetPackageTypes,
    handleCreatePackage,
    handleGetPackageDetail,
    handleUpdatePackage,
    handleEndPackage,
    handleGetPackagesByRange,
} from './handlers/package.handler';
import {
    getUpComingPackages,
    getPackageTypes,
    createNewPackage,
    updatePackage,
    getPackageDetail,
    endPackage,
    getPackagesByRange,
} from '../slices/package.slice';
import { isUserLogOut } from '../slices/logout.slice';

import { 
    getStats,
    getStatsActive,
    getStatsClaimed,
    getStatsDrinks,
    getStatsUpcoming
 } from '../slices/analyze.slice';
import { 
    handleGetStats,
    handleGetStatsActive,
    handleGetStatsClaimed,
    handleGetStatsDrinks,
    handleGetStatsUpcoming
 } from './handlers/analyze.handler';

import { getThreads, getThreadMessages, sendMessage } from '../slices/chat.slice';
import { handleGetThreads, handleGetThreadMessages, handleSendMessage } from './handlers/chat.handler';

import {
    getPaymentMethods,
    addPaymentMethod,
    updateDefaultPaymentMethod,
    sendDrink,
    getHomeData,
    redeemDrink,
    getNotifications,
    getCreditsAndCoupons,
    getTransactions
} from '../slices/wallet.slice';

import {
    handleGetPaymentMethods,
    handleAddPaymentMethod,
    handleUpdateDefaultPaymentMethod,
    handleSendDrink,
    handleGetHomeData,
    handleRedeemDrink,
    handleGetNotifications,
    handleGetCreditsAndCoupons,
    handleGetTransactions
} from './handlers/wallet.handler';

export function* watcherSaga() {
    yield takeLatest(signinUser.type, handleSignin);
    yield takeLatest(registerUser.type, handleRegister);
    yield takeLatest(getProfile.type, handleGetProfile);
    yield takeLatest(updateProfile.type, handleUpdateProfile);
    yield takeLatest(isUserLogOut.type, handleLogout);
    yield takeLatest(getUpComingPromotions.type, handleGetUpComingPromotions);
    yield takeLatest(getPromotionTypes.type, handleGetPromotionTypes);
    yield takeLatest(getPromotionDetail.type, handleGetPromotionDetail);
    yield takeLatest(createNewPromotion.type, handleCreatePromotion);
    yield takeLatest(updatePromotion.type, handleUpdatePromotion);
    yield takeLatest(endPromotion.type, handleEndPromotion);
    yield takeLatest(getPromotionsByRange.type, handleGetPromotionsByRange);
    yield takeLatest(getQuestion.type, questionsHandler);
    yield takeLatest(isEmailSent.type, handleForgotEmail);
    yield takeLatest(passwordReset.type, handleResetEmail);
    yield takeLatest(confirmEmail.type, handleConfirmEmail);
    yield takeLatest(getUpComingPackages.type, handleGetUpComingPackage);
    yield takeLatest(getPackageTypes.type, handleGetPackageTypes);
    yield takeLatest(createNewPackage.type, handleCreatePackage);
    yield takeLatest(getPackageDetail.type, handleGetPackageDetail);
    yield takeLatest(updatePackage.type, handleUpdatePackage);
    yield takeLatest(endPackage.type, handleEndPackage);
    yield takeLatest(getPackagesByRange.type, handleGetPackagesByRange);
    yield takeLatest(getStats.type, handleGetStats);
    yield takeLatest(getThreads.type, handleGetThreads);
    yield takeLatest(getThreadMessages.type, handleGetThreadMessages);
    yield takeLatest(sendMessage.type, handleSendMessage);
    yield takeLatest(getPaymentMethods.type, handleGetPaymentMethods);
    yield takeLatest(addPaymentMethod.type, handleAddPaymentMethod);
    yield takeLatest(updateDefaultPaymentMethod.type, handleUpdateDefaultPaymentMethod);
    yield takeLatest(sendDrink.type, handleSendDrink);
    yield takeLatest(getHomeData.type, handleGetHomeData);
    yield takeLatest(redeemDrink.type, handleRedeemDrink);
    yield takeLatest(getNotifications.type, handleGetNotifications);
    yield takeLatest(getCreditsAndCoupons.type, handleGetCreditsAndCoupons);
    yield takeLatest(getTransactions.type, handleGetTransactions);
    yield takeLatest(getStatsActive.type, handleGetStatsActive);
    yield takeLatest(getStatsDrinks.type, handleGetStatsDrinks);
    yield takeLatest(getStatsClaimed.type, handleGetStatsClaimed);
    yield takeLatest(getStatsUpcoming.type, handleGetStatsUpcoming);
}

import React from 'react';
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
    '& .Mui-selected': {
        background: 'white !important',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        padding: '1px 10px',
        borderRadius: '6px',
    },
}));

const IconToggleButtonGroup = ({ icons, value, onChange }) => {
    return (
        <StyledToggleButtonGroup
            value={value}
            sx={{ backgroundColor: '#F2F4F7', p: '4px', borderRadius: '10px' }}
            exclusive
            onChange={onChange}
            aria-label="icon toggle button group"
        >
            {icons.map((icon, index) => (
                <ToggleButton key={index} value={icon.value} aria-label={icon.label}>
                    {icon.component}
                </ToggleButton>
            ))}
        </StyledToggleButtonGroup>
    );
};

export default IconToggleButtonGroup;

/* Constants to be used across the app */

export const APP_CONSTANTS = {
    APP_TITLE: 'SipSender',
    PAGE_NOT_FOUND: 'Page Not found',
    APP_BASE_URL: process.env.REACT_APP_BASE_URL
};

export const API_CONSTANTS = {
    API_URL: process.env.REACT_APP_API_URL
};

export const STRIPE_CONSTANTS = {
    PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY
};

export const MOBILE_APPS_CONSTANTS = {
    APP_STORE_LINK: process.env.REACT_APP_APP_STORE_LINK || 'https://apps.apple.com/ru/app/sipsender/id6474432564',
    PLAY_STORE_LINK: process.env.REACT_APP_PLAY_STORE_LINK || 'https://play.google.com/store/apps/details?id=YourAppId'
};

import React, { useState } from 'react';
import { Grid, Typography, Button, Box, TextField, CssBaseline } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import { isEmailSent, emailSent } from '../../store/slices/user.slice';
import SPILOGO from '../../assets/images/SpiLogo.svg';

const defaultFormInput = {
    email: '',
};

const ForgotPassword = () => {
    const dispatch = useDispatch();

    const [formInput, setFormInput] = useState(defaultFormInput);

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const dispatchSnackBar = (type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    };

    const handleInputChange = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        });
    };

    const handleForgotPassword = (e) => {
        e.stopPropagation();

        const signinDataObject = {
            email: formInput.email,
        };

        if (signinDataObject.email === '') {
            dispatchSnackBar('error', 'Kindly Enter the valid email', true);
        } else {
            dispatch(isEmailSent(signinDataObject.email));
            dispatchSnackBar('success', 'Reset password email generated Successfully', true);
        }
    };

    return (
        <>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="h3" variant="h5" align="center">
                                Forgot Your Password?
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="p" variant="body2" align="center">
                                We’ll email you instructions to reset your password.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{
                                    mb: 2,
                                }}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                value={formInput.newPassword}
                                fullWidth
                                name="email"
                                type="email"
                                id="email"
                                placeholder="Email Address"
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} alignItems="center" pt={2}>
                            <Button
                                type="button"
                                className="button-primary FllWidthBtn"
                                variant="contained"
                                onClick={(e) => handleForgotPassword(e)}
                            >
                                Send Email
                            </Button>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={'center'} alignItems="center" pt={2}>
                            <Typography>Login Instead?</Typography>
                            <Button type="button" className="button-transparent">
                                <Link
                                    style={{ textDecoration: 'underline', color: 'blue', textTransform: 'none' }}
                                    to="/login"
                                >
                                    Login
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ForgotPassword;

import { createSlice } from '@reduxjs/toolkit';

const GlobalSlice = createSlice({
    name: 'global',
    initialState: {
        showLoading: false,
    },
    reducers: {
        setShowLoading(state, action) {
            state.showLoading = action.payload.showLoading;
        },
    },
});

export const { setShowLoading } = GlobalSlice.actions;

export default GlobalSlice.reducer;

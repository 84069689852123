import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import React, { Fragment } from 'react';

const SearchBar = (props) => {
    const { isIconRequired = true, handleChangeSearch } = props;
    return (
        <Fragment>
            <Box className="flex-fill search-main-div">
                <input
                    type={props.type}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        handleChangeSearch(e);
                    }}
                    className="flex-fill"
                />
                {isIconRequired && <SearchIcon className="search-icon-main " />}
            </Box>
        </Fragment>
    );
};

export default SearchBar;

import React, {useEffect, useState} from 'react';
import {
    Grid,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    MenuItem, 
    Select,
    TablePagination
} from '@mui/material';

const styles = {
    tableHeader: {
        backgroundColor: '#F9FAFB',
    },
    rowCell: {
        fontSize: '15px', 
        color: '#344054',
        fontWeight: 400,
    },
    headerCell: {
        fontSize: '15px',
        color: '#1D2939',
        fontWeight: 600,
    },
    titleText: {
        fontSize: '15px',
        color: '#1D2939',
        fontWeight: 600,
    },
    filtersInnerContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    redeemedStatusText: {
        color: '#027A48',
    },
    pendingStatusText: {
        color: '#344054',
    },
    expiredStatusText: {
        color: '#B42318',
    },
}

const SortView = (props) => {
    const {selectedOrder, handleOrderChange} = props
    const options = [{
        id: '0',
        title: 'Date'
    }, {
        id: '1',
        title: 'Name of Recipient'
    }]

    return (
        <Grid item xs={12} style={styles.filtersInnerContainer}>
            <Select
                className="select-dropdown-payment"
                onChange={handleOrderChange}
                value={selectedOrder}
            >
                {options?.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.id}>
                            Sort by:&nbsp;<span style={{fontWeight: 'bold'}}>{item.title}</span>
                        </MenuItem>
                    );
                })}
            </Select>

            <Box style={{flex: 1}}/>
        </Grid>
    )
}

const HeaderView = () => {
    return (
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
            <span style={styles.titleText}>History of free drinks sent</span>
            <Box style={{flex: 1}}/>
        </Grid>
    )
}

const TableView = (props) => {
    const {list, total, page, handleChangePage} = props

    return (
        <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="coupons table">
                <TableHead>
                    <TableRow style={styles.tableHeader}>
                        <TableCell style={styles.headerCell}>Date</TableCell>
                        <TableCell style={styles.headerCell}>Name of Recipient</TableCell>
                        <TableCell style={styles.headerCell}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.length == 0 ? (
                        <TableRow>
                            <TableCell colSpan={12} style={{...styles.rowCell, textAlign: 'center'}}>No records found</TableCell>
                        </TableRow>
                    ) :
                    list.map((item, index) => {
                        const color = item.status == 'Redeemed' ? styles.redeemedStatusText : item.status == 'Pending' ? styles.pendingStatusText : styles.expiredStatusText
                        const statusStyle = {...styles.rowCell, ...color}
                        
                        return (
                            <TableRow key={index}>
                                <TableCell style={styles.rowCell}>{item.date}</TableCell>
                                <TableCell style={styles.rowCell}>{item.recipient}</TableCell>
                                <TableCell style={statusStyle}>{item.status}</TableCell>
                            </TableRow>
                        )
                    })
                    }
                </TableBody>
                <TablePagination
                colSpan={12}
                rowsPerPageOptions={[]}
                count={total}
                rowsPerPage={10}
                page={page}
                SelectProps={{
                    inputProps: {
                    'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                style={{borderWidth: 0}}
                onPageChange={handleChangePage}
                />
            </Table>
        </Grid>
    )
}

const Coupons = (props) => {
    const {list, total, page, handleChangePage, handleChangeSort} = props

    const [selectedOrder, setSelectedOrder] = useState('0')

    useEffect(() => {
        let order
        if (selectedOrder == '1') {
            order = 'name'
        } else {
            order = 'date'
        }
        handleChangeSort(order)
    }, [selectedOrder]);

    const handleOrderChange = (e) => {
        setSelectedOrder(e.target.value)
    }

    return (
        <Grid container spacing={2}>
            <HeaderView/>

            <SortView
            selectedOrder={selectedOrder} 
            handleOrderChange={handleOrderChange}
            />

            <TableView 
            list={list}
            total={total}
            page={page}
            handleChangePage={handleChangePage}
            />
        </Grid>
    )
}
export default Coupons;
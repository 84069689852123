import { createSlice } from '@reduxjs/toolkit';

const WalletSlice = createSlice({
    name: 'wallet',
    initialState: {
        paymentMethods: undefined,
        defaultPaymentId: undefined,
        newPaymentMethod: undefined,
        isApiDone: false,
        homeData: undefined,
        notifications: undefined,
        creditsAndCoupons: undefined,
        transactionsData: undefined
    },
    reducers: {
        getPaymentMethods(state, action) {},
        setPaymentMethods(state, action) {
            state.paymentMethods = action.payload
        },
        setDefaultPaymentId(state, action) {
            state.defaultPaymentId = action.payload
        },
        addPaymentMethod(state, action) {},
        addPaymentMethodDone(state, action) {
            state.newPaymentMethod = action.payload
        },
        updateDefaultPaymentMethod(state, action) {},
        sendDrink(state, action) {},
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
        getHomeData(state, action) {},
        setHomeData(state, action) {
            state.homeData = action.payload
        },
        redeemDrink(state, action) {},
        getNotifications(state, action) {},
        setNotifications(state, action) {
            state.notifications = action.payload
        },
        getCreditsAndCoupons(state, action) {},
        setCreditsAndCoupons(state, action) {
            state.creditsAndCoupons = action.payload
        },
        getTransactions(state, action) {},
        setTransactions(state, action) {
            state.transactionsData = action.payload
        }
    }
})

export const {
    getPaymentMethods,
    setPaymentMethods,
    setDefaultPaymentId,
    addPaymentMethod,
    addPaymentMethodDone,
    updateDefaultPaymentMethod,
    sendDrink,
    setIsDone,
    getHomeData,
    setHomeData,
    redeemDrink,
    getNotifications,
    setNotifications,
    getCreditsAndCoupons,
    setCreditsAndCoupons,
    getTransactions,
    setTransactions
} = WalletSlice.actions

export default WalletSlice.reducer
import { Grid, Typography, Button } from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';
import React, { useState, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { APP_CONSTANTS } from '../../config/config';
import { sendDrink } from '../../store/slices/wallet.slice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendDrinkModal from './SendDrinkModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import { showSnackBar } from '../../store/slices/snackbar.slice';
import dayjs from 'dayjs';

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);

function SipSender(props) {
    const { avatarList } = props;
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])

    const { APP_BASE_URL } = APP_CONSTANTS;
    const dispatch = useDispatch();

    const snackbarObject = {
        type: '',
        message: '',
        open: false,
    };

    const dispatchSnackBar = useCallback((type, message, open) => {
        snackbarObject.type = type;
        snackbarObject.message = message;
        snackbarObject.open = open;

        dispatch(showSnackBar(snackbarObject));
    }, []);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handSendFreeDrinkClick = () => {
        if (selectedUsers.length == 0) {
            dispatchSnackBar('error', 'Please select SipSenders to send free drinks.', true);
            return
        }
        setShowConfirmationModal(true)
    }

    const handleConfirmModalClose = () => {
        setShowConfirmationModal(false)
    }

    const handleConfirmationModalSend = (expiryDate, expiryTime, description) => {
        if (isUndefinedOrEmpty(expiryDate) || isUndefinedOrEmpty(expiryTime)) {
            dispatchSnackBar('error', 'Please enter a valide date and time to continue.', true);
            return
        }
        setShowConfirmationModal(false)
        
        const [hour, minute] = expiryTime.split(':').map(Number);
        const date = dayjs(expiryDate)
        .set('hour', hour)
        .set('minute', minute)     

        const params = {
            receiverIds: selectedUsers.map(user => user.id).join(","),
            expiry: dayjs(date).format('MM-DD-YYYY HH:mm:ss'),
            description
        }
        dispatch(sendDrink(params))
        setSelectedUsers([])
    }

    const isUserSelected = (user) => {
        return selectedUsers.some(item => item.id == user.id)
    }

    const toggleUserSelected = (user) => {
        const index = selectedUsers.findIndex((item) => item.id == user.id);
        if (index == -1) {
            selectedUsers.push(user);
        } else {
            selectedUsers.splice(index, 1);
        }
        setSelectedUsers(selectedUsers)
    }

    return (
        <Grid container sx={{ backgroundColor: '#FFFFFF', borderRadius: '20px', p: 2 }}>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                <div>
                    <Typography
                        variant="body1"
                        sx={{ color: '#1D2939', fontWeight: 600, fontSize: '17px', lineHeight: '25px' }}
                    >
                        SipSenders Within a 1-Mile Radius
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: '#344054',
                            fontWeight: 300,
                            fontSize: '17px',
                            lineHeight: '25px',
                        }}
                    >
                        Send free drinks to nearby SipSenders and increase foot traffic
                    </Typography>
                </div>

                <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    startIcon={<SendIcon />}
                    sx={{
                        color: 'white',
                        background: '#7F56D9',
                        p: '8px 16px',
                        borderRadius: '8px',
                        textTransform: 'inherit',
                    }}
                    onClick={handSendFreeDrinkClick}
                >
                    Send free drink
                </Button>
            </Grid>
            <Grid item pt={3} sx={{ overflowX: 'auto' }}>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Stack direction="row" spacing={1}>
                        {avatarList?.map((item, index) => {
                            const isHovered = hoveredIndex === index;
                            const isSelected = isUserSelected(item)
                            return (
                                <Tooltip
                                    key={index}
                                    title={item.name}
                                    placement="top-start"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div 
                                    className={`tooltip-container ${isHovered ? 'hovered' : ''}`} 
                                    onClick={() => toggleUserSelected(item)}
                                    >
                                        <Avatar
                                            style={isSelected ? {border: '2px solid #7F56D9'}: {border: '2px solid transparent'}}
                                            id={`avatar-${index}`}
                                            key={index}
                                            alt="Remy Sharp"
                                            src={APP_BASE_URL + item.image_url}
                                        />
                                        {isSelected && <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, borderRadius: '100px', display: 'flex', justifyContent: 'flex-end'}}>
                                            <div style={{backgroundColor: '#fff', borderRadius: '8px', width: '15px', height: '15px', paddingBottom: '1px', paddingLeft: '1px'}}>
                                                <CheckCircleIcon style={{color: '#7F56D9', width: '14px', height: '14px'}}/>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </Stack>
                </Box>
            </Grid>
            <SendDrinkModal 
            isOpen={showConfirmationModal}
            list={selectedUsers}
            handleClose={handleConfirmModalClose} 
            handleSend={handleConfirmationModalSend}/>
        </Grid>
    );
}

export default SipSender;

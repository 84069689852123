import { Grid } from '@mui/material';
import React from 'react';
import useDebouncedSearch from '../../components/General/DebounceSearch.jsx'; // Import the custom hook
import { ActiveEvent, PastEvent, SearchFilter, UpcomingEvent } from '../../components/Manage';
import { useState } from 'react';

function EventPackages(props) {
    const { handleChangeDate, handleRangeSelection, selectedDate, packagesList } = props;
    const initialData = {
        upComingPackages: packagesList.upComing,
        activePackages: packagesList.active,
        pastPackages: packagesList.past,
    };
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebouncedSearch(searchValue, 500);
    // const { setSearch, filteredData } = useDebouncedSearch(
    //     {
    //         active: initialData.activePackages,
    //         upComing: initialData.upComingPackages,
    //         past: initialData.pastPackages,
    //     },
    //     500,
    // );
    const filteredUpComingPackages = initialData.upComingPackages.filter((eventPackage) =>
        eventPackage.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    const filteredActivePackages = initialData.activePackages.filter((eventPackage) =>
        eventPackage.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    const filteredPastPackages = initialData.pastPackages.filter((eventPackage) =>
        eventPackage.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SearchFilter
                    handleChangeSearch={handleChangeSearch}
                    handleChangeDate={handleChangeDate}
                    handleRangeSelection={handleRangeSelection}
                    selectedDate={selectedDate}
                    showSearch={true}
                />
            </Grid>
            <Grid item xs={12}>
                <UpcomingEvent upcomingEvent={filteredUpComingPackages} />
            </Grid>
            <Grid item xs={12}>
                <ActiveEvent activeEvent={filteredActivePackages} />
            </Grid>
            <Grid item xs={12}>
                <PastEvent pastEvent={filteredPastPackages} />
            </Grid>
        </Grid>
    );
}

export default EventPackages;

import React, { useCallback, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import ACTIVE_ENGAGEMENT from '../../assets/images/ActiveEngagement.png';
import IconToggleButtonGroup from '../General/IconToggleButtonGroup';
import GRAPH_ICON from '../../assets/images/GraphIcon.png';

const colors = ['#F79009', '#2E90FA', '#7F56D9'];

const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } =
        props;
    const sin = Math.sin(-midAngle * (Math.PI / 180));
    const cos = Math.cos(-midAngle * (Math.PI / 180));
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >{`${name} ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const ColorLegend = ({data}) => {
    return (
        <Grid container spacing={1} alignItems="center">
            {data.map((entry, index) => (
                <Grid item key={`legend-${index}`}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <div
                                style={{
                                    backgroundColor: colors[index % colors.length],
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '1px',
                                    display: 'inline-block',
                                    marginRight: '8px',
                                }}
                            ></div>
                        </Grid>
                        <Grid item mb={'5.2px'}>
                            <Typography variant="body2">{entry.name}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

const Engagement = ({data}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [alignment, setAlignment] = useState('left');

    const matchesOnSmallDevice = useMediaQuery('(max-width:600px)');
    const matchesOnMediumDevice = useMediaQuery('(min-width:1023px)');

    const chartSmallWidth = matchesOnSmallDevice ? 320 : 400;
    const chartWidth = matchesOnMediumDevice ? 300 : chartSmallWidth;

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const icons = [
        { value: 'left', label: 'Align Left', component: <img src={ACTIVE_ENGAGEMENT} alt="circle" /> },
        { value: 'right', label: 'Align Right', component: <img src={GRAPH_ICON} alt="circle" /> },
    ];

    const onPieEnter = useCallback((_, index) => {
        setActiveIndex(index);
    }, []);

    return (
        <Grid container direction="column" gap="20px" sx={{ background: 'white', p: '20px', borderRadius: '20px' }}>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item flexGrow={1}>
                        <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Engagement</Typography>
                    </Grid>
                    <Grid item>
                        <IconToggleButtonGroup icons={icons} value={alignment} onChange={handleAlignment} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ColorLegend data={data}/>
            </Grid>
            <Grid item>
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={80}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default Engagement;

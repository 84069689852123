import { put, retry, call } from 'redux-saga/effects';
import {
    setUser,
    resetState,
    setIsEmailSent,
    setPasswordReset,
    setConfirmEmail
} from '../../slices/user.slice';
import { setUserLogOut } from '../../slices/logout.slice';
import { showSnackBar } from '../../slices/snackbar.slice';
import { setShowLoading } from '../../slices/global.slice';
import {
    requestSignin,
    registerUser,
    requestLogout,
    forgotPassword,
    resetPassword,
    confirmEmail,
    getProfileRequest,
    updateProfileRequest,
} from '../requests/user.request';

export function* handleSignin(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const response = yield retry(0, 0, requestSignin, action.payload);
        const { data } = response;
        console.log('Sign In API response >>', data);
        if (data.status && data.status === 1) {
            const userData = data.user;
            localStorage.setItem('accessToken', data.auth);
            localStorage.setItem('userName', userData.name);
            localStorage.setItem('userImageUrl', userData.image_url);
            localStorage.setItem('userAddress', userData.address);
            yield put(setUser(userData));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
            console.log(data);
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        const snackbarObject = {
            type: 'error',
            message: 'Login Failed | Wrong Credentials',
            open: true,
        };

        yield put(showSnackBar(snackbarObject));
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}
export function* handleRegister(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const response = yield retry(0, 0, registerUser, action.payload);
        const { data } = response;
        console.log('Register API response >>', data);
        if (data.status === 1) {
            const userData = data.user;
            // login user after success registration
            // localStorage.setItem('accessToken', data.auth);
            // localStorage.setItem('userName', userData.name);
            // localStorage.setItem('userImageUrl', userData.image_url);
            yield put(setUser(userData));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
            console.log(data.message);
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        console.log('register user catch error');
        const snackbarObject = {
            type: 'error',
            message: 'Register Failed',
            open: true,
        };

        yield put(showSnackBar(snackbarObject));
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleForgotEmail(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const response = yield retry(0, 0, forgotPassword, action.payload);
        console.log('🚀 ~ file: user.handler.js ~ line 153 ~ handleForgotPassword', response);

        yield put(setIsEmailSent({ emailSent: true }));
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setIsEmailSent({ emailSent: false }));
        yield put(setShowLoading({ showLoading: false }));

        console.log('Forgot Password API Call failed ::', error);
    }
}

export function* handleResetEmail(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const response = yield retry(0, 0, resetPassword, action.payload);

        yield put(setPasswordReset({ resetPassword: true }));
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setPasswordReset({ resetPassword: false }));
        yield put(setShowLoading({ showLoading: false }));
        console.log('Forgot Password API Call failed ::', error);
    }
}

export function* handleConfirmEmail(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));
        const response = yield retry(0, 0, confirmEmail, action.payload);

        const { data } = response;

        if (data.status === 1) {
            yield put(setConfirmEmail({ isEmailConfirmed: true }));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }

        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setConfirmEmail({ isEmailConfirmed: false }));
        yield put(setShowLoading({ showLoading: false }));
        const snackbarObject = {
            type: 'error',
            message: 'Email confirmation failed',
            open: true,
        };

        yield put(showSnackBar(snackbarObject));
        console.log('Confirm Email API Call failed ::', error);
    }
}
export function* handleLogout(action) {
    try {
        console.log(action.payload);
        const accessToken = JSON.parse(localStorage.getItem('accessToken'));
        const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));

        console.log('Access token in Logout API ===> ', accessToken);
        console.log('Refresh token in Logout API ===> ', refreshToken);

        const data = {
            accessToken,
            refreshToken,
        };

        const response = yield retry(0, 0, requestLogout, data);
        console.log('🚀 ~ file: user.handler.js ~ line 91 ~ function*handleLogout ~ response', response);
        yield put(resetState());
        yield put(setUserLogOut({ userLogOut: true }));
    } catch (error) {
        yield put(setUserLogOut({ userLogOut: false }));
        console.log(error);
    }
}

export function* handleGetProfile(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getProfileRequest, { accessToken, id: action.payload });
        console.log('🚀 ~ file: user.handler.js ~ line 218 ~ handleGetProfile ~ response', response);

        const { data } = response;

        const userData = data.data;
        if (userData) {
            console.log('userdata', userData);
            yield put(setUser(userData));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleUpdateProfile(action) {
    try {
        console.log('action payload', action.payload);
        yield put(setShowLoading({ showLoading: true }));
        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(updateProfileRequest, { accessToken: accessToken, form: action.payload });
        console.log('🚀 ~ file: user.handler.js ~ line 235 ~ handleUpdateProfile ~ response', response);

        const { data } = response;

        if (data.status === 1) {
            const userData = data.data
            localStorage.setItem('userName', userData.name);
            localStorage.setItem('userImageUrl', userData.image_url);
            localStorage.setItem('userAddress', userData.address);
            yield put(setUser(userData));
            const snackbarObject = {
                type: 'success',
                message: 'Venue Updated Successfully',
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message,
                open: true,
            };

            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ChatUI from './ChatUI';
import { InboxUsers } from './InboxUsers';
import { useDispatch, useSelector } from 'react-redux';
import { getThreads, getThreadMessages, sendMessage } from '../../store/slices/chat.slice';
import { useParams } from 'react-router-dom';

const ChatContainer = () => {
    const dispatch = useDispatch();
    const [selectedThread, setSelectedThread] = useState(null);
    const { id } = useParams();
    const [defaultSelected, setDefaultSelected] = useState(false)

    useEffect(() => {
        dispatch(getThreads());
    }, []);

    const handleChatSelected = (threadId) => {
        const selectedThread = threads.find((thread) => thread.thread_id == threadId);
        if (selectedThread) {
            setSelectedThread(selectedThread);
            dispatch(getThreadMessages(threadId));
        }
    };

    const handleMessageSend = (data) => {
        dispatch(sendMessage(data));
        dispatch(getThreadMessages(selectedThread.thread_id));
    };

    const { threads, threadMessages } = useSelector((state) => state.chat);

    useEffect(() => {
        if (!defaultSelected && id > 0 && threads.length > 0) {
            setTimeout(() => {
                handleChatSelected(id)
                setDefaultSelected(true)    
            }, 1.0);
        }
    }, [threads])

    return (
        <Grid container spacing={'20px'}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
                <InboxUsers threads={threads} handleChatSelected={handleChatSelected} />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={9} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <ChatUI
                    selectedThread={selectedThread}
                    threadMessages={threadMessages}
                    handleMessageSend={handleMessageSend}
                />
            </Grid>
        </Grid>
    );
};

export default ChatContainer;

import { Box } from '@mui/material';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericTabs from '../../components/General/GenericTabs/GenericTabs';
import { getPackagesByRange, setIsDone as setPackageIsDone } from '../../store/slices/package.slice';
import { getPromotionsByRange, setIsDone as setPromotionIsDone } from '../../store/slices/promotion.slice';
import EventPackages from './EventPackages';
import Promotions from './Promotions';

export const Manage = (props) => {
    dayjs.extend(utcPlugin);
    const {
        rangeActivePromotions,
        rangePastPromotions,
        rangeUpComingPromotions,
        isApiDone: isPromotionApiDone,
    } = useSelector((state) => state.promotions);

    const {
        rangeActivePackages,
        rangePastPackages,
        rangeUpComingPackages,
        isApiDone: isPackageApiDone,
    } = useSelector((state) => state.packages);

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [dateRange, setDateRange] = useState({ fromTimeStamp: null, toTimeStamp: null });
    const [promotionsList, setPromotionsList] = useState({
        active: rangeActivePromotions,
        past: rangePastPromotions,
        upComing: rangeUpComingPromotions,
    });

    const [packagesList, setPackagesList] = useState({
        active: rangeActivePackages,
        past: rangePastPackages,
        upComing: rangeUpComingPackages,
    });
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const handleChangeDate = (e, key) => {
        key === 'startDate' ? setStartDateTime(e.target.value) : setEndDateTime(e.target.value);
    };
    const handleChange = (event, newValue) => {
        setStartDateTime('');
        setEndDateTime('');
        setSelectedFilter('');
        setDateRange({ fromTimeStamp: null, toTimeStamp: null });
        setActiveTab(newValue);
    };

    useEffect(() => {
        setDateRange({
            fromTimeStamp: startDateTime,
            toTimeStamp: endDateTime,
        });
    }, [startDateTime, endDateTime]);

    useEffect(() => {
        setPromotionsList({
            active: rangeActivePromotions,
            past: rangePastPromotions,
            upComing: rangeUpComingPromotions,
        });
    }, [rangeActivePromotions, rangePastPromotions, rangeUpComingPromotions]);

    useEffect(() => {
        setPackagesList({
            active: rangeActivePackages,
            past: rangePastPackages,
            upComing: rangeUpComingPackages,
        });
    }, [rangeActivePackages, rangePastPackages, rangeUpComingPackages]);

    useEffect(() => {
        if (activeTab === 0) {
            dispatch(getPromotionsByRange(dateRange));
        } else {
            dispatch(getPackagesByRange(dateRange));
        }
    }, [isPromotionApiDone, dateRange, isPackageApiDone, activeTab]);

    useEffect(() => {
        dispatch(setPackageIsDone(false));
        dispatch(setPromotionIsDone(false));
    }, []);

    const handleRangeSelection = (e) => {
        const id = e.target.value;
        setSelectedFilter(id == 4 ? 'custom' : '');
        let startDate = null;
        let endDate = null;
        if (id === 1) {
            startDate = dayjs().startOf('day');
            endDate = dayjs().endOf('day');
        } else if (id === 2) {
            startDate = dayjs().startOf('week');
            endDate = dayjs().endOf('week');
        } else if (id === 3) {
            startDate = dayjs().startOf('month');
            endDate = dayjs().endOf('month');
        } else if (id === 4) {
            startDate = startDateTime;
            endDate = endDateTime;
        }
        setDateRange({
            fromTimeStamp: startDate,
            toTimeStamp: endDate,
        });
    };
    return (
        <Fragment>
            <GenericTabs
                active={activeTab}
                handleChange={handleChange}
                firstTab={<Box>Promotions</Box>}
                secondTab={<Box>Event Packages</Box>}
            />

            {activeTab === 0 && (
                <Box className="panel-body">
                    <Promotions
                        promotionsList={promotionsList}
                        handleRangeSelection={handleRangeSelection}
                        selectedDate={selectedFilter}
                        handleChangeDate={handleChangeDate}
                    />
                </Box>
            )}
            {activeTab === 1 && (
                <Box className="panel-body">
                    <EventPackages
                        handleRangeSelection={handleRangeSelection}
                        selectedDate={selectedFilter}
                        handleChangeDate={handleChangeDate}
                        packagesList={packagesList}
                    />
                </Box>
            )}
        </Fragment>
    );
};

import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './sagas/rootSaga';
import userReducer from './slices/user.slice';
import snackbarReducer from './slices/snackbar.slice';
import questionsReducer from './slices/questions.slice';
import logoutReducer from './slices/logout.slice';
import promotionReducer from './slices/promotion.slice';
import packageReducer from './slices/package.slice';
import globalReducer from './slices/global.slice';
import analyzeReducer from './slices/analyze.slice';
import chatReducer from './slices/chat.slice';
import walletReducer from './slices/wallet.slice';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
    user: userReducer,
    promotions: promotionReducer,
    packages: packageReducer,
    snackbar: snackbarReducer,
    questions: questionsReducer,
    logout: logoutReducer,
    global: globalReducer,
    analyze: analyzeReducer,
    chat: chatReducer,
    wallet: walletReducer
});

const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
});
sagaMiddleware.run(watcherSaga);

export default store;

import React, { useState } from 'react';
import { Modal, Box, IconButton, Grid, Avatar, Stack, TextField, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { APP_CONSTANTS } from '../../config/config';
import SendIcon from '@mui/icons-material/Send';

const styles = {
    mainContainer: {
        width: '40%',
        marginLeft: '30%',
        marginTop: '12vh',
        borderRadius: '20px',
        backgroundColor: '#fff',
        border: 'none'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px'
    },
    headerTitle: {
        fontSize: '19px',
        fontWeight: 600,
        color: '#1D2939',
        flex: 1,
        textAlign: 'center'
    },
    contentContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        padding: '0px 80px'
    },
    sendInstructionText: {
        color: '#1D2939',
        fontSize: '15px',
        fontWeight: 400
    },
    avatarsContainer: { 
        display: 'flex', 
        flexWrap: 'nowrap',
        marginTop: '4px'
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0px'
    }
}

const SendDrinkModal = (props) => {
    const {isOpen, handleClose, list, handleSend} = props
    const { APP_BASE_URL } = APP_CONSTANTS;

    const [expiryDate, setExpiryDate] = useState('')
    const [expiryTime, setExpiryTime] = useState('')
    const [description, setDescription] = useState('')

    return (
        <Grid>
            <Modal open={isOpen} onClose={handleClose}>
                <Box style={styles.mainContainer}>
                    <Box style={styles.headerContainer}>
                        <span style={styles.headerTitle}>Send free drinks</span>
                        <IconButton onClick={handleClose}>
                            <ClearIcon />
                        </IconButton>
                    </Box>

                    <Box style={styles.contentContainer}>
                        <span style={styles.sendInstructionText}>{`You're about to send free drinks to ${list.length == 1 ? '1 person' : list.length + ' people'}:`}</span>

                        <Box sx={styles.avatarsContainer}>
                            <Stack direction="row" spacing={1}>
                                {list?.map((item, index) => {
                                    return (
                                        <Avatar
                                            id={`avatar-${index}`}
                                            key={index}
                                            alt="Remy Sharp"
                                            src={APP_BASE_URL + item.image_url}
                                        />
                                    );
                                })}
                            </Stack>
                        </Box>

                        <Box style={{marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Box style={{flex: 1, marginRight: '20px'}}>
                                <span style={styles.sendInstructionText}>Expiration Date</span>
                                <TextField
                                    name="expiryDate"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                    placeholder="MM/DD/YYYY"
                                    type="date"
                                    fullWidth
                                    style={{backgroundColor: '#fff', marginTop: '4px'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>

                            <Box style={{flex: 1}}>
                                <span style={styles.sendInstructionText}>Expiration Time</span>
                                <TextField
                                    name="expiryTime"
                                    value={expiryTime}
                                    onChange={(e) => setExpiryTime(e.target.value)}
                                    placeholder="HH:mm a"
                                    type="time"
                                    fullWidth
                                    style={{backgroundColor: '#fff', marginTop: '4px'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box style={{marginTop: '20px'}}>
                            <span style={styles.sendInstructionText}>Enter description, terms and exclusions</span>
                            <TextField
                                name="description"
                                multiline
                                rows={5}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter description"
                                type="text"
                                fullWidth
                                style={{backgroundColor: '#fff', marginTop: '4px'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={styles.footerContainer}>
                        <Button
                            size="medium"
                            color="primary"
                            variant="contained"
                            startIcon={<SendIcon />}
                            sx={{
                                width: '103px',
                                color: 'white',
                                background: '#7F56D9',
                                p: '8px 16px',
                                borderRadius: '8px',
                                textTransform: 'inherit',
                            }}
                            onClick={() => handleSend(expiryDate, expiryTime, description)}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    );
};

export default SendDrinkModal;
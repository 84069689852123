import { createSlice } from '@reduxjs/toolkit';

const ChatSlice = createSlice({
    name: 'chat',
    initialState: {
        threads: [],
        threadMessages: [],
        isApiDone: false,
    },
    reducers: {
        getThreads(state, action) {},
        setThreads(state, action) {
            state.threads = action.payload;
            state.threadMessages = [];
        },
        getThreadMessages(state, action) {},
        setThreadMessages(state, action) {
            state.threadMessages = action.payload;
        },
        sendMessage(state, action) {},
        setIsDone(state, action) {
            state.isApiDone = action.payload.isApiDone;
        },
    },
});

export const { getThreads, setThreads, getThreadMessages, setThreadMessages, sendMessage, setIsDone } =
    ChatSlice.actions;

export default ChatSlice.reducer;

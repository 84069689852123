import { Grid } from '@mui/material';
import React from 'react';
import useDebouncedSearch from '../../components/General/DebounceSearch.jsx'; // Import the custom hook
import { ActiveOffers, PastOffers, SearchFilter, UpcomingOffers } from '../../components/Manage';
import { useState } from 'react';

function Promotions(props) {
    const { promotionsList, handleRangeSelection, selectedDate, handleChangeDate } = props;
    const initialData = {
        upComingOffers: promotionsList.upComing,
        activeOffers: promotionsList.active,
        pastOffers: promotionsList.past,
    };
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebouncedSearch(searchValue, 500);

    const filteredUpComingPromotions = initialData.upComingOffers.filter((promotion) =>
        promotion.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    const filteredActivePromotions = initialData.activeOffers.filter((promotion) =>
        promotion.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    const filteredPastPromotions = initialData.pastOffers.filter((promotion) =>
        promotion.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );

    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SearchFilter
                    handleChangeSearch={handleChangeSearch}
                    handleChangeDate={handleChangeDate}
                    handleRangeSelection={handleRangeSelection}
                    selectedDate={selectedDate}
                    showSearch={true}
                />
            </Grid>
            <Grid item xs={12}>
                <UpcomingOffers upComingOffers={filteredUpComingPromotions} />
            </Grid>
            <Grid item xs={12}>
                <ActiveOffers activeOffers={filteredActivePromotions} />
            </Grid>
            <Grid item xs={12}>
                <PastOffers pastOffers={filteredPastPromotions} />
            </Grid>
        </Grid>
    );
}

export default Promotions;

import axios from 'axios';
import { API_CONSTANTS } from '../../../config/config';
const { API_URL } = API_CONSTANTS;

export function getThreadsRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/chat/getVenueThreads`,
        headers: {
            Authorization: data.accessToken,
        },
    };

    return axios(config);
}

export function getThreadMessagesRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/chat/getMessages`,
        headers: {
            Authorization: data.accessToken,
        },
        data: { threadId: data.threadId, type: 'venue' },
    };

    return axios(config);
}

export function sendMessageRequest(data) {
    var config = {
        method: 'post',
        url: `${API_URL}v1/chat/sendVenueMessage`,
        headers: {
            Authorization: data.accessToken,
        },
        data: data.message,
    };

    return axios(config);
}

import { Box, Grid, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { datesOptions } from '../../config/constants';
import SearchBar from '../General/SearchBar';

export const SearchFilter = (props) => {
    const { selectedDate, handleChangeDate, endDate, startDate } = props;

    return (
        <Grid container spacing={2}>
            {props.showSearch && (
                <Grid item xs={12} md={8}>
                    <SearchBar
                        placeholder="Search"
                        type="Search"
                        isIconRequired={false}
                        handleChangeSearch={props.handleChangeSearch}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={props.showSearch ? 4 : 12}>
                <Box className="flex-fill search-main-div">
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        className="select-dropdown"
                        onChange={props.handleRangeSelection}
                        defaultValue={datesOptions[4]?.id} // Set the default value to the first item's id
                    >
                        {datesOptions?.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            </Grid>
            {selectedDate === 'custom' && (
                <Grid item xs={12}>
                    <Grid container spacing={2} display="flex" justifyContent={'flex-end'}>
                        <Grid item>
                            <TextField
                                name="endTimeStamp"
                                value={endDate}
                                onChange={(e) => handleChangeDate(e, 'startDate')}
                                placeholder="MM/DD/YYYY"
                                type="date"
                                fullWidth
                                label="From"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="startTimeStamp"
                                value={startDate}
                                onChange={(e) => handleChangeDate(e, 'endDate')}
                                placeholder="MM/DD/YYYY"
                                type="date"
                                label="To"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

import { Grid, Typography, useMediaQuery } from '@mui/material';
// import Container from '@mui/material/Container';

export default function Promotion(props) {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <div className="App">
            <Grid container spacing="30px">
                <Grid item xs={12}>
                    <Grid container direction="column" sx={{ position: 'relative' }}>
                        {matches ? (
                            <Grid item xs={6} className="promotion-img-container">
                                <img src={props.promotionImg} alt="promo" className="promotion-img " />
                            </Grid>
                        ) : null}

                        <Grid
                            xs={6}
                            item
                            sx={{
                                p: matches ? '80px 30px 30px' : '16px',
                                textAlign: matches ? 'center' : 'left',
                                borderRadius: matches ? '60px 60px 20px 20px' : '16px',
                                background: 'linear-gradient(180deg, #9E77ED 0%, #53389E 100%)',
                            }}
                        >
                            <Grid container spacing="16px">
                                {matches ? null : (
                                    <Grid item xs={3}>
                                        <img
                                            src={props.promotionImg}
                                            alt="promo"
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={matches ? 12 : 9}>
                                    <Typography className="promotion-name">{props.promotionName}</Typography>
                                    <Typography className="promotion-detail">{props.promotionDetail}</Typography>
                                    {props.promotionAction}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

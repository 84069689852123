import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SPILOGO from '../../assets/images/SpiLogo.svg';
import {MOBILE_APPS_CONSTANTS} from "../../config/config";

const UniversalLink = () => {
    const [ isPlatformReady, setIsPlatformReady ] = useState(false);

    useEffect(() => {
        const os = getMobileOperatingSystem();

        if (os === "ios") {
            window.location.href = MOBILE_APPS_CONSTANTS.APP_STORE_LINK;

            return;
        }

        if (os === "android") {
            window.location.href = MOBILE_APPS_CONSTANTS.PLAY_STORE_LINK;

            return;
        }

        setIsPlatformReady(true)
    }, [])

    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return "unknown";
    }

    if (!isPlatformReady) {
        return (<div />);
    }

    return (
        <>
            <Grid container justifyContent="center" sx={{ height: '100vh' }} p={2}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={4} sx={{ margin: 'auto' }}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            pb={3}
                        >
                            <img src={SPILOGO} alt="SPI" className="AppLogo" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            mb={2}
                        >
                            <Typography component="h3" variant="h5" align="center">
                                Join SipSender!
                            </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          mb={2}
                        >
                            <Typography component="p" variant="body2" align="center">
                                Download the SipSender APP for your mobile device
                            </Typography>
                        </Grid>
                        <Grid container spacing={2} pt={2}>
                            <Grid item md={6} xs={12} display="flex" justifyContent={'center'}>
                                <Button
                                  type="button"
                                  className="button-primary FllWidthBtn"
                                  variant="contained"
                                  onClick={() => window.location.href = MOBILE_APPS_CONSTANTS.APP_STORE_LINK}
                                >
                                    IOS platform
                                </Button>
                            </Grid>
                            <Grid item md={6} xs={12} display="flex" justifyContent={'center'}>
                                <Button
                                  type="button"
                                  className="button-primary FllWidthBtn"
                                  variant="contained"
                                  onClick={() => window.location.href = MOBILE_APPS_CONSTANTS.PLAY_STORE_LINK}
                                >
                                    Android Platform
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default UniversalLink;

import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Divider,
} from '@mui/material';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { uploadImage } from '../../store/sagas/requests/image.request.js';
import {
    createNewPromotion,
    getPromotionDetail,
    getPromotionTypes,
    updatePromotion,
} from '../../store/slices/promotion.slice';
import { showSnackBar } from '../../store/slices/snackbar.slice';

import { isEmpty, isUndefined } from 'lodash';
import Frequency from '../../components/Frequency/Frequency';
import CustomButton from '../../components/PromotionPackageButton/CustomButton';
import { APP_CONSTANTS } from '../../config/config';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import SHARE_ICON from '../../assets/images/share.png';
import TICKET_ICON from '../../assets/images/ticket.png';

const isUndefinedOrEmpty = (string) => isUndefined(string) || isEmpty(string);

const styles = {
    container: {
        width: '312px',
        padding: '20px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
    },
    topContainer: {
        height: '280px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: '#fff',
        borderRadius: '20px 20px 0 0',
    },
    topHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 20px 10px 20px'
    },
    topInternalContainer: {
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,5)',
        padding: '20px 20px 40px 20px'
    },
    iconImage: {
        width: '20px',
        height: '20px'
    },
    boldText: {
        fontSize: 17, 
        fontWeight: 'bold'
    },
    bottomContainer: {
        backgroundColor: '#f0f0f0',
        padding: '40px 10px 10px 10px',
        borderRadius: '0 0 20px 20px',
    },
    smallText: {
        fontSize: 13, 
    },
    descriptionContainer: {
        background: '#EEE9F5',
        borderRadius: '10px',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '250px 10px 0 10px',
        position: 'absolute',
        width: '252px',
        height: '40px'
    },
    description: {
        color: '#000'
    },
    button: {
        background: '#5B2A9E',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        padding: '6px 10px',
        height: '20px',
    },
    sectionContainer: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '10px',
        margin: '0 0 12px 0'
    },
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#000',
    },
    sectionInternalContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    venueImage: {
        width: '80px',
        height: '80px',
        borderRadius: '6px',
        margin: '0 6px 0 0'
    },
    nameText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000'
    },
    addressText: {
        fontSize: 13,
        color: '#475467'
    },
    mapText: {
        fontSize: 13,
        color: '#5B2A9E',
        fontWeight: 'bold',
    },
    friendItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 6px 0 0'
    },
    friendImage: {
        width: 46,
        height: 46,
        borderRadius: 23,
        margin: '0 0 2px 0'
    },
    text: {
        fontSize: 13,
        color: '#000'
    },
    titleContainer: {
        margin: '0 0 12px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    seeAllText: {
        fontSize: 13,
        color: '#5B2A9E',
        margin: '0 2px 0 0'
    },
    countText: {
        color: '#5B2A9E',
        fontSize: 17,
        fontWeight: 'bold',
        margin: '0 0 0 2px'
    },
    seeAllContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
};

const PromoPreview = (props) => {
    const {event, isPromo} = props

    const HeaderView = (event) => {
        const {title, day, time, totalClaims, claimsCount} = event
        const claimsTitle = totalClaims == null || totalClaims == '' ? (claimsCount == 0 ? 'Unlimited' : `${claimsCount}/∞`) : `${claimsCount}/${totalClaims}`
        const topContainerStyle = {...styles.topContainer,  backgroundImage: 'url("' + event.imageUrl + '")',}

        return (
            <div style={topContainerStyle}>
                <div style={styles.topHeaderContainer}>
                    <ChevronLeftIcon style={{ color: '#fff', marginRight: '2px' }}/>
                    <span style={styles.smallText}>Back</span>
                    <span style={{flex: 1}}/>
                    <img src={SHARE_ICON} style={styles.iconImage}/>
                </div>
                <div style={styles.topInternalContainer}>
                    <span style={styles.boldText}>{title}</span>
                    <br/>
                    <span style={styles.smallText}>{day}</span>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={styles.smallText}>{time}</span>
                        <span style={{flex: 1}}/>
                        <img src={TICKET_ICON} style={styles.iconImage}/>
                        <span style={{fontSize: 13, marginLeft: '2px'}}>{claimsTitle}</span>
                    </div>
                </div>
            </div>
        )
    }

    const DescriptionView = (event, isPromo) => {
        const {description} = event
        
        return (
            <Paper style={styles.descriptionContainer} elevation={3}>
                <div style={styles.description}>
                    <span style={styles.smallText}>{description}</span><br/>
                </div>
                <div style={styles.button}>
                    <span style={{fontSize: 13}}>{'Claim ' + (isPromo ? 'Offer' : 'Package')}</span>
                </div>
            </Paper>
        )
    }

    const VenueView = (venue) => {
        return (
            <Paper style={styles.sectionContainer}>
                <div style={styles.titleContainer}>
                    <span style={styles.title}>Venue</span>
                </div>
                <div style={styles.sectionInternalContainer}>
                    <img src={venue.imageUrl} style={styles.venueImage}/>
                    <div>
                        <span style={styles.nameText}>{venue.name}</span><br/>
                        <span style={styles.addressText}>{venue.address}</span><br/>
                        <span style={styles.mapText}>See map</span>
                    </div>
                </div>
            </Paper>
        )
    }

    const FriendItemView = (friend) => {
        return (
            <div style={styles.friendItemContainer}>
                <img src={friend.imageUrl} style={styles.friendImage}/>
                <span style={styles.text}>{friend.name}</span>
            </div>
        )
    }

    const FriendsView = (friends) => {
        return (
            <Paper style={styles.sectionContainer}>
                <div style={styles.titleContainer}>
                    <div>
                        <span style={styles.title}>Friends attending</span>
                        <span style={styles.countText}>{friends.length}</span>
                    </div>
                    <div style={styles.seeAllContainer}>
                        <span style={styles.seeAllText}>See all</span>
                        <ArrowForwardIcon style={{ color: '#5B2A9E' }}/>
                    </div>
                    
                </div>
                <div style={styles.sectionInternalContainer}>
                    {friends.map(friend => FriendItemView(friend))}
                </div>
            </Paper>
        )
    }

    const AboutView = (event, isPromo) => {
        const {about} = event
        if (isUndefinedOrEmpty(about)) {
            return null
        }
        const title = isPromo ? 'About Event' : 'About Event Package'
        return (
            <Paper style={styles.sectionContainer}>
                <div style={styles.titleContainer}>
                    <span style={styles.title}>{title}</span>
                </div>
                <div style={styles.sectionInternalContainer}>
                    <span style={styles.text}>{about}</span>
                </div>
            </Paper>
        )
    }

    const RulesView = (event) => {
        const {rules} = event
        if (isUndefinedOrEmpty(rules)) {
            return null
        }
        return (
            <Paper style={styles.sectionContainer}>
                <div style={styles.titleContainer}>
                    <span style={styles.title}>Policies & Rules</span>
                </div>
                <div style={styles.sectionInternalContainer}>
                    <span style={styles.text}>{rules}</span>
                </div>
            </Paper>
        )
    }
    
    return (
            <Grid container xs={12} justifyContent="center">
                <Grid item>
                    <Box width={300} style={styles.container}>
                        {DescriptionView(event, isPromo)}
                        {HeaderView(event)}
                        <div style={styles.bottomContainer}>
                            {VenueView(event.venue)}
                            {FriendsView(event.friends)}
                            {AboutView(event, isPromo)}
                            {RulesView(event)}
                        </div>
                    </Box>
                </Grid>
            </Grid>
    )
}

export default PromoPreview;
import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { APP_CONSTANTS } from '../../config/config';
import OffersActivity from '../Offers/OffersActivity';

export const PastOffers = (props) => {
    const { pastOffers } = props;
    const { APP_BASE_URL } = APP_CONSTANTS;
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pb: 2, gap: '5px' }}>
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    sx={{
                        color: '#7F56D9',
                        '&.Mui-checked': {
                            color: '#7F56D9', // Change the color to the desired color
                        },
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '17px' }}>
                    Past Offers
                </Typography>
            </Box>

            <div style={{ overflowX: 'auto' }}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {pastOffers?.map((promotion) => (
                        <div key={promotion.id} style={{ minWidth: '230px', maxWidth: '230px' }}>
                            <OffersActivity
                                dateType={'date,year'}
                                key={promotion.id}
                                title={promotion.title}
                                date={promotion.start_timestamp}
                                subTitle={promotion.description}
                                backgroundImageURL={APP_BASE_URL + promotion.cover_image_url}
                                badgeClass="bg-grey"
                            />
                        </div>
                    ))}
                </Box>
            </div>
        </Box>
    );
};

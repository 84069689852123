import { call, put } from 'redux-saga/effects';
import { getStats, getStatsActive, getStatsClaimed, getStatsDrinks, getStatsUpcoming } from '../requests/analyze.request';
import { setStats, setEngagement, setAttendees, setStatDetails } from '../../slices/analyze.slice';
import { setShowLoading } from '../../slices/global.slice';
import { showSnackBar } from '../../slices/snackbar.slice';
import giftGrey from '../../../assets/images/gift-grey.svg';
import giftGreen from '../../../assets/images/gift-grey.svg';
import notificationGreen from '../../../assets/images/notification-status-green.svg';
import peopleIcon from '../../../assets/images/people.svg';
import userIcon from '../../../assets/images/user-tick.svg';
import notificationGrey from '../../../assets/images/notification-status-grey.svg';
import dayjs from 'dayjs';

// Mapping object for body text
const bodyTextMapping = {
    attendees: 'Attendees',
    drinks: 'Drinks Gifted',
    engagement: 'Engagement',
    claimed: 'Claimed Offers',
    promoActiveCount: 'Active Promotions',
    promoUpcomingCount: 'Upcoming Promotions',
    eventActiveCount: 'Active Events',
    eventUpcomingCount: 'Upcoming Events',
};

const typeMapping = {
    attendees: 'attendees',
    drinks: 'drinks',
    engagement: 'engagement',
    claimed: 'claimed',
    promoActiveCount: 'active',
    promoUpcomingCount: 'upcoming',
    eventActiveCount: 'active',
    eventUpcomingCount: 'upcoming',
};

// Mapping object for images
const imagesMapping = {
    attendees: peopleIcon,
    drinks: giftGreen,
    engagement: userIcon,
    claimed: giftGrey,
    promoActiveCount: notificationGreen,
    promoUpcomingCount: notificationGrey,
    eventActiveCount: notificationGreen,
    eventUpcomingCount: notificationGrey,
};

export function* handleGetStats(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getStats, { accessToken, form: action.payload });

        const { data } = response;

        const stats = data.count;
        const engagment = data.engagment
        const attendees = data.attendees
        if (data.status === 1) {
            const updatedCounts = {
                ...stats,
                engagement: engagmentTotal(engagment),
                attendees: attendeesTotal(attendees)
            }
            const newDataset = createNewDataset(updatedCounts, action.payload.filter);
            yield put(setStats(newDataset));

            const updatedEngagment = mapEngagment(engagment)
            yield put(setEngagement(updatedEngagment));

            const updatedAttendees = mapAttendees(attendees)
            yield put(setAttendees(updatedAttendees));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function engagmentTotal(engagment) {
    return parseInt(engagment.promoViews) 
        + parseInt(engagment.promoShares) 
        + parseInt(engagment.packageViews)
        + parseInt(engagment.packageShares)
}

export function attendeesTotal(attendees) {
    const mapCallback = (item => (item.first_time_user + item.returning_user))
    return attendees.promotions.map(mapCallback).reduce((prev, curr) => (prev + curr), 0) 
    + attendees.packages.map(mapCallback).reduce((prev, curr) => (prev + curr), 0)        
}

function getRandomChipPercent() {
    return Math.random() < 0.5 ? '10%' : '20%';
}

function mapEngagment(engagment) {
    return [
        { name: 'Promo Views', value: parseInt(engagment.promoViews) + parseInt(engagment.packageViews) },
        { name: 'Social Shares', value: parseInt(engagment.promoShares) + parseInt(engagment.packageShares) },
    ];
}

function mapAttendees(attendees) {
    const promotions = attendees.promotions.map(item => ({
        ...item,
        total: item.first_time_user + item.returning_user,
        referred: 0,
        title: dayjs(item.date).format('MM/DD')
    }))
    const packages = attendees.packages.map(item => ({
        ...item,
        total: item.first_time_user + item.returning_user,
        referred: 0,
        title: dayjs(item.date).format('MM/DD')
    }))
    
    const map = new Map()
    const mapItem = (item => {
        var prev = map.get(item.title)
        if (prev) {
            prev.total = prev.total + item.total
            prev.first_time_user = prev.first_time_user + item.first_time_user
            prev.returning_user = prev.returning_user + item.returning_user
            prev.referred = prev.referred + item.referred
        } else {
            prev = item
        }
        map.set(prev.title, prev)
    })
    promotions.forEach(mapItem);
    packages.forEach(mapItem);
    return Array.from(map.values())
}

export function createNewDataset(stats, filter) {
    const dataset = [];
    let activeEventsOffersAdded = false;
    let upcomingEventsOffersAdded = false;

    for (const key in stats) {
        const value = stats[key];
        let bodyText = bodyTextMapping[key] || key;
        let type = typeMapping[key] || key;
        let count = parseInt(value);
        let chipPercent = getRandomChipPercent();
        let increment = Math.random() < 0.5;

        const suffix = filter == 'All' ? 'Offers & Events' : filter == 'All Offers' ? 'Offers' : 'Events' 
        if (key === 'promoActiveCount' || key === 'eventActiveCount') {
            count = parseInt(stats['promoActiveCount']) + parseInt(stats['eventActiveCount']);
            bodyText = `Active ${suffix}`;
        } else if (key === 'promoUpcomingCount' || key === 'eventUpcomingCount') {
            count = parseInt(stats['promoUpcomingCount']) + parseInt(stats['eventUpcomingCount']);
            bodyText = `Upcoming ${suffix}`;
        } else if (key === 'claimed') {
            bodyText = `Claimed ${suffix}`
        }

        if (
            filter == 'All' &&
            (key === 'promoActiveCount' ||
                key === 'eventActiveCount' ||
                key === 'promoUpcomingCount' ||
                key === 'eventUpcomingCount')
        ) {
            if (!activeEventsOffersAdded && (key === 'promoActiveCount' || key === 'eventActiveCount')) {
                activeEventsOffersAdded = true;
                dataset.push({
                    id: dataset.length + 1,
                    image: imagesMapping[key],
                    count,
                    chipPercent,
                    bodyText,
                    increment,
                    type
                });
            }
            if (!upcomingEventsOffersAdded && (key === 'promoUpcomingCount' || key === 'eventUpcomingCount')) {
                upcomingEventsOffersAdded = true;
                dataset.push({
                    id: dataset.length + 1,
                    image: imagesMapping[key],
                    count,
                    chipPercent,
                    bodyText,
                    increment,
                    type
                });
            }
        } else {
            if (filter !== 'All') {
                if (filter == 'All Events' && key !== 'promoActiveCount' && key !== 'promoUpcomingCount') {
                    dataset.push({
                        id: dataset.length + 1,
                        image: imagesMapping[key],
                        count,
                        chipPercent,
                        bodyText,
                        increment,
                        type
                    });
                } else if (filter == 'All Offers' && key !== 'eventActiveCount' && key !== 'eventUpcomingCount') {
                    dataset.push({
                        id: dataset.length + 1,
                        image: imagesMapping[key],
                        count,
                        chipPercent,
                        bodyText,
                        increment,
                        type
                    });
                }
            } else {
                dataset.push({
                    id: dataset.length + 1,
                    image: imagesMapping[key],
                    count,
                    chipPercent,
                    bodyText,
                    increment,
                    type
                });
            }
        }
    }

    return dataset;
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const mapDetails = (data, type, filter) => {
    const promos = {
        data: data.promos,
        title: 'Offers'
    }
    const packages = {
        data: data.packages,
        title: 'Events'
    }

    let details
    if (filter == 'All') {
        details = {
            type,
            title: `${capitalize(type)} Offers & Events Insights`,
            list1: promos,
            list2: packages
        }
    } else if (filter == 'All Offers') {
        details = {
            type,
            title: `${capitalize(type)} Offers Insights`,
            list1: promos
        }
    } else {
        details = {
            type,
            title: `${capitalize(type)} Events Insights`,
            list1: packages
        }
    }
    return details
}

export function* handleGetStatsClaimed(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getStatsClaimed, { accessToken, form: action.payload });

        const { data } = response;

        if (data.status === 1) {
            let details = mapDetails(data, 'claimed', action.payload.filter)
            yield put(setStatDetails(details));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetStatsActive(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getStatsActive, { accessToken, form: action.payload });

        const { data } = response;

        if (data.status === 1) {
            let details = mapDetails(data, 'active', action.payload.filter)
            yield put(setStatDetails(details));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetStatsUpcoming(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getStatsUpcoming, { accessToken, form: action.payload });

        const { data } = response;

        if (data.status === 1) {
            let details = mapDetails(data, 'upcoming', action.payload.filter)
            yield put(setStatDetails(details));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}

export function* handleGetStatsDrinks(action) {
    try {
        yield put(setShowLoading({ showLoading: true }));

        const accessToken = localStorage.getItem('accessToken');
        const response = yield call(getStatsDrinks, { accessToken, form: action.payload });

        const { data } = response;

        if (data.status === 1) {
            const details = {
                type: 'drinks',
                title: `Drinks Gifted Insights`,
                list1: {
                    data: data.details,
                    title: 'Drinks Gifted'
                }
            }
            yield put(setStatDetails(details));
        } else {
            const snackbarObject = {
                type: 'error',
                message: data.message || 'Can\'t process now. Please try again later.',
                open: true,
            };
            yield put(showSnackBar(snackbarObject));
        }
        yield put(setShowLoading({ showLoading: false }));
    } catch (error) {
        yield put(setShowLoading({ showLoading: false }));
        console.log(error);
    }
}